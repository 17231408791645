import { Accordion, AccordionDetails, AccordionSummary, Breadcrumbs, Link, Stack, Typography } from "@mui/material";
import { TypographyList } from "../../components";
import { TitledCard } from "./cards";
import VerticalPad from "../../components/verticalPad";
import WorkIcon from '@mui/icons-material/Work';
import { InlineWidget } from "react-calendly";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MyBreadcrumbs from "../../components/myBreadcrumbs";

export default function ScreenConsulting() {
    return (
        <>
            <MyBreadcrumbs current="Consulting" />
            <div >
                <Typography variant="h5" gutterBottom>
                    Development & IT
                </Typography>
                <Typography gutterBottom>
                    Bringing enterprise-level tech to businesses of all sizes.
                </Typography>
                <Typography>
                    Whether you need unique web tools for your organization, or support with your marketing website
                </Typography>
                <Typography>
                    I have a plan for you!
                </Typography>
                <div style={{ display: 'flex' }}>
                    <Link href="https://www.upwork.com/freelancers/~0118a166b79862b285" underline="hover" style={{ display: 'flex' }}>
                        <Typography>Hire me on upwork </Typography>
                        <WorkIcon />
                    </Link>
                </div>
            </div>
            <VerticalPad size={40} />
            <div>
                <Typography variant="h5" gutterBottom>
                    Technologies
                </Typography>
                {TypographyList([
                    "FrontEnd: React, Angular, Web3",
                    "BackEnd: Node, Spring",
                    "Database: SQL, MongoDB",
                    "Cloud: AWS & Azure",
                    "Scripting: Python, Bash, Excel"
                ])}
            </div>
            <VerticalPad size={40} />
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
                justifyContent={{ xs: 'center' }}
                alignItems={{ xs: 'center' }}
            >
                <TitledCard title={"Basic Website"} items={["Support and Setup", "Web Hosting", "Frontend Only"]} />
                <TitledCard title={"Web & Mobile Apps"} items={["APIs", "Custom Tracker Apps", "Basic Database", "User Authentication"]} />
                <TitledCard title={"Enterprise Apps & Cloud Support"} items={["Advanced Applications", "Continuous Integration", "Custom ETL and Integration Tools", "Complex Database Needs"]} />
            </Stack>
            {/* <VerticalPad size={40} />
            <CalendlyWidget /> */}
        </>
    )
}

function CalendlyWidget() {
    return <Accordion>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography>
                Setup a Consultation
            </Typography>
            {/* <Typography sx={{ color: 'text.secondary' }}>not accounts necessary</Typography> */}
        </AccordionSummary>
        <AccordionDetails>
            <InlineWidget styles={{ height: '1200px', padding: 0, margin: 0 }}
                url="https://calendly.com/ausmo-consulting/30min" />
        </AccordionDetails>
    </Accordion>
}