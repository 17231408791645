import React from "react";

export type EstimatorVal = {
    activeStep: number;
    tier: string;
    pageRange: number[];
}

export type EstimatorState = {
    get: EstimatorVal;
    set: React.Dispatch<React.SetStateAction<EstimatorVal>>;
}

export const EstimatorBaseState: EstimatorVal = {
    activeStep: 0,
    tier: "web",
    pageRange: [1,2]
}

export const EstimatorContext = React.createContext<EstimatorState>({} as EstimatorState);
export const EstimatorProvider = EstimatorContext.Provider;