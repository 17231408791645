import React from "react";
import MyBreadcrumbs from "../../components/myBreadcrumbs";
import boxArt from "../../assets/CX_boxArt.png";
import { Stack } from "@mui/system";
import { Box, Button, Link, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

export default function ScreenClaimingXodius() {
    const [value, setValue] = React.useState('summary');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <>
            <MyBreadcrumbs current="Claiming Xodius" />
            <Typography variant="h5" gutterBottom>
                Claiming Xodius (2015)
            </Typography>
            <Stack
                direction={{ sm: 'column', md: 'row' }}
                spacing={{ sm: 2, md: 4 }}
                justifyContent={{ sm: 'stretch' }}
                alignItems={{ sm: 'flex-start' }}
            >

                <img src={boxArt} />
                <Box>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Summary" value="summary" />
                                <Tab label="Editor" value="editor" />
                            </TabList>
                        </Box>
                        <TabPanel value="summary">
                            <CXYouTube />
                            <Typography>LVL House Games</Typography>
                            <ul>
                                <li>Developer and Artist: Austin Molina</li>
                                <li>Sound Design and Music: <Link
                                    href="https://obsydianx.itch.io/"
                                    target="_blank"
                                    underline="hover"
                                >ObsydianX</Link></li>
                            </ul>

                            <Typography>About</Typography>
                            <ul>

                                {
                                    [
                                        'Developed over 3 years while I worked as a Bagger, Dishwasher, and Math Tutor',
                                        'Created with Game Maker: Studio',
                                        'Built all assets and systems from the ground up including: RPG Systems, Menus, Gameplay, 2.5d Support',
                                        '1-2 player local co-op with ai-controlled party members',
                                        "It's pretty fun, I'd say"
                                    ].map(a => <li>{a}</li>)
                                }
                                <li>
                                    <Link
                                        href="https://www.indiedb.com/games/claiming-xodius"
                                        target="_blank"

                                    >
                                        Devlogs (2014-2015)
                                    </Link>
                                </li>
                            </ul>
                            <div style={{ display: 'flex' }}>
                                <Button
                                    href="https://lvlhouse.itch.io/claiming-xodius"
                                    variant="contained"
                                    target="_blank"
                                >
                                    Download on itch.io
                                </Button>
                            </div>
                            <div style={{ display: 'flex' }}>

                            </div>
                        </TabPanel>
                        <TabPanel value="editor">
                            <img src="https://media.indiedb.com/cache/images/games/1/33/32369/thumb_620x2000/Runner_2014-06-04_14-26-57-03.png"
                                alt="Claiming Xodius Editor" />
                            <ul>
                                <li>Custom map editor created entirely in Game Maker</li>
                                <li>Implemented file format compatible with the Claiming Xodius game engine</li>
                                <li>Complete with Tile/Prefab Placement, Monster Spawns, Map Navigation, Music, and more</li>
                                <li>
                                    <Link href="https://www.indiedb.com/games/claiming-xodius/news/claiming-xodius-map-editor-redesign"
                                        target="_blank"
                                        underline="hover"
                                        style={{ display: 'flex' }}>
                                        Editor Devlog
                                    </Link>
                                </li>
                            </ul>
                            {/* <img src="https://media.indiedb.com/cache/images/games/1/33/32369/thumb_620x2000/cx_edit_screen_3.png"
                                alt="CX Editor" /> */}
                        </TabPanel>

                    </TabContext>
                </Box>
            </Stack>
        </>
    )
}

function CXYouTube() {
    return <iframe
        style={{
            border: 0,
            width: '120%',
            height: '320px',
            marginLeft: '-10%'
        }}

        src="https://www.youtube.com/embed/V10XCdWUbqM?rel=0&autoplay=1&mute=1"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    />
}