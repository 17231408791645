import centipede from "../../assets/art/elBlueCentipede2.jpg";
import forget from "../../assets/art/forget.jpg";
import fowl from "../../assets/art/fowl.jpg";
import kind from "../../assets/art/kind.jpg";
import mariposaAqua2 from "../../assets/art/mariposaAqua2.jpg";
import skullKoi from "../../assets/art/skullKoi.jpg";
import salty from "../../assets/art/salty.jpg";
import immolation from "../../assets/art/immolation.jpg";
import WaspChimaera from "../../assets/art/WaspChimaera.jpg";
import Masonry from '@mui/lab/Masonry';
import '../../theme/grayscaleStyle.css';

import MyBreadcrumbs from "../../components/myBreadcrumbs";

export default function ArtScreen() {
    const images: string[] = [
        WaspChimaera, forget, mariposaAqua2,
        kind, fowl, skullKoi,
        salty, immolation, centipede
    ];

    return <>
        <MyBreadcrumbs current="Gallery" />
        <Masonry columns={3} spacing={2}>
            {images.map((src, index) => (
                <img className="grayscale" src={src} />
            ))}
        </Masonry>

    </>
}