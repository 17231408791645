import { Card, CardContent, Typography, CardActions, Button } from "@mui/material";

export function TitledCard(props: { title: string, items: string[] }) {
    const { title, items } = props;

    const d = { w: '280px', h: '320px' };

    return (
        <Card
            style={{ height: d.h, display: "flex", flexDirection: "column", justifyContent: "stretch", marginTop: "20px" }}
            sx={{ width: d.w }}
            color="black"
        >
            <div style={{
                backgroundColor: "rgb(12,12,12)", width: "100%", height: "72px",
                display: "flex", justifyContent: "center",
                padding: "2px"
            }}>
                <Typography variant="h5" align="center" color="text.secondary" gutterBottom>
                    {title}
                </Typography>
            </div>
            <CardContent sx={{ width: d.w, flexGrow: 1 }}>
                <ul>
                    {items.map(i => (
                        <li>
                            {i}
                        </li>
                    ))}
                </ul>
            </CardContent>
            {/* <CardActions style={{ alignSelf: "end" }}>
                <Button href={'/consulting/estimator'} size="small">Learn More</Button>
            </CardActions> */}

        </Card>
    );
}