import { Avatar, Container, Link, Typography } from "@mui/material";
import { BasicCard } from "./cards";
import codeScreen from "../../assets/codeScreen.jpg";
import starChart from "../../assets/starchart2.png";
import claimingXodius from "../../assets/claimingXodius.png";
import tshirtSample from "../../assets/tshirtSample.jpg";
import meArm from "../../assets/prototyping/meArm.jpg";
import avatar from "../../assets/avatar.png";
import InstagramIcon from '@mui/icons-material/Instagram';
import WorkIcon from '@mui/icons-material/Work';


export default function ScreenHome() {
    return <>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", minHeight: "100vh" }}>
            <Container
                style={{ display: "flex", flexWrap: "wrap", alignContent: "center", justifyContent: "stretch", padding: 0 }}
            >
                <AvatarCard />
                <BasicCard title={"Shop"} grayscale image={tshirtSample} link={"https://shop.ausmo.online"}></BasicCard>
                {/* <BasicCard title={"Games"} image={skulls} link={"/"}></BasicCard> */}
                {/* <BasicCard title={"Gallery"} grayscale image={mariposa} link={"/gallery"}></BasicCard> */}
                <BasicCard title={"Consulting"} grayscale image={codeScreen} link={"/consulting"}></BasicCard>
                <BasicCard title={"Astronomy Demo (2022)"} grayscale image={starChart} link={"/astro"}></BasicCard>
                <BasicCard title={"Rapid Prototyping (2017)"} grayscale image={meArm} link={"/prototyping"}></BasicCard>
                <BasicCard title={"Claiming Xodius (2013-2015)"} grayscale image={claimingXodius} link={"/claiming-xodius"}></BasicCard>
                {/* <BasicCard title={"NFTs"} image={bonePlanet} link={"/"}></BasicCard> */}
            </Container>

        </div>
    </>
}

function AvatarCard() {
    return (
        <BasicCard title={"Ausmo Online"}
            top={
                <Avatar alt="Aust" src={avatar} sx={{ width: 96, height: 96 }} />
            } >
            <Typography>Web Developer | Artist | Freelancer</Typography>
            <Link href="https://www.instagram.com/ausmo.ahh/" target="_blank" underline="hover" style={{ display: 'flex' }}>
                <InstagramIcon /> ausmo.ahh
            </Link>
            <Link href="https://www.upwork.com/freelancers/~0118a166b79862b285" target="_blank" underline="hover" style={{ display: 'flex' }}>
                <WorkIcon /> upwork
            </Link>
        </BasicCard>
    )
}