import { Link as MatLink } from "@mui/material";
import { Link } from "react-router-dom";
import { Container } from "@mui/system";


export function Footer() {
    return (
        <div style={{
            backgroundColor: "rgb(12,12,12)", width: "100%", minHeight: "240px", padding: "20px",
            bottom: 0, marginTop: 10
        }}>
            <Container style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <TextLink to={'/'}>Home</TextLink>
                    {/* <TextLink>About</TextLink> */}
                    {/* <TextLink>Help</TextLink> */}
                    {/* <TextLink to={'other'}>Other</TextLink> */}
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <TextLink to={'https://shop.ausmo.online/pages/contact'}>Contact Me</TextLink>
                </div>
            </Container>
        </div>
    )
}

function TextLink({ children, to }: any) {
    return (
       
            <MatLink
                href={to ? to : ''}
                style={{ margin: '4px' }}
                underline="hover"
            >
                {children}
            </MatLink>

    );
}