
// import "../styles/parallax.css"

import { ReactNode } from "react";

function ParallaxDiv(props: {image: string, height?: number, position?: string, children: ReactNode}) {
    const height = props.height ? props.height : '400px'
    const paraImg = {
        width: '100%',
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        // flexDirection: 'column',
        justifyContent: 'center'
    }
    return <div
        className="paraImage"
        style={{
            ...paraImg,
            backgroundPosition: props.position ? props.position : 'center',
            backgroundImage: `url(${props.image})`,
            minHeight: height,
        }}    
        >
        {props.children}
    </div>
}

export default ParallaxDiv;