import { Card, Typography, CardActionArea, Link } from "@mui/material";
import { ReactNode } from "react";

export function BasicCard(props: { title?: string, image?: string, link?: string, children?: ReactNode, top?: ReactNode, grayscale?: boolean, externalLink?: boolean }) {
    const { title, image, link, grayscale } = props;

    const content = () => {
        return <ImageDiv grayscale={grayscale || false} style={{ display: "flex", flexDirection: "column", justifyContent: "stretch", alignItems: "center" }} image={image}>
            <div style={divStyle}>
                {props.top}
            </div>
            <Typography variant="h3" style={{
                alignSelf: "center",
                textAlign: "center",
                maxWidth: "300px",
                color: "white",
                backgroundColor: "rgba(0,0,0,.75)",
                borderRadius: "5px"
            }}>{title}</Typography>
            <div style={divStyle}>
                {props.children}
            </div>
        </ImageDiv>
    }

    return (
        <Card sx={{ minWidth: '300px', width: '100%', margin: "10px", flexGrow: 1 }}>
            {link
                ? <CardActionArea href={link}>{content()}</CardActionArea>
                : content()
                }
        </Card>
    );
}

function ImageDiv(props: { grayscale: boolean, style: React.CSSProperties, image?: string, height?: number, position?: string, children: ReactNode }) {
    const { style, height, grayscale } = props;
    const paraImg = {
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        justifyContent: 'center',
        color: 'white'
    }
    return <div
        className={grayscale ? "paraImag grayscale" : "paraImag"}
        style={{
            ...paraImg,
            backgroundPosition: props.position ? props.position : 'center',
            backgroundImage: `url(${props.image})`,
            minHeight: height || '400px',
            ...style
        }}
    >
        {props.children}
    </div>
}

const divStyle: React.CSSProperties = {
    flexBasis: 0,
    flexGrow: 1,
    width: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}