import { Breadcrumbs, Link, Typography } from "@mui/material";
import VerticalPad from "./verticalPad";

export default function MyBreadcrumbs(props: { current: string }) {
    const { current } = props;
    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                    Home
                </Link>
                <Typography color="text.primary">{current}</Typography>
            </Breadcrumbs>
            <VerticalPad size={40} />
        </>
    )
}