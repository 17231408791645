/*

        Home Planet for Windows

            Definitions

*/

//	Frequently used astronomical constants

export const J2000 = 2451545.0		// Julian day of J2000 epoch
export const JulianCentury = 36525.0			// Days in Julian century
export const AstronomicalUnit = 149597870.0		// Astronomical unit in kilometres
export const SunSMAX = (AstronomicalUnit * 1.000001018) // Semi-major axis of Earth's orbit
export const EarthRad = 6378.14			// Earth's equatorial radius, km (IAU 1976)
export const LunatBase = 2423436.0		/* Base date for E. W. Brown's numbered
											   series of lunations (1923 January 16) */
export const SynMonth = 29.53058868		// Synodic month (mean time from new Moon to new Moon)

//	Precession calculation modes

export const PrecAuto = 0					// Precess if more then PrecYears from PrecEpoch
export const PrecAlways = 1					// Always precess
export const PrecNever = 2					// Never correct for precession
export const PrecEpoch = J2000				// All databases are epoch J2000.0
export const PrecYears = 25					// Consider databases valid for this time around epoch

/*  Handy mathematical functions  */


/* As an indication of how carefully Microsoft tests software prior
   to shipment, and their deep committment to numerical applications,
   Visual C++ 1.5 (C 8.0) shipped with a library in which tan(x)
   returns the wrong sign for arguments between -90 and 90 degrees
   but only if the math coprocessor is not installed.  Can you believe
   this?  So, define tan(x) as sin(x) / cos(x) to work around this
   unbelievable example of lack of regression testing. */

//const MICROSOFT_CAN_T_COUNT_WORTH_BEANS=

export const sgn = (x: any) => (((x) < 0) ? -1 : ((x) > 0 ? 1 : 0))       // Extract sign
export const abs = (x: any) => Math.abs(x)                   // Absolute val
export const fixangle = (a: number) => ((a) - 360.0 * (Math.floor((a) / 360.0)))  // Fix angle   
export const fixangr = (a: number) => ((a) - (Math.PI * 2) * (Math.floor((a) / (Math.PI * 2))))  // Fix angle in radians
export const dtr = (x: number) => ((x) * (Math.PI / 180.0))                       // Degree->Radian
export const rtd = (x: number) => ((x) / (Math.PI / 180.0))                       // Radian->Degree

// const NeedToCalculate = (qty, time)((abs((qty##Last) - (time)) > qty##Interval) ?\
// (qty##Last = (time), TRUE) : FALSE)
// const InvalidateCalculation = (qty)      qty##Last = -1e10
// const ValidateCalculation = (qty, time) qty##Last = (time)
export const CalculationInterval = (x: number) => ((x) / (24.0 * 60 * 60))

/*
//	From Sunmoon.c

extern void upd_sun_moon(double jd, int realtime, int repaint);
extern void updmoon(HWND hWnd, HDC hDC, RECT windex, double jd);
extern void drawmoon(HDC hDC);
extern void undrawmoon(HDC hDC);
extern void restoremoon(HDC hDC);
extern int hitmoon(int x, int y);

//	From Objcat.c

extern void launchObjectCat(void);
extern void buildFindIndex(void);
extern void relFindIndex(void);
extern int findObject(double ra, double dec, WPARAM keysdown,
    int * catno, int * objno, double * separation);
extern void pointObjectCat(int catno, int objno);

//	From Planetp.c

extern void updatePlanet(double jd, int normal);
extern void planetSiteChanged(void);
extern void calcPlanet(double jd);

//	From Sky.c

extern void launchSkyWindow(void);
extern void loadPlanetIcons(void);
extern void unloadPlanetIcons(void);
extern void sitePicked(WORD px, WORD py, WORD sx, WORD sy);
extern void updateSky(double jd, int force);
extern void skySatChanged(void);
extern void selectSkyObjectBin(double ra, double dec);
extern void parseRaDec(char * ra, char * dec, double * fra, double * fdec);
extern void selectSkyObject(char * ra, char * dec);
extern void updateOrrery(double jd, int force);

//	From Terrain.c

extern BOOL terrainSeeded;
extern void drawTerrain(HWND hWnd, HDC hDC, double jd, double azimuth,
        double roughness, int scenery);

//	From Cuckoo.c

extern void updateCuckoo(void);

//  From Asteroid.c

extern void selectAsteroid(char * aname, double aelem[8]);
extern void selectComet(char * aname, double aelem[8]);
extern void trackAsteroid(double jd, double * ra, double * dec, double * dist,
            double * hlong, double * hlat, double * hrv, int quick);

//  From Settings.c

extern void saveSettings(char * appName);
extern void loadSettings(char * appName);
extern void defSettings(char * appName);

//  Calculation functions from Suncalc.c

extern void set_tm_time(struct tm * t, BOOL islocal);
extern double jtime(struct tm * t);
extern void jyear(double td, long * yy, int * mm, int * dd),
    jhms(double j, int * h, int * m, int * s);
extern double gmst(double jd);
extern double ucttoj(long year, int mon, int mday,
        int hour, int min, int sec);
extern void sunpos(double jd, int apparent,
            double * ra, double * dec, double * rv, double * slong);
extern double phase(
                double  pdate, double * pphase, double * mage, double * dist,
                double * angdia, double * sudist, double * suangdia);
extern void phasehunt(double sdate, double phases[5]);
extern void highmoon(double jd, double * l, double * b, double * r);
extern double obliqeq(double jd);
extern void ecliptoeq(double jd, double Lambda, double Beta, double * Ra, double * Dec);
extern void definePrecession(double epoch);
extern void precessObject(double ira, double idec, double * ora, double * odec);


extern void invalidate_refresh(void);
extern void release_bitmaps(void);
extern void localtimeformat(void);

extern void mapdraw(int xsize, int ysize,
                    void (PASCAL * vector)(int x1, int y1, int x2, int y2));
extern void maplatlon(void (* llfunc)(int lat1, int lon1, int lat2, int lon2));

extern void ringgg(HWND hWnd, HDC hDC, int repaint, int copyCat);
extern void go_iconic(HWND hWnd);
extern void new_display_mode(HWND hWnd, int mode);
extern char * rstring(int resid);
extern LPBITMAPINFOHEADER palMapStart(LPBITMAPINFOHEADER bh, int * bmode);
extern void palMapEnd(void);

//  DDE link management functions from Ddeserv.c

extern void ddeInit(HINSTANCE hInstance);
extern void ddeAdvise(void);
extern void ddeTerm(void);
extern void updateDDEInfo(void);
extern void updateScopeDDE(void);

//      Comma separated value database scanning routines from Csv.c

extern void CSVscanInit(char * s);
extern int CSVscanField(char * f);

// Orbit calculation routines from Orbit.c

typedef double mat3x3[3][3];

extern void InitOrbitRoutines(double EpochDay);
extern long GetDayNum(int Year, int Month, int Day);
extern double Kepler(double MeanAnomaly, double Eccentricity);
extern void GetSubSatPoint(double SatX, double SatY, double SatZ,
                        double Time,
                        double * Latitude, double * Longitude, double * Height);
extern void GetPrecession(double SemiMajorAxis, double Eccentricity, double Inclination,
                            double * RAANPrecession, double * PerigeePrecession);
extern void GetSatPosition(double EpochTime, double EpochRAAN, double EpochArgPerigee,
                                double SemiMajorAxis, double Inclination, double Eccentricity,
                                double RAANPrecession, double PerigeePrecession, double Time,
                                double TrueAnomaly,
                                double * X, double * Y, double * Z,
                                double * Radius, double * VX, double * VY, double * VZ);
extern int Eclipsed(double SatX, double SatY, double SatZ,
                                    double SatRadius, double CurrentTime);
extern void GetSitPosition(double SiteLat, double SiteLong, double SiteElevation,
                                        double CurrentTime,
                                        double * SiteX, double * SiteY, double * SiteZ,
                                        double * SiteVX, double * SiteVY, mat3x3 SiteMatrix);
extern void GetBearings(double SatX, double SatY, double SatZ,
                                            double SiteX, double SiteY, double SiteZ,
                                            mat3x3 SiteMatrix, double * Azimuth, double * Elevation);

//	From Vsop87.c

const FULL_PLANET_INFO = 0x8000			// Calculate complete high-precision planet info
extern void planets(double jd, int which);	/* Update planetary positions */

