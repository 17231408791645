import { PLANET_NAME } from "./astroScreen";
import { Planet } from "./vsop87";

export type planetRelation = {
    planetA: string,
    planetB: string,
    type: string,
    angle: number,
    x1: number
    y1: number
    x2: number
    y2: number
}

export function determinePlanetRelations(planets: Planet[]): planetRelation[] {

    const relations = [];
    const err = 10;

    for (let i = 0; i < planets.length - 1; i++) {
        for (let j = i + 1; j < planets.length; j++) {
            const p1 = planets[i];
            const p2 = planets[j];

            const diff = Math.abs(p1.ra - p2.ra);

            const rel = calcRelationCoords(p1, p2, diff)
            rel.planetA = PLANET_NAME[i]
            rel.planetB = PLANET_NAME[j]

            if (Math.abs(diff - 60) < err || Math.abs((diff - 300)) < err) {
                rel.type = "sextile"
            }
            if (Math.abs(diff - 90) < err || Math.abs((diff - 270)) < err) {
                rel.type = "square"
            }
            if (Math.abs(diff - 120) < err || Math.abs((diff - 240)) < err) {
                rel.type = "trine"
            }
            if (Math.abs(diff - 180) < err) {
                rel.type = "opposition"
            }

            if (rel.type) relations.push(rel);
        }
    }

    console.log(relations);
    return relations;
}

function calcRelationCoords(p1: Planet, p2: Planet, angle: number): planetRelation {
    const x1 = - Math.cos(Math.PI / 180 * (p1.ra)) / 2;
    const y1 = + Math.sin(Math.PI / 180 * (p1.ra)) / 2;
    const x2 = - Math.cos(Math.PI / 180 * (p2.ra)) / 2;
    const y2 = + Math.sin(Math.PI / 180 * (p2.ra)) / 2;

    return {
        planetA: "",
        planetB: "",
        x1, y1, x2, y2,
        angle,
        type: ""
    }
}