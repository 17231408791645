import React from "react";
import { planetRelation } from "./planetRelations";
import { Planet } from "./vsop87";

export type AstroVal = {
    planetInfo: Planet[];
    displayPlanet: boolean[];
    planetRelations: planetRelation[];
}

export type AstroState = {
    get: AstroVal;
    set: React.Dispatch<React.SetStateAction<AstroVal>>;
}

export const AstroBaseState: AstroVal = {
    planetInfo: [],
    displayPlanet: [true, true, true, true, true, true, true, true, true, false],
    planetRelations: []
}

export const AstroContext = React.createContext<AstroState>({} as AstroState);
export const AstroProvider = AstroContext.Provider;