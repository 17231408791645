import { Typography, Stack, Card, Box, Tab, Tabs } from "@mui/material";
import MyBreadcrumbs from "../../components/myBreadcrumbs";
import VerticalPad from "../../components/verticalPad";
import { useState } from "react";

import simonSays from "../../assets/prototyping/simonSays.gif";
import skittleSorter from "../../assets/prototyping/skittleSorter.mp4";

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function ScreenProtoTyping() {
    const [value, setValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <MyBreadcrumbs current="Prototyping" />
            <div >
                <Typography variant="h5" gutterBottom>
                    Rapid Prototyping
                </Typography>
                {
                    [
                        '- Experience with the Microprocessor Arduino UNO at the University of Arizona',
                        '- Designed components in Adobe Illustrator which were then laser cut and assembled',
                        '- Wrote C/C++ programs to interface with electronic components',
                    ].map(a => <Typography>{a}</Typography>)
                }
            </div>
            <VerticalPad size={40} />

            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Skittle Sorter" {...a11yProps(0)} />
                        <Tab label="Simon Says" {...a11yProps(1)} />
                        <Tab label="Me Arm" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <SkittleSorter />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <SimonSays />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <MeArm />
                </TabPanel>
            </Box>

            {/* <img style={imgStyle} src={meArm} />
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
                justifyContent={{ xs: 'center' }}
                alignItems={{ xs: 'center' }}
            >
                <img style={imgStyle} src={simonSays} />
                <img style={imgStyle} src={sonarScanner} />
            </Stack> */}
            {/* <VerticalPad size={40} />
            <CalendlyWidget /> */}
        </>
    )
}

const iframeStyle: React.CSSProperties = {
    width: "100%",
    minHeight: "500px",
    height: "100%",
    flexGrow: 1,
    // maxHeight: "500px",
    border: 0
}

function SkittleSorter() {
    return <Card>
        <Stack
            direction={{ xs: 'column-reverse', sm: 'row' }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
            justifyContent={{ xs: 'center' }}
            alignItems={{ xs: 'center' }}
        >
            <iframe
                title="skittleSorterPDF"
                style={iframeStyle}
                src="https://drive.google.com/file/d/1GBGjrfrEcXDRIC6QK834wfFz6_6izH7G/preview"
                allow="autoplay"
            />
            <video width="360" height="640" loop muted autoPlay controls controlsList="play timeline nodownload nofullscreen">
                <source src={skittleSorter} type="video/mp4" />
            </video>
        </Stack>
    </Card>
}

function SimonSays() {
    const preview = 'https://drive.google.com/file/d/1SoqX_bCqcvBs95-9K5-y9LtzKWA67agZ/preview'
    return <Card>
        <Stack
            direction={{ xs: 'column-reverse', sm: 'column-reverse', md: 'row' }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
            justifyContent={{ xs: 'center' }}
            alignItems={{ xs: 'center' }}
        >
            <iframe
                title="SimonSaysPDF"
                style={iframeStyle}
                src={preview}
                allow="autoplay"
            ></iframe>
            <div>
                <img style={{ width: '100%', maxWidth: '360px', height: 'auto', aspectRatio: "initial" }} src={simonSays} />
            </div>
        </Stack>
    </Card>
}

function MeArm() {
    const preview = 'https://drive.google.com/file/d/1nw0UvQOIko0GNFD3__ha2F-DgX6jFo_d/preview'
    return <Card>
        <iframe
            title="MeArmPDF"
            style={iframeStyle}
            src={preview}
            allow="autoplay"></iframe>
    </Card>
}