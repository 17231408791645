import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Card, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Slider } from '@mui/material';
import { Container } from '@mui/system';
import { InlineWidget } from 'react-calendly';
import { EstimatorBaseState, EstimatorContext, EstimatorProvider } from './context';
import { useState } from 'react';
import ParallaxDiv from '../../components/parallax';

import cloudScape from "../../assets/cloudScape2.jpg";
import { Footer } from '../../components/footer';
import VerticalPad from '../../components/verticalPad';

export default function EstimatorScreen() {
    const [get, set] = useState(EstimatorBaseState)

    return (
        <>
            <ParallaxDiv image={cloudScape} height={200} position={""} >
                <Typography variant="h3" style={{
                    alignSelf: "center",
                    backgroundColor: "black"
                }}>Project Estimator Tool</Typography>
            </ParallaxDiv>
            <EstimatorProvider value={{ get, set }}>
                <Container>
                    {/* <Container sx={{ height: '100%' }}> */}
                    <EstStepper />
                    {/* </Container> */}
                </Container>
            </EstimatorProvider>
            <VerticalPad size={40} />
            <Footer />
        </>
    );
}
const steps = ['Tier', 'Scope', 'Term'];
const stepComponents = [TierComponent, ScopeComponent, TermComponent]

function EstStepper() {
    const context = React.useContext(EstimatorContext);
    const activeStep = context.get.activeStep;

    const handleNext = () => {
        context.set((prev) => ({
            ...prev, activeStep: prev.activeStep + 1
        }));
    };

    const handleBack = () => {
        context.set((prev) => ({
            ...prev, activeStep: prev.activeStep - 1
        }));
    };

    const handleReset = () => {
        context.set((prev) => ({
            ...prev, activeStep: 0
        }));
    };

    function RenderButtons() {
        return activeStep === steps.length ? (
            <>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleReset}>Reset</Button>
                </Box>
            </>
        ) : (
            <>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifySelf: "flex-end", height: '100%' }}>
                    <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleNext}>
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                </Box>
            </>
        )
    }

    return (
        <>
            <Card sx={{ minHeight: '400px', marginTop: '5%', display: 'flex', flexDirection: 'column', justifyContent: 'stretch' }}>
                <Container sx={{flexGrow: 1}}>
                    <VerticalPad size={20} />
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {

                            const stepProps: { completed?: boolean } = {};
                            const labelProps: {
                                optional?: React.ReactNode;
                            } = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                        {activeStep === steps.length ? (
                            <>
                                <EstimateComponent />

                            </>
                        ) : (
                            <>
                                {stepComponents[activeStep]()}

                            </>
                        )}
                    </div>
                </Container>
                {RenderButtons()}
            </Card>
            {activeStep === steps.length ? <CalendlyWidget /> : null}
        </>
    );
}


function TierComponent() {

    const context = React.useContext(EstimatorContext);
    const value = context.get.tier;

    const handleChange = (event: React.ChangeEvent, newValue: string) => {
        if (typeof newValue === 'number') return;
        context.set((prev) => ({
            ...prev, tier: newValue
        }));
    };

    return (
        <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Tier</FormLabel>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                value={value}
                onChange={handleChange}
            >
                <FormControlLabel value="web" control={<Radio />} label="Tier 1: Web" />
                <FormControlLabel value="application" control={<Radio />} label="Tier 2: Application" />
                <FormControlLabel value="enterprise" control={<Radio />} label="Tier 3: Enterprise" />
                <FormControlLabel value="other" control={<Radio />} label="Other" />
            </RadioGroup>
        </FormControl>
    );
}

function ScopeComponent() {

    const context = React.useContext(EstimatorContext);
    const value = context.get.pageRange;

    const handleChange = (event: Event, newValue: number | number[]) => {
        if (typeof newValue === 'number') return;
        context.set((prev) => ({
            ...prev, pageRange: newValue
        }));
    };

    return (
        <>
            <Typography>
                Number of Pages {value[0]}-{value[1]}
            </Typography>
            <Box sx={{ width: 300 }}>
                <Slider
                    getAriaLabel={() => 'Number of Pages'}
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={1}
                    max={20}
                />
            </Box>
            <Typography>
                Automation
            </Typography>
            <Typography>
                ETL
            </Typography>
        </>
    );
}

function TermComponent() {
    return (
        <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Term</FormLabel>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="short"
                name="radio-buttons-group"
            >
                <FormControlLabel value="short" control={<Radio />} label="Short Term" />
                <FormControlLabel value="long" control={<Radio />} label="Long Term" />
                <FormControlLabel value="ongoing" control={<Radio />} label="Ongoing" />
            </RadioGroup>
        </FormControl>
    );
}

const webEst = "Websites can vary in commplexity and have unique challenges. Websites typically take me a couple days for mostly static pages. Plus additional time to setup an environment, secure a domain, and deploy!"
const appEst = "Software applications can vary in commplexity and have unique challenges. Applications typically take me 1 - 2 weeks per functional page. On the longer side when they require API development. Plus additional time to setup an environment, secure a domain, and deploy!"
const entEst = "Software applications can vary in commplexity and have unique challenges. Applications typically take me 1 - 2 weeks per functional page. Plus additional time to setup an environment, secure a domain, and deploy!"

function EstimateComponent() {
    const context = React.useContext(EstimatorContext);
    const pages = context.get.pageRange;
    const tier = context.get.tier;

    const lenModifier = (tier === "web") ? 0.3 : 1;

    const length = [
        1 + Math.round((pages?.[0] + pages?.[1]) * lenModifier / 2),
        1 + Math.ceil(pages?.[1] * lenModifier * 2)
    ]

    let cost, estInfo;
    switch (tier) {
        case "web": cost = 20 * 35; break;
        case "application": cost = 30 * 50; break;
        case "enterprise": cost = 30 * 85; break;
        default: cost = 1500; break;
    }
    switch (tier) {
        case "web": estInfo = webEst; break;
        case "application": estInfo = appEst; break;
        case "enterprise": estInfo = entEst; break;
        default: estInfo = appEst; break;
    }
    return <>
        <Typography>
            Tier: {tier}
        </Typography>
        <Typography>
            Pages: {pages[0]} - {pages[1]}
        </Typography>
        <br />
        <Typography>
            {estInfo}
        </Typography>
        <br />
        <Typography color={"red"}>
            [DISCLAIMER] This is not a Quote - Book a Project Consultation with me and we can discuss your project further!
        </Typography>
        <br />
        <Typography>
            Time Estimate: {length[0]} - {length[1]} weeks
        </Typography>
        <Typography>
            Cost Estimate: ${length[0] * cost} - ${length[1] * cost}
        </Typography>
    </>
}

function CalendlyWidget() {

    return <InlineWidget styles={{ height: '1000px', padding: 0, margin: 0 }}
        url="https://calendly.com/ausmo-consulting/30min" />

}