import { Tooltip, Typography } from "@mui/material";
import { PLANET_NAME, PLANET_SYMBOL } from "./astroScreen";
import { Planet } from "./vsop87";

import starchart from "../../assets/starchart2.png";
import { AstroContext, AstroState } from "./context";
import { useContext } from "react";
const SVG = require('react-svg-draw')

export function PlanetaryChart() {
    const context: AstroState = useContext(AstroContext);
    const { planetInfo, displayPlanet, planetRelations } = context.get;
    const size = 500;
    const radius = 160;
    const offset = 0;
    const dist = (pDist: number) => { return Math.min(pDist, 40) }

    const { Rect, Line } = SVG;


    return (<div style={{ maxWidth: size, maxHeight: size, position: "relative", alignSelf: "center", }}>
        <SVG style={{ position: "absolute", width: "100%", height: "100%" }}>
            {planetRelations.map((p, i) => {
                console.log(
                    `${(size / 2 + p.x1 * radius) / 5}`,
                    `${(size / 2 + p.y1 * radius) / 5}`,
                    `${(size / 2 + p.x2 * radius) / 5}`,
                    `${(size / 2 + p.y2 * radius) / 5}`,
                )
                return (<Line
                    key={`relation-${i}`}
                    strokeWidth={"2"}
                    style={{
                        stroke: p.type === "opposition" ? "#da345d" : (
                            p.type !== "square" ? "#99c555" : "#8a7b4f"
                        ),
                        strokeDasharray: p.type === "sextile" ? 5 : 0
                    }}
                    stroke
                    x1={`${(size / 2 + p.x1 * 300) / 5}%`}
                    y1={`${(size / 2 + p.y1 * 300) / 5}%`}
                    x2={`${(size / 2 + p.x2 * 300) / 5}%`}
                    y2={`${(size / 2 + p.y2 * 300) / 5}%`}
                />)
            }
            )}
        </SVG>
        <img src={starchart} width={"100%"} />
        {planetInfo.map((p, i) => (
            displayPlanet[i]
                ? <Tooltip key={`symbol-${i}`} title={PLANET_NAME[i]} followCursor>
                    <Typography
                        className="planet"
                        style={{
                            cursor: "pointer",
                            position: "absolute",
                            left: `${(size / 2 - Math.cos(Math.PI / 180 * (p.ra + offset)) * (radius + dist(p.dist))) / 5}%`,
                            top: `${(size / 2 + Math.sin(Math.PI / 180 * (p.ra + offset)) * (radius + dist(p.dist))) / 5}%`,
                            backgroundColor: "rgba(0,0,0,.5)",
                            borderRadius: "100%",
                            // overflow: "hidden",
                            boxShadow: "1",

                            fontSize: "2em",
                            textAlign: "center",
                            width: 32,
                            height: 32,
                            marginTop: -16,
                            marginLeft: -16,

                        }}>{PLANET_SYMBOL[i]}</Typography>
                </Tooltip>
                : null
        ))}
    </ div>)
}