/*

            Calculate planetary positions using the VSOP87 theory
        	
*/

import { obliqeq, nutation, highmoon, ecliptoeq } from "./astro";
import { fixangr, dtr, rtd, J2000, JulianCentury, fixangle } from "./sunclock";

type pTerms = {
    termArray: number[];
    termCount: number;
};

// Planet information entry
export type Planet = {
    hlong: number;               // FK5 Heliocentric longitude
    hlat: number;                // FK5 Heliocentric latitude
    hrv: number;                 // Heliocentric radius vector
    dhlong: number;              // Dynamical Heliocentric longitude
    dhlat: number;               // Dynamical Heliocentric latitude
    ra: number;                  // Apparent right ascension
    dec: number;                 // Apparent declination
    dist: number;                // True distance from the Earth
    mag: number;                 // Approximate magnitude
    lha: number;                 // Local hour angle
    alt: number;                 // Altitude above (-below) horizon
    az: number;                  // Azimuth from South: West positive, East negative
};



const SunTermL0 = [
    1.75347045673, 0, 0,
    0.03341656453, 4.66925680415, 6283.0758499914,
    0.00034894275, 4.62610242189, 12566.1516999828,
    3.497056e-005, 2.74411783405, 5753.3848848968,
    3.417572e-005, 2.82886579754, 3.523118349,
    3.135899e-005, 3.62767041756, 77713.7714681205,
    2.676218e-005, 4.41808345438, 7860.4193924392,
    2.342691e-005, 6.13516214446, 3930.2096962196,
    1.324294e-005, 0.74246341673, 11506.7697697936,
    1.273165e-005, 2.03709657878, 529.6909650946,
    1.199167e-005, 1.10962946234, 1577.3435424478,
    9.9025e-006, 5.23268072088, 5884.9268465832,
    9.01854e-006, 2.04505446477, 26.2983197998,
    8.57223e-006, 3.50849152283, 398.1490034082,
    7.79786e-006, 1.17882681962, 5223.6939198022,
    7.53141e-006, 2.53339052847, 5507.5532386674,
    5.05267e-006, 4.58292599973, 18849.2275499742,
    4.92392e-006, 4.20505711826, 775.522611324,
    3.56672e-006, 2.91954114478, 0.0673103028,
    3.17087e-006, 5.84901948512, 11790.6290886588,
    2.84125e-006, 1.89869240932, 796.2980068164,
    2.71112e-006, 0.31486255375, 10977.078804699,
    2.42879e-006, 0.34481445893, 5486.777843175,
    2.06217e-006, 4.80646631478, 2544.3144198834,
    2.05478e-006, 1.86953770281, 5573.1428014331,
    2.02318e-006, 2.45767790232, 6069.7767545534,
    1.55516e-006, 0.83306084617, 213.299095438,
    1.32212e-006, 3.41118292683, 2942.4634232916,
    1.26225e-006, 1.08295459501, 20.7753954924,
    1.15132e-006, 0.64544911683, 0.9803210682,
    1.02851e-006, 0.63599845579, 4694.0029547076,
    1.01895e-006, 0.97569280312, 15720.8387848784,
    1.01724e-006, 4.2667980198, 7.1135470008,
    9.9206e-007, 6.20992926918, 2146.1654164752,
    9.7607e-007, 0.68101342359, 155.4203994342,
    8.5803e-007, 5.9832263126, 161000.685737674,
    8.5128e-007, 1.29870764804, 6275.9623029906,
    8.4711e-007, 3.67080093031, 71430.6956181291,
    7.9637e-007, 1.80791287082, 17260.1546546904,
    7.8757e-007, 3.03697458703, 12036.4607348882,
    7.4651e-007, 1.755089133, 5088.6288397668,
    7.3874e-007, 3.50319414955, 3154.6870848956,
    7.3547e-007, 4.67926633877, 801.8209311238,
    6.9627e-007, 0.83297621398, 9437.762934887,
    6.2449e-007, 3.97763912806, 8827.3902698748,
    6.1148e-007, 1.81839892984, 7084.8967811152,
    5.6963e-007, 2.78430458592, 6286.5989683404,
    5.6116e-007, 4.38694865354, 14143.4952424306,
    5.5577e-007, 3.47006059924, 6279.5527316424,
    5.1992e-007, 0.18914947184, 12139.5535091068,
    5.1605e-007, 1.33282739866, 1748.016413067,
    5.1145e-007, 0.28306832879, 5856.4776591154,
    4.9e-007, 0.48735014197, 1194.4470102246,
    4.1036e-007, 5.36817592855, 8429.2412664666,
    4.0938e-007, 2.39850938714, 19651.048481098,
    3.92e-007, 6.16833020996, 10447.3878396044,
    3.677e-007, 6.04133863162, 10213.285546211,
    3.6596e-007, 2.56957481827, 1059.3819301892,
    3.5954e-007, 1.70875808777, 2352.8661537718,
    3.557e-007, 1.775968892, 6812.766815086,
    3.3296e-007, 0.59310278598, 17789.845619785,
    3.0412e-007, 0.44294464169, 83996.8473181119,
    3.0047e-007, 2.73975124088, 1349.8674096588,
    2.5352e-007, 3.16470891653, 4690.4798363586,
];

const SunTermL1 = [
    6283.0758499914, 0, 0,
    0.00206058863, 2.67823455808, 6283.0758499914,
    4.303419e-005, 2.63512233481, 12566.1516999828,
    4.25264e-006, 1.59046982018, 3.523118349,
    1.19305e-006, 5.79555765566, 26.2983197998,
    1.09017e-006, 2.96631010675, 1577.3435424478,
    9.3479e-007, 2.59211109542, 18849.2275499742,
    7.2121e-007, 1.13840581212, 529.6909650946,
    6.7784e-007, 1.87453300345, 398.1490034082,
    6.735e-007, 4.40932832004, 5507.5532386674,
    5.9045e-007, 2.88815790631, 5223.6939198022,
    5.5976e-007, 2.17471740035, 155.4203994342,
    4.5411e-007, 0.39799502896, 796.2980068164,
    3.6298e-007, 0.46875437227, 775.522611324,
    2.8962e-007, 2.64732254645, 7.1135470008,
    2.0844e-007, 5.34138275149, 0.9803210682,
    1.9097e-007, 1.84628376049, 5486.777843175,
    1.8508e-007, 4.96855179468, 213.299095438,
    1.7293e-007, 2.9911676063, 6275.9623029906,
    1.6233e-007, 0.03216587315, 2544.3144198834,
    1.5832e-007, 1.43049301283, 2146.1654164752,
    1.4608e-007, 1.2046979369, 10977.078804699,
    1.2461e-007, 2.83432282119, 1748.016413067,
    1.1877e-007, 3.25805082007, 5088.6288397668,
    1.1808e-007, 5.27379760438, 1194.4470102246,
    1.1514e-007, 2.07502080082, 4694.0029547076,
    1.0641e-007, 0.76614722966, 553.5694028424,
    9.969e-008, 1.30263423409, 6286.5989683404,
    9.721e-008, 4.2392586526, 1349.8674096588,
    9.452e-008, 2.69956827011, 242.728603974,
    8.577e-008, 5.6447608598, 951.7184062506,
    7.576e-008, 5.30056172859, 2352.8661537718,
    6.385e-008, 2.65034514038, 9437.762934887,
    6.101e-008, 4.66633726278, 4690.4798363586,
];

const SunTermL2 = [
    8.721859e-005, 1.07253635559, 6283.0758499914,
    9.9099e-006, 3.14159265359, 0,
    2.94833e-006, 0.43717350256, 12566.1516999828,
    2.7338e-007, 0.05295636147, 3.523118349,
    1.6333e-007, 5.18820215724, 26.2983197998,
    1.5745e-007, 3.68504712183, 155.4203994342,
    9.425e-008, 0.29667114694, 18849.2275499742,
    8.938e-008, 2.05706319592, 77713.7714681205,
    6.94e-008, 0.82691541038, 775.522611324,
    5.061e-008, 4.6624323168, 1577.3435424478,
    4.06e-008, 1.03067032318, 7.1135470008,
    3.809e-008, 3.44043369494, 5573.1428014331,
    3.464e-008, 5.14021224609, 796.2980068164,
    3.172e-008, 6.05479318507, 5507.5532386674,
    3.02e-008, 1.19240008524, 242.728603974,
    2.885e-008, 6.11705865396, 529.6909650946,
    2.719e-008, 0.30363248164, 398.1490034082,
    2.538e-008, 2.27966434314, 553.5694028424,
    2.365e-008, 4.37666117992, 5223.6939198022,
    2.078e-008, 3.75435095487, 0.9803210682,
];

const SunTermL3 = [
    2.89058e-006, 5.84173149732, 6283.0758499914,
    2.0712e-007, 6.0498393902, 12566.1516999828,
    2.962e-008, 5.1956057957, 155.4203994342,
    2.527e-008, 3.14159265359, 0,
    1.288e-008, 4.7219761197, 3.523118349,
    6.35e-009, 5.96904899168, 242.728603974,
    5.7e-009, 5.54182903238, 18849.2275499742,
];

const SunTermL4 = [
    7.714e-008, 4.14117321449, 6283.0758499914,
    1.016e-008, 3.27573644241, 12566.1516999828,
];

const SunTermB0 = [
    2.7962e-006, 3.19870156017, 84334.6615813083,
    1.01643e-006, 5.42248619256, 5507.5532386674,
    8.0445e-007, 3.88013204458, 5223.6939198022,
    4.3806e-007, 3.70444689759, 2352.8661537718,
    3.1933e-007, 4.00026369781, 1577.3435424478,
];

const SunTermB1 = [
    0.00227777722, 3.4137662053, 6283.0758499914,
    3.805678e-005, 3.37063423795, 12566.1516999828,
    3.619589e-005, 0, 0,
    7.1542e-007, 3.32777549735, 18849.2275499742,
    8.107e-008, 3.89190403643, 5507.5532386674,
    7.655e-008, 1.79489607186, 5223.6939198022,
    6.456e-008, 5.1978942475, 2352.8661537718,
];

const SunTermB2 = [
    9.721424e-005, 5.1519280992, 6283.0758499914,
    2.33002e-006, 3.14159265359, 0,
    1.34188e-006, 0.64406212977, 12566.1516999828,
    6.504e-008, 1.07333397797, 18849.2275499742,
];

const SunTermB3 = [
    2.75993e-006, 0.59480097092, 6283.0758499914,
    1.7034e-007, 3.14159265359, 0,
    3.617e-008, 0.11750575325, 12566.1516999828,
];

const SunTermB4 = [
    5.745e-008, 2.26734029843, 6283.0758499914,
    8.7e-009, 0, 0,
];

const SunTermR0 = [
    1.00013988784, 0, 0,
    0.01670699632, 3.09846350258, 6283.0758499914,
    0.00013956024, 3.05524609456, 12566.1516999828,
    3.08372e-005, 5.19846674381, 77713.7714681205,
    1.628463e-005, 1.17387558054, 5753.3848848968,
    1.575572e-005, 2.84685214877, 7860.4193924392,
    9.24799e-006, 5.45292236722, 11506.7697697936,
    5.42439e-006, 4.56409151453, 3930.2096962196,
    4.7211e-006, 3.66100022149, 5884.9268465832,
    3.45969e-006, 0.96368627272, 5507.5532386674,
    3.2878e-006, 5.89983686142, 5223.6939198022,
    3.06784e-006, 0.29867139512, 5573.1428014331,
    2.43181e-006, 4.2734953079, 11790.6290886588,
    2.11836e-006, 5.84714461348, 1577.3435424478,
    1.8574e-006, 5.02199710705, 10977.078804699,
    1.74844e-006, 3.01193636733, 18849.2275499742,
    1.09835e-006, 5.0551063586, 5486.777843175,
    9.8316e-007, 0.88681311278, 6069.7767545534,
    8.65e-007, 5.68956418946, 15720.8387848784,
    8.5831e-007, 1.27079125277, 161000.685737674,
    6.4908e-007, 0.27251341435, 17260.1546546904,
    6.2917e-007, 0.92177053978, 529.6909650946,
    5.7056e-007, 2.01374292245, 83996.8473181119,
    5.5736e-007, 5.2415979917, 71430.6956181291,
    4.9384e-007, 3.24501240359, 2544.3144198834,
    4.6966e-007, 2.57799853213, 775.522611324,
    4.4666e-007, 5.53715663816, 9437.762934887,
    4.252e-007, 6.01110257982, 6275.9623029906,
    3.8963e-007, 5.36063832897, 4694.0029547076,
    3.8245e-007, 2.39255343973, 8827.3902698748,
    3.7486e-007, 0.82961281844, 19651.048481098,
    3.6957e-007, 4.90107587287, 12139.5535091068,
    3.5661e-007, 1.67447135798, 12036.4607348882,
    3.4537e-007, 1.84270693281, 2942.4634232916,
    3.3193e-007, 0.24370221704, 7084.8967811152,
    3.1922e-007, 0.18368299942, 5088.6288397668,
    3.1846e-007, 1.77775642078, 398.1490034082,
    2.8468e-007, 1.21344887533, 6286.5989683404,
    2.7795e-007, 1.89934427832, 6279.5527316424,
    2.6275e-007, 4.58896863104, 10447.3878396044,
];

const SunTermR1 = [
    0.00103018607, 1.10748968172, 6283.0758499914,
    1.721238e-005, 1.06442300386, 12566.1516999828,
    7.02217e-006, 3.14159265359, 0,
    3.2345e-007, 1.02168583254, 18849.2275499742,
    3.0801e-007, 2.84358443952, 5507.5532386674,
    2.4978e-007, 1.31906570344, 5223.6939198022,
    1.8487e-007, 1.42428709076, 1577.3435424478,
    1.0077e-007, 5.91385248388, 10977.078804699,
    8.654e-008, 1.42046854427, 6275.9623029906,
    8.635e-008, 0.27158192945, 5486.777843175,
];

const SunTermR2 = [
    4.359385e-005, 5.78455133808, 6283.0758499914,
    1.23633e-006, 5.57935427994, 12566.1516999828,
    1.2342e-007, 3.14159265359, 0,
    8.792e-008, 3.62777893099, 77713.7714681205,
    5.689e-008, 1.86958905084, 5573.1428014331,
    3.302e-008, 5.47034879713, 18849.2275499742,
];

const SunTermR3 = [
    1.44595e-006, 4.27319433901, 6283.0758499914,
    6.729e-008, 3.91706261708, 12566.1516999828,
];

const SunTermR4 = [
    3.858e-008, 2.56389016346, 6283.0758499914,
];

const SunTerms: pTerms[] = [
    { termArray: SunTermL0, termCount: 64 },
    { termArray: SunTermL1, termCount: 34 },
    { termArray: SunTermL2, termCount: 20 },
    { termArray: SunTermL3, termCount: 7 },
    { termArray: SunTermL4, termCount: 2 },
    { termArray: [], termCount: 0 },
    { termArray: SunTermB0, termCount: 5 },
    { termArray: SunTermB1, termCount: 7 },
    { termArray: SunTermB2, termCount: 4 },
    { termArray: SunTermB3, termCount: 3 },
    { termArray: SunTermB4, termCount: 2 },
    { termArray: [], termCount: 0 },
    { termArray: SunTermR0, termCount: 40 },
    { termArray: SunTermR1, termCount: 10 },
    { termArray: SunTermR2, termCount: 6 },
    { termArray: SunTermR3, termCount: 2 },
    { termArray: SunTermR4, termCount: 1 },
    { termArray: [], termCount: 0 },
];


const MercuryTermL0 = [
    4.40250710144, 0, 0,
    0.40989414976, 1.48302034194, 26087.9031415742,
    0.05046294199, 4.4778548954, 52175.8062831484,
    0.00855346843, 1.16520322351, 78263.7094247226,
    0.00165590362, 4.11969163181, 104351.612566297,
    0.00034561897, 0.77930765817, 130439.515707871,
    7.583476e-005, 3.7134840051, 156527.418849445,
    3.55974e-005, 1.51202669419, 1109.3785520934,
    1.803463e-005, 4.1033317841, 5661.3320491522,
    1.726012e-005, 0.35832239908, 182615.321991019,
    1.589923e-005, 2.99510417815, 25028.521211385,
    1.364682e-005, 4.59918318745, 27197.2816936676,
    1.017332e-005, 0.8803143904, 31749.2351907264,
    7.14182e-006, 1.54144865265, 24978.5245894808,
    6.43759e-006, 5.30266110787, 21535.9496445154,
    4.51137e-006, 6.04989275289, 51116.4243529592,
    4.042e-006, 3.28228847025, 208703.225132594,
    3.52441e-006, 5.24156297101, 20426.571092422,
    3.45212e-006, 2.79211901539, 15874.6175953632,
    3.43313e-006, 5.76531885335, 955.5997416086,
    3.39214e-006, 5.86327765, 25558.2121764796,
    3.25335e-006, 1.3367433478, 53285.1848352418,
    2.72947e-006, 2.49451163975, 529.6909650946,
    2.64336e-006, 3.91705094013, 57837.1383323006,
    2.59587e-006, 0.98732428184, 4551.9534970588,
    2.38793e-006, 0.11343953378, 1059.3819301892,
    2.3483e-006, 0.266721189, 11322.6640983044,
    2.16645e-006, 0.65987207348, 13521.7514415914,
    2.08995e-006, 2.09178234008, 47623.8527860896,
    1.83359e-006, 2.62878670784, 27043.5028831828,
    1.81629e-006, 2.43413502466, 25661.3049506982,
    1.75965e-006, 4.53636829858, 51066.427731055,
    1.72643e-006, 2.45200164173, 24498.8302462904,
    1.42316e-006, 3.36003948842, 37410.5672398786,
    1.37942e-006, 0.29098447849, 10213.285546211,
    1.25219e-006, 3.72079804425, 39609.6545831656,
    1.18233e-006, 2.78149786369, 77204.3274945334,
    1.06422e-006, 4.20572116254, 19804.8272915828,
];

const MercuryTermL1 = [
    26088.1470622275, 0, 0,
    0.01126007832, 6.21703970996, 26087.9031415742,
    0.00303471395, 3.05565472363, 52175.8062831484,
    0.00080538452, 6.10454743366, 78263.7094247226,
    0.00021245035, 2.83531934452, 104351.612566297,
    5.592094e-005, 5.82675673328, 130439.515707871,
    1.472233e-005, 2.51845458395, 156527.418849445,
    3.88318e-006, 5.48039225891, 182615.321991019,
    3.52244e-006, 3.05238094403, 1109.3785520934,
    1.02743e-006, 2.14879173777, 208703.225132594,
    9.354e-007, 6.11791163931, 27197.2816936676,
    9.0579e-007, 0.00045481669, 24978.5245894808,
    5.1941e-007, 5.62107554052, 5661.3320491522,
    4.437e-007, 4.57348500464, 25028.521211385,
    2.807e-007, 3.04195430989, 51066.427731055,
    2.7295e-007, 5.09210138837, 234791.128274168,
];

const MercuryTermL2 = [
    0.00053049845, 0, 0,
    0.00016903658, 4.69072300649, 26087.9031415742,
    7.396711e-005, 1.34735624669, 52175.8062831484,
    3.018297e-005, 4.45643539705, 78263.7094247226,
    1.107419e-005, 1.26226537554, 104351.612566297,
    3.78173e-006, 4.319980559, 130439.515707871,
    1.22998e-006, 1.06868541052, 156527.418849445,
    3.8663e-007, 4.08011610182, 182615.321991019,
    1.4898e-007, 4.6334308581, 1109.3785520934,
    1.1861e-007, 0.79187646439, 208703.225132594,
];

const MercuryTermL3 = [
    1.88077e-006, 0.03466830117, 52175.8062831484,
    1.42152e-006, 3.125054526, 26087.9031415742,
    9.6877e-007, 3.00378171915, 78263.7094247226,
    4.3669e-007, 6.01867965826, 104351.612566297,
    3.5395e-007, 0, 0,
    1.8045e-007, 2.77538373991, 130439.515707871,
    6.971e-008, 5.81808665742, 156527.418849445,
    2.556e-008, 2.57014364454, 182615.321991019,
];

const MercuryTermL4 = [
    1.14078e-006, 3.14159265359, 0,
    3.247e-008, 2.02848007619, 26087.9031415742,
    1.914e-008, 1.41731803758, 78263.7094247226,
    1.727e-008, 4.50137643801, 52175.8062831484,
    1.237e-008, 4.49970181057, 104351.612566297,
    6.45e-009, 1.26591776986, 130439.515707871,
];

const MercuryTermL5 = [
    8.77e-009, 3.14159265359, 0,
];

const MercuryTermB0 = [
    0.11737528962, 1.98357498767, 26087.9031415742,
    0.02388076996, 5.03738959685, 52175.8062831484,
    0.01222839532, 3.14159265359, 0,
    0.0054325181, 1.79644363963, 78263.7094247226,
    0.0012977877, 4.83232503961, 104351.612566297,
    0.00031866927, 1.58088495667, 130439.515707871,
    7.963301e-005, 4.60972126348, 156527.418849445,
    2.014189e-005, 1.35324164694, 182615.321991019,
    5.13953e-006, 4.37835409309, 208703.225132594,
    2.08584e-006, 2.02020294153, 24978.5245894808,
    2.07674e-006, 4.91772564073, 27197.2816936676,
    1.32013e-006, 1.11908492283, 234791.128274168,
    1.21395e-006, 1.81271752059, 53285.1848352418,
    1.00454e-006, 5.65684734206, 20426.571092422,
];

const MercuryTermB1 = [
    0.00429151362, 3.50169780393, 26087.9031415742,
    0.00146233668, 3.14159265359, 0,
    0.00022675295, 0.0151536688, 52175.8062831484,
    0.00010894981, 0.48540174006, 78263.7094247226,
    6.353462e-005, 3.42943919982, 104351.612566297,
    2.495743e-005, 0.16051210665, 130439.515707871,
    8.59585e-006, 3.18452433647, 156527.418849445,
    2.77503e-006, 6.21020774184, 182615.321991019,
    8.6233e-007, 2.95244391822, 208703.225132594,
    2.7696e-007, 0.29068938889, 27197.2816936676,
    2.6133e-007, 5.97708962692, 234791.128274168,
];

const MercuryTermB2 = [
    0.00011830934, 4.79065585784, 26087.9031415742,
    1.913516e-005, 0, 0,
    1.044801e-005, 1.21216540536, 52175.8062831484,
    2.66213e-006, 4.43418336532, 78263.7094247226,
    1.7028e-006, 1.62255638714, 104351.612566297,
    9.63e-007, 4.80023692017, 130439.515707871,
    4.4692e-007, 1.60758267772, 156527.418849445,
    1.8316e-007, 4.66904655377, 182615.321991019,
    6.927e-008, 1.4340488893, 208703.225132594,
];

const MercuryTermB3 = [
    2.35423e-006, 0.35387524604, 26087.9031415742,
    1.60537e-006, 0, 0,
    1.8904e-007, 4.36275460261, 52175.8062831484,
    6.376e-008, 2.50715381439, 78263.7094247226,
    4.58e-008, 6.14257817571, 104351.612566297,
    3.061e-008, 3.12497552681, 130439.515707871,
    1.732e-008, 6.26642412058, 156527.418849445,
];

const MercuryTermB4 = [
    4.276e-008, 1.74579932115, 26087.9031415742,
    1.023e-008, 3.14159265359, 0,
];

const MercuryTermR0 = [
    0.39528271652, 0, 0,
    0.07834131817, 6.19233722599, 26087.9031415742,
    0.00795525557, 2.95989690096, 52175.8062831484,
    0.00121281763, 6.01064153805, 78263.7094247226,
    0.00021921969, 2.77820093975, 104351.612566297,
    4.354065e-005, 5.82894543257, 130439.515707871,
    9.18228e-006, 2.59650562598, 156527.418849445,
    2.89955e-006, 1.42441936951, 25028.521211385,
    2.60033e-006, 3.02817753482, 27197.2816936676,
    2.01855e-006, 5.6472504035, 182615.321991019,
    2.01499e-006, 5.59227724202, 31749.2351907264,
    1.4198e-006, 6.25264202645, 24978.5245894808,
    1.00144e-006, 3.73435608689, 21535.9496445154,
];

const MercuryTermR1 = [
    0.00217347739, 4.65617158663, 26087.9031415742,
    0.00044141826, 1.42385543975, 52175.8062831484,
    0.00010094479, 4.47466326316, 78263.7094247226,
    2.432804e-005, 1.24226083435, 104351.612566297,
    1.624367e-005, 0, 0,
    6.03996e-006, 4.29303116561, 130439.515707871,
    1.52851e-006, 1.0606077981, 156527.418849445,
    3.9202e-007, 4.11136751416, 182615.321991019,
];

const MercuryTermR2 = [
    3.117867e-005, 3.08231840296, 26087.9031415742,
    1.245396e-005, 6.15183317423, 52175.8062831484,
    4.24822e-006, 2.9258335296, 78263.7094247226,
    1.3613e-006, 5.97983925842, 104351.612566297,
    4.2175e-007, 2.74936980629, 130439.515707871,
    2.1759e-007, 3.14159265359, 0,
    1.2793e-007, 5.80143162209, 156527.418849445,
];

const MercuryTermR3 = [
    3.2676e-007, 1.67971635359, 26087.9031415742,
    2.4166e-007, 4.63403168997, 52175.8062831484,
    1.2133e-007, 1.38983781545, 78263.7094247226,
    5.14e-008, 4.4391538693, 104351.612566297,
    1.981e-008, 1.20733880274, 130439.515707871,
];

const MercuryTerms: pTerms[] = [
    { termArray: MercuryTermL0, termCount: 38 },
    { termArray: MercuryTermL1, termCount: 16 },
    { termArray: MercuryTermL2, termCount: 10 },
    { termArray: MercuryTermL3, termCount: 8 },
    { termArray: MercuryTermL4, termCount: 6 },
    { termArray: MercuryTermL5, termCount: 1 },
    { termArray: MercuryTermB0, termCount: 14 },
    { termArray: MercuryTermB1, termCount: 11 },
    { termArray: MercuryTermB2, termCount: 9 },
    { termArray: MercuryTermB3, termCount: 7 },
    { termArray: MercuryTermB4, termCount: 2 },
    { termArray: [], termCount: 0 },
    { termArray: MercuryTermR0, termCount: 13 },
    { termArray: MercuryTermR1, termCount: 8 },
    { termArray: MercuryTermR2, termCount: 7 },
    { termArray: MercuryTermR3, termCount: 5 },
    { termArray: [], termCount: 0 },
    { termArray: [], termCount: 0 },
];

const VenusTermL0 = [
    3.17614666774, 0, 0,
    0.01353968419, 5.59313319619, 10213.285546211,
    0.00089891645, 5.30650048468, 20426.571092422,
    5.477201e-005, 4.41630652531, 7860.4193924392,
    3.455732e-005, 2.69964470778, 11790.6290886588,
    2.372061e-005, 2.99377539568, 3930.2096962196,
    1.664069e-005, 4.2501893503, 1577.3435424478,
    1.438322e-005, 4.15745043958, 9683.5945811164,
    1.317108e-005, 5.18668219093, 26.2983197998,
    1.200521e-005, 6.15357115319, 30639.856638633,
    7.69314e-006, 0.81629615911, 9437.762934887,
    7.6138e-006, 1.9501470212, 529.6909650946,
    7.07676e-006, 1.06466707214, 775.522611324,
    5.84836e-006, 3.99839884762, 191.4482661116,
    4.99915e-006, 4.12340210074, 15720.8387848784,
    4.29498e-006, 3.58642859752, 19367.1891622328,
    3.26967e-006, 5.67736583705, 5507.5532386674,
    3.26221e-006, 4.59056473097, 10404.7338123226,
    2.31937e-006, 3.16251057072, 9153.9036160218,
    1.79695e-006, 4.65337915578, 1109.3785520934,
    1.55464e-006, 5.57043888948, 19651.048481098,
    1.28263e-006, 4.22604493736, 20.7753954924,
    1.27907e-006, 0.96209822685, 5661.3320491522,
    1.05547e-006, 1.53721191253, 801.8209311238,
];

const VenusTermL1 = [
    10213.529430529, 0, 0,
    0.00095707712, 2.46424448979, 10213.285546211,
    0.00014444977, 0.51624564679, 20426.571092422,
    2.13374e-006, 1.79547929368, 30639.856638633,
    1.73904e-006, 2.65535879443, 26.2983197998,
    1.51669e-006, 6.10635282369, 1577.3435424478,
    8.2233e-007, 5.7023413373, 191.4482661116,
    6.9734e-007, 2.68136034979, 9437.762934887,
    5.2408e-007, 3.60013087656, 775.522611324,
    3.8318e-007, 1.03379038025, 529.6909650946,
    2.9633e-007, 1.25056322354, 5507.5532386674,
    2.5056e-007, 6.10664792855, 10404.7338123226,
];

const VenusTermL2 = [
    0.00054127076, 0, 0,
    3.89146e-005, 0.34514360047, 10213.285546211,
    1.33788e-005, 2.02011286082, 20426.571092422,
    2.3836e-007, 2.04592119012, 26.2983197998,
    1.9331e-007, 3.53527371458, 30639.856638633,
    9.984e-008, 3.97130221102, 775.522611324,
    7.046e-008, 1.51962593409, 1577.3435424478,
    6.014e-008, 0.99926757893, 191.4482661116,
];

const VenusTermL3 = [
    1.35742e-006, 4.80389020993, 10213.285546211,
    7.7846e-007, 3.66876371591, 20426.571092422,
    2.6023e-007, 0, 0,
];

const VenusTermL4 = [
    1.14016e-006, 3.14159265359, 0,
    3.209e-008, 5.20514170164, 20426.571092422,
    1.714e-008, 2.51099591706, 10213.285546211,
];

const VenusTermL5 = [
    8.74e-009, 3.14159265359, 0,
];

const VenusTermB0 = [
    0.05923638472, 0.26702775813, 10213.285546211,
    0.00040107978, 1.14737178106, 20426.571092422,
    0.00032814918, 3.14159265359, 0,
    1.011392e-005, 1.08946123021, 30639.856638633,
    1.49458e-006, 6.25390296069, 18073.7049386502,
    1.37788e-006, 0.86020146523, 1577.3435424478,
    1.29973e-006, 3.67152483651, 9437.762934887,
    1.19507e-006, 3.70468812804, 2352.8661537718,
    1.07971e-006, 4.53903677647, 22003.9146348698,
];

const VenusTermB1 = [
    0.00513347602, 1.80364310797, 10213.285546211,
    4.3801e-005, 3.38615711591, 20426.571092422,
    1.99162e-006, 0, 0,
    1.96586e-006, 2.53001197486, 30639.856638633,
];

const VenusTermB2 = [
    0.00022377665, 3.38509143877, 10213.285546211,
    2.81739e-006, 0, 0,
    1.73164e-006, 5.25563766915, 20426.571092422,
    2.6945e-007, 3.87040891568, 30639.856638633,
];

const VenusTermB3 = [
    6.46671e-006, 4.99166565277, 10213.285546211,
    1.9952e-007, 3.14159265359, 0,
    5.54e-008, 0.77376923951, 20426.571092422,
    2.526e-008, 5.4449376302, 30639.856638633,
];

const VenusTermB4 = [
    1.4102e-007, 0.31537190181, 10213.285546211,
];

const VenusTermR0 = [
    0.72334820905, 0, 0,
    0.00489824185, 4.02151832268, 10213.285546211,
    1.658058e-005, 4.90206728012, 20426.571092422,
    1.632093e-005, 2.84548851892, 7860.4193924392,
    1.378048e-005, 1.128465906, 11790.6290886588,
    4.98399e-006, 2.58682187717, 9683.5945811164,
    3.73958e-006, 1.42314837063, 3930.2096962196,
    2.63616e-006, 5.5293818592, 9437.762934887,
    2.37455e-006, 2.55135903978, 15720.8387848784,
    2.21983e-006, 2.01346776772, 19367.1891622328,
    1.25896e-006, 2.72769833559, 1577.3435424478,
    1.19467e-006, 3.01975365264, 10404.7338123226,
];

const VenusTermR1 = [
    0.00034551039, 0.89198710598, 10213.285546211,
    2.34203e-006, 1.77224942714, 20426.571092422,
    2.33998e-006, 3.14159265359, 0,
];

const VenusTermR2 = [
    1.406587e-005, 5.0636639519, 10213.285546211,
    1.5529e-007, 5.47321687981, 20426.571092422,
    1.3059e-007, 0, 0,
];

const VenusTermR3 = [
    4.9582e-007, 3.2226355452, 10213.285546211,
];

const VenusTermR4 = [
    5.73e-009, 0.9222969782, 10213.285546211,
];

const VenusTerms: pTerms[] = [
    { termArray: VenusTermL0, termCount: 24 },
    { termArray: VenusTermL1, termCount: 12 },
    { termArray: VenusTermL2, termCount: 8 },
    { termArray: VenusTermL3, termCount: 3 },
    { termArray: VenusTermL4, termCount: 3 },
    { termArray: VenusTermL5, termCount: 1 },
    { termArray: VenusTermB0, termCount: 9 },
    { termArray: VenusTermB1, termCount: 4 },
    { termArray: VenusTermB2, termCount: 4 },
    { termArray: VenusTermB3, termCount: 4 },
    { termArray: VenusTermB4, termCount: 1 },
    { termArray: [], termCount: 0 },
    { termArray: VenusTermR0, termCount: 12 },
    { termArray: VenusTermR1, termCount: 3 },
    { termArray: VenusTermR2, termCount: 3 },
    { termArray: VenusTermR3, termCount: 1 },
    { termArray: VenusTermR4, termCount: 1 },
    { termArray: [], termCount: 0 },
];

const EarthTermL0 = [
    1.75347045673, 0, 0,
    0.03341656456, 4.66925680417, 6283.0758499914,
    0.00034894275, 4.62610241759, 12566.1516999828,
    3.497056e-005, 2.74411800971, 5753.3848848968,
    3.417571e-005, 2.82886579606, 3.523118349,
    3.135896e-005, 3.62767041758, 77713.7714681205,
    2.676218e-005, 4.41808351397, 7860.4193924392,
    2.342687e-005, 6.13516237631, 3930.2096962196,
    1.324292e-005, 0.74246356352, 11506.7697697936,
    1.273166e-005, 2.03709655772, 529.6909650946,
    1.199167e-005, 1.10962944315, 1577.3435424478,
    9.9025e-006, 5.23268129594, 5884.9268465832,
    9.01855e-006, 2.04505443513, 26.2983197998,
    8.57223e-006, 3.50849156957, 398.1490034082,
    7.79786e-006, 1.17882652114, 5223.6939198022,
    7.53141e-006, 2.53339053818, 5507.5532386674,
    5.05264e-006, 4.58292563052, 18849.2275499742,
    4.92379e-006, 4.20506639861, 775.522611324,
    3.56655e-006, 2.91954116867, 0.0673103028,
    3.17087e-006, 5.84901952218, 11790.6290886588,
    2.84125e-006, 1.89869034186, 796.2980068164,
    2.71039e-006, 0.31488607649, 10977.078804699,
    2.4281e-006, 0.34481140906, 5486.777843175,
    2.0616e-006, 4.80646606059, 2544.3144198834,
    2.05385e-006, 1.86947813692, 5573.1428014331,
    2.02261e-006, 2.45767795458, 6069.7767545534,
    1.55516e-006, 0.83306073807, 213.299095438,
    1.32212e-006, 3.41118275555, 2942.4634232916,
    1.26184e-006, 1.0830263021, 20.7753954924,
    1.15132e-006, 0.64544911683, 0.9803210682,
    1.02851e-006, 0.63599846727, 4694.0029547076,
    1.01895e-006, 0.97569221824, 15720.8387848784,
    1.01724e-006, 4.26679821365, 7.1135470008,
    9.9206e-007, 6.20992940258, 2146.1654164752,
    9.7607e-007, 0.6810127227, 155.4203994342,
    8.5803e-007, 5.98322631256, 161000.685737674,
    8.5128e-007, 1.29870743025, 6275.9623029906,
    8.4711e-007, 3.67080093025, 71430.6956181291,
    7.9637e-007, 1.807913307, 17260.1546546904,
    7.8756e-007, 3.03698313141, 12036.4607348882,
    7.4651e-007, 1.75508916159, 5088.6288397668,
    7.3874e-007, 3.50319443167, 3154.6870848956,
    7.3547e-007, 4.67926565481, 801.8209311238,
    6.9627e-007, 0.83297596966, 9437.762934887,
    6.2449e-007, 3.97763880587, 8827.3902698748,
    6.1148e-007, 1.81839811024, 7084.8967811152,
    5.6963e-007, 2.78430398043, 6286.5989683404,
    5.6116e-007, 4.38694880779, 14143.4952424306,
    5.5577e-007, 3.47006009062, 6279.5527316424,
    5.1992e-007, 0.18914945834, 12139.5535091068,
    5.1605e-007, 1.33282746983, 1748.016413067,
    5.1145e-007, 0.28306864501, 5856.4776591154,
    4.9e-007, 0.48735065033, 1194.4470102246,
    4.1036e-007, 5.36817351402, 8429.2412664666,
    4.0938e-007, 2.39850881707, 19651.048481098,
    3.92e-007, 6.16832995016, 10447.3878396044,
    3.677e-007, 6.04133859347, 10213.285546211,
    3.6596e-007, 2.56955238628, 1059.3819301892,
    3.5954e-007, 1.70876111898, 2352.8661537718,
    3.5566e-007, 1.77597314691, 6812.766815086,
    3.3291e-007, 0.59309499459, 17789.845619785,
    3.0412e-007, 0.44294464135, 83996.8473181119,
    3.0047e-007, 2.73975123935, 1349.8674096588,
    2.5352e-007, 3.16470953405, 4690.4798363586,
];

const EarthTermL1 = [
    6283.31966747491, 0, 0,
    0.00206058863, 2.67823455584, 6283.0758499914,
    4.30343e-005, 2.63512650414, 12566.1516999828,
    4.25264e-006, 1.59046980729, 3.523118349,
    1.19261e-006, 5.79557487799, 26.2983197998,
    1.08977e-006, 2.96618001993, 1577.3435424478,
    9.3478e-007, 2.59212835365, 18849.2275499742,
    7.2122e-007, 1.13846158196, 529.6909650946,
    6.7768e-007, 1.87472304791, 398.1490034082,
    6.7327e-007, 4.40918235168, 5507.5532386674,
    5.9027e-007, 2.8879703846, 5223.6939198022,
    5.5976e-007, 2.17471680261, 155.4203994342,
    4.5407e-007, 0.39803079805, 796.2980068164,
    3.6369e-007, 0.46624739835, 775.522611324,
    2.8958e-007, 2.64707383882, 7.1135470008,
    2.0844e-007, 5.34138275149, 0.9803210682,
    1.9097e-007, 1.84628332577, 5486.777843175,
    1.8508e-007, 4.96855124577, 213.299095438,
    1.7293e-007, 2.99116864949, 6275.9623029906,
    1.6233e-007, 0.03216483047, 2544.3144198834,
    1.5832e-007, 1.43049285325, 2146.1654164752,
    1.4615e-007, 1.20532366323, 10977.078804699,
    1.2461e-007, 2.83432285512, 1748.016413067,
    1.1877e-007, 3.25804815607, 5088.6288397668,
    1.1808e-007, 5.2737979048, 1194.4470102246,
    1.1514e-007, 2.07502418155, 4694.0029547076,
    1.0641e-007, 0.76614199202, 553.5694028424,
    9.969e-008, 1.30262991097, 6286.5989683404,
    9.721e-008, 4.23925472239, 1349.8674096588,
    9.452e-008, 2.69957062864, 242.728603974,
    8.577e-008, 5.64475868067, 951.7184062506,
    7.576e-008, 5.30062664886, 2352.8661537718,
    6.385e-008, 2.65033984967, 9437.762934887,
    6.101e-008, 4.66632584188, 4690.4798363586,
];

const EarthTermL2 = [
    0.0005291887, 0, 0,
    8.719837e-005, 1.07209665242, 6283.0758499914,
    3.09125e-006, 0.86728818832, 12566.1516999828,
    2.7339e-007, 0.05297871691, 3.523118349,
    1.6334e-007, 5.18826691036, 26.2983197998,
    1.5752e-007, 3.6845788943, 155.4203994342,
    9.541e-008, 0.75742297675, 18849.2275499742,
    8.937e-008, 2.05705419118, 77713.7714681205,
    6.952e-008, 0.8267330541, 775.522611324,
    5.064e-008, 4.66284525271, 1577.3435424478,
    4.061e-008, 1.03057162962, 7.1135470008,
    3.81e-008, 3.4405080349, 5573.1428014331,
    3.463e-008, 5.14074632811, 796.2980068164,
    3.169e-008, 6.05291851171, 5507.5532386674,
    3.02e-008, 1.19246506441, 242.728603974,
    2.886e-008, 6.11652627155, 529.6909650946,
    2.714e-008, 0.30637881025, 398.1490034082,
    2.538e-008, 2.27992810679, 553.5694028424,
    2.371e-008, 4.38118838167, 5223.6939198022,
    2.079e-008, 3.75435330484, 0.9803210682,
];

const EarthTermL3 = [
    2.89226e-006, 5.84384198723, 6283.0758499914,
    3.4955e-007, 0, 0,
    1.6819e-007, 5.48766912348, 12566.1516999828,
    2.962e-008, 5.19577265202, 155.4203994342,
    1.288e-008, 4.72200252235, 3.523118349,
    7.14e-009, 5.30045809128, 18849.2275499742,
    6.35e-009, 5.96925937141, 242.728603974,
];

const EarthTermL4 = [
    1.14084e-006, 3.14159265359, 0,
    7.717e-008, 4.13446589358, 6283.0758499914,
    7.65e-009, 3.83803776214, 12566.1516999828,
];

const EarthTermL5 = [
    8.78e-009, 3.14159265359, 0,
];

const EarthTermB0 = [
    2.7962e-006, 3.19870156017, 84334.6615813083,
    1.01643e-006, 5.42248619256, 5507.5532386674,
    8.0445e-007, 3.88013204458, 5223.6939198022,
    4.3806e-007, 3.70444689758, 2352.8661537718,
    3.1933e-007, 4.00026369781, 1577.3435424478,
];

const EarthTermB1 = [
    9.03e-008, 3.8972906189, 5507.5532386674,
    6.177e-008, 1.73038850355, 5223.6939198022,
];

const EarthTermR0 = [
    1.00013988799, 0, 0,
    0.01670699626, 3.09846350771, 6283.0758499914,
    0.00013956023, 3.0552460962, 12566.1516999828,
    3.08372e-005, 5.19846674381, 77713.7714681205,
    1.628461e-005, 1.17387749012, 5753.3848848968,
    1.575568e-005, 2.84685245825, 7860.4193924392,
    9.24799e-006, 5.45292234084, 11506.7697697936,
    5.42444e-006, 4.56409149777, 3930.2096962196,
    4.7211e-006, 3.66100022149, 5884.9268465832,
    3.45983e-006, 0.96368617687, 5507.5532386674,
    3.2878e-006, 5.89983646482, 5223.6939198022,
    3.06784e-006, 0.29867139512, 5573.1428014331,
    2.43189e-006, 4.27349536153, 11790.6290886588,
    2.11829e-006, 5.84714540314, 1577.3435424478,
    1.85752e-006, 5.02194447178, 10977.078804699,
    1.74844e-006, 3.01193636534, 18849.2275499742,
    1.09835e-006, 5.05510636285, 5486.777843175,
    9.8316e-007, 0.88681311277, 6069.7767545534,
    8.6499e-007, 5.68959778254, 15720.8387848784,
    8.5825e-007, 1.27083733351, 161000.685737674,
    6.4903e-007, 0.27250613787, 17260.1546546904,
    6.2916e-007, 0.92177108832, 529.6909650946,
    5.7056e-007, 2.01374292014, 83996.8473181119,
    5.5736e-007, 5.24159798933, 71430.6956181291,
    4.9384e-007, 3.24501240359, 2544.3144198834,
    4.6963e-007, 2.57805070386, 775.522611324,
    4.4661e-007, 5.53715807302, 9437.762934887,
    4.2515e-007, 6.01110242003, 6275.9623029906,
    3.8968e-007, 5.36071738169, 4694.0029547076,
    3.8245e-007, 2.39255343974, 8827.3902698748,
    3.749e-007, 0.82952922332, 19651.048481098,
    3.6957e-007, 4.90107591914, 12139.5535091068,
    3.566e-007, 1.67468058995, 12036.4607348882,
    3.4537e-007, 1.84270693282, 2942.4634232916,
    3.3193e-007, 0.24370300098, 7084.8967811152,
    3.1921e-007, 0.18368229781, 5088.6288397668,
    3.1846e-007, 1.77775642085, 398.1490034082,
    2.8464e-007, 1.21344868176, 6286.5989683404,
    2.7793e-007, 1.89934330904, 6279.5527316424,
    2.6275e-007, 4.58896850401, 10447.3878396044,
];

const EarthTermR1 = [
    0.00103018608, 1.10748969588, 6283.0758499914,
    1.721238e-005, 1.06442301418, 12566.1516999828,
    7.02215e-006, 3.14159265359, 0,
    3.2346e-007, 1.02169059149, 18849.2275499742,
    3.0799e-007, 2.84353804832, 5507.5532386674,
    2.4971e-007, 1.31906709482, 5223.6939198022,
    1.8485e-007, 1.42429748614, 1577.3435424478,
    1.0078e-007, 5.91378194648, 10977.078804699,
    8.654e-008, 1.42046854427, 6275.9623029906,
    8.634e-008, 0.27146150602, 5486.777843175,
];

const EarthTermR2 = [
    4.359385e-005, 5.78455133738, 6283.0758499914,
    1.23633e-006, 5.57934722157, 12566.1516999828,
    1.2341e-007, 3.14159265359, 0,
    8.792e-008, 3.62777733395, 77713.7714681205,
    5.689e-008, 1.86958905084, 5573.1428014331,
    3.301e-008, 5.47027913302, 18849.2275499742,
];

const EarthTermR3 = [
    1.44595e-006, 4.27319435148, 6283.0758499914,
    6.729e-008, 3.91697608662, 12566.1516999828,
];

const EarthTermR4 = [
    3.858e-008, 2.56384387339, 6283.0758499914,
];

const EarthTerms: pTerms[] = [
    { termArray: EarthTermL0, termCount: 64 },
    { termArray: EarthTermL1, termCount: 34 },
    { termArray: EarthTermL2, termCount: 20 },
    { termArray: EarthTermL3, termCount: 7 },
    { termArray: EarthTermL4, termCount: 3 },
    { termArray: EarthTermL5, termCount: 1 },
    { termArray: EarthTermB0, termCount: 5 },
    { termArray: EarthTermB1, termCount: 2 },
    { termArray: [], termCount: 0 },
    { termArray: [], termCount: 0 },
    { termArray: [], termCount: 0 },
    { termArray: [], termCount: 0 },
    { termArray: EarthTermR0, termCount: 40 },
    { termArray: EarthTermR1, termCount: 10 },
    { termArray: EarthTermR2, termCount: 6 },
    { termArray: EarthTermR3, termCount: 2 },
    { termArray: EarthTermR4, termCount: 1 },
    { termArray: [], termCount: 0 },
];

const MarsTermL0 = [
    6.20347711583, 0, 0,
    0.186563681, 5.05037100303, 3340.6124266998,
    0.01108216792, 5.40099836958, 6681.2248533996,
    0.00091798394, 5.75478745111, 10021.8372800994,
    0.00027744987, 5.97049512942, 3.523118349,
    0.00012315897, 0.84956081238, 2810.9214616052,
    0.0001061023, 2.93958524973, 2281.2304965106,
    8.926772e-005, 4.15697845939, 0.0172536522,
    8.715688e-005, 6.11005159792, 13362.4497067992,
    7.774867e-005, 3.33968655074, 5621.8429232104,
    6.797552e-005, 0.36462243626, 398.1490034082,
    4.161101e-005, 0.2281497533, 2942.4634232916,
    3.575079e-005, 1.66186540141, 2544.3144198834,
    3.07525e-005, 0.85696597082, 191.4482661116,
    2.937543e-005, 6.07893711408, 0.0673103028,
    2.628122e-005, 0.6480614357, 3337.0893083508,
    2.579842e-005, 0.02996706197, 3344.1355450488,
    2.38942e-005, 5.03896401349, 796.2980068164,
    1.798808e-005, 0.65634026844, 529.6909650946,
    1.546408e-005, 2.91579633392, 1751.539531416,
    1.52814e-005, 1.14979306228, 6151.533888305,
    1.286232e-005, 3.06795924626, 2146.1654164752,
    1.264356e-005, 3.62275092231, 5092.1519581158,
    1.024907e-005, 3.69334293555, 8962.4553499102,
    8.91567e-006, 0.1829389909, 16703.062133499,
    8.5876e-006, 2.40093704204, 2914.0142358238,
    8.32724e-006, 4.49495753458, 3340.629680352,
    8.32718e-006, 2.46418591282, 3340.5951730476,
    7.48724e-006, 3.82248399468, 155.4203994342,
    7.23863e-006, 0.67497565801, 3738.761430108,
    7.12899e-006, 3.66336014788, 1059.3819301892,
    6.55163e-006, 0.48864075176, 3127.3133312618,
    6.35557e-006, 2.92182704275, 8432.7643848156,
    5.52746e-006, 4.47478863016, 1748.016413067,
    5.50472e-006, 3.81001205408, 0.9803210682,
    4.72164e-006, 3.6254781941, 1194.4470102246,
    4.25972e-006, 0.55365138172, 6283.0758499914,
    4.15132e-006, 0.49662314774, 213.299095438,
    3.12141e-006, 0.99853322843, 6677.7017350506,
    3.06552e-006, 0.38052862973, 6684.7479717486,
    3.02377e-006, 4.48618150321, 3532.0606928114,
    2.99396e-006, 2.78323705697, 6254.6266625236,
    2.93199e-006, 4.22131277914, 20.7753954924,
    2.836e-006, 5.76885494123, 3149.1641605882,
    2.81073e-006, 5.88163372945, 1349.8674096588,
    2.74035e-006, 0.13372501211, 3340.6797370026,
    2.74028e-006, 0.54222141841, 3340.545116397,
    2.38857e-006, 5.37155471672, 4136.9104335162,
    2.36114e-006, 5.75504515576, 3333.498879699,
    2.31185e-006, 1.28240685294, 3870.3033917944,
    2.21225e-006, 3.50466672203, 382.8965322232,
    2.04161e-006, 2.82133266185, 1221.8485663214,
    1.93126e-006, 3.35715137745, 3.5904286518,
    1.88639e-006, 1.49103016486, 9492.1463150048,
    1.79196e-006, 1.00561112574, 951.7184062506,
    1.74068e-006, 2.41360332576, 553.5694028424,
    1.7211e-006, 0.43943041719, 5486.777843175,
    1.60011e-006, 3.94854735192, 4562.4609930212,
    1.44305e-006, 1.41874193418, 135.0650800354,
    1.39897e-006, 3.32592516164, 2700.7151403858,
    1.38245e-006, 4.30145176915, 7.1135470008,
    1.30993e-006, 4.04491720264, 12303.06777661,
    1.28102e-006, 2.20806651008, 1592.5960136328,
    1.28062e-006, 1.80665643332, 5088.6288397668,
    1.16945e-006, 3.12805282207, 7903.073419721,
    1.13486e-006, 3.70070798123, 1589.0728952838,
    1.10375e-006, 1.05195079687, 242.728603974,
    1.04541e-006, 0.78535382076, 8827.3902698748,
    1.0009e-006, 3.24343740861, 11773.3768115154,
];

const MarsTermL1 = [
    3340.85627474342, 0, 0,
    0.01458227051, 3.60426053609, 3340.6124266998,
    0.00164901343, 3.92631250962, 6681.2248533996,
    0.00019963338, 4.2659406103, 10021.8372800994,
    3.452399e-005, 4.73210386365, 3.523118349,
    2.48548e-005, 4.61277567318, 13362.4497067992,
    8.41551e-006, 4.45858256765, 2281.2304965106,
    5.37566e-006, 5.01589727492, 398.1490034082,
    5.21041e-006, 4.99422678175, 3344.1355450488,
    4.32614e-006, 2.5606640286, 191.4482661116,
    4.29656e-006, 5.31646162367, 155.4203994342,
    3.81747e-006, 3.53881289437, 796.2980068164,
    3.14129e-006, 4.96335266049, 16703.062133499,
    2.82804e-006, 3.15967518204, 2544.3144198834,
    2.05664e-006, 4.5689145566, 2146.1654164752,
    1.68805e-006, 1.32894813366, 3337.0893083508,
    1.57587e-006, 4.18501035954, 1751.539531416,
    1.33686e-006, 2.23325104196, 0.9803210682,
    1.33563e-006, 5.97421903927, 1748.016413067,
    1.17591e-006, 6.02407213861, 6151.533888305,
    1.16561e-006, 2.21347652545, 1059.3819301892,
    1.13876e-006, 2.12869455089, 1194.4470102246,
    1.13595e-006, 5.42803224317, 3738.761430108,
    9.1098e-007, 1.09627836591, 1349.8674096588,
    8.5342e-007, 3.90854841008, 553.5694028424,
    8.3301e-007, 5.29636626272, 6684.7479717486,
    8.0776e-007, 4.42813405865, 529.6909650946,
    7.9531e-007, 2.2486426633, 8962.4553499102,
    7.2946e-007, 2.50189460554, 951.7184062506,
    7.2505e-007, 5.8420816324, 242.728603974,
    7.1487e-007, 3.85636094435, 2914.0142358238,
    6.7582e-007, 5.02327686473, 382.8965322232,
    6.5089e-007, 1.01802439311, 3340.5951730476,
    6.5089e-007, 3.04879603978, 3340.629680352,
    6.1508e-007, 4.151831598, 3149.1641605882,
    5.652e-007, 3.8881369932, 4136.9104335162,
    4.8477e-007, 4.87362121538, 213.299095438,
    4.7613e-007, 1.18238046057, 3333.498879699,
    4.6584e-007, 1.31452419914, 3185.1920272656,
    4.1343e-007, 0.71385375517, 1592.5960136328,
    4.0272e-007, 2.72542480614, 7.1135470008,
    4.0055e-007, 5.31611875491, 20043.6745601988,
    3.2886e-007, 5.41067411968, 6283.0758499914,
    2.8244e-007, 0.04534124888, 9492.1463150048,
    2.6579e-007, 3.88960724782, 1221.8485663214,
    2.6554e-007, 5.11271747607, 2700.7151403858,
];

const MarsTermL2 = [
    0.00058015791, 2.04979463279, 3340.6124266998,
    0.00054187645, 0, 0,
    0.00013908426, 2.45742359888, 6681.2248533996,
    2.465104e-005, 2.80000020929, 10021.8372800994,
    3.98379e-006, 3.14118428289, 13362.4497067992,
    2.22022e-006, 3.19436080019, 3.523118349,
    1.20957e-006, 0.54325292454, 155.4203994342,
    6.1517e-007, 3.48529427371, 16703.062133499,
    5.3638e-007, 3.54191121461, 3344.1355450488,
    3.4268e-007, 6.00188499119, 2281.2304965106,
    3.1665e-007, 4.14015171788, 191.4482661116,
    2.9839e-007, 1.99870679845, 796.2980068164,
    2.3168e-007, 4.33403365928, 242.728603974,
    2.1659e-007, 3.44532466378, 398.1490034082,
    2.037e-007, 5.421913754, 553.5694028424,
    1.6227e-007, 0.65678953303, 0.9803210682,
    1.6044e-007, 6.11000472441, 2146.1654164752,
    1.5648e-007, 1.2208612194, 1748.016413067,
    1.4927e-007, 6.09541783564, 3185.1920272656,
    1.4416e-007, 4.01923812101, 951.7184062506,
    1.4317e-007, 2.61851897591, 1349.8674096588,
    1.3352e-007, 0.60189008414, 1194.4470102246,
    1.1934e-007, 3.86122163021, 6684.7479717486,
    1.126e-007, 4.71822363671, 2544.3144198834,
    1.0396e-007, 0.25038714677, 382.8965322232,
    9.468e-008, 0.68170713564, 1059.3819301892,
    9.229e-008, 3.83209092321, 20043.6745601988,
    9.005e-008, 3.88271826102, 3738.761430108,
    7.501e-008, 5.46498630412, 1751.539531416,
    6.859e-008, 2.57522504136, 3149.1641605882,
    6.681e-008, 2.37843690339, 4136.9104335162,
    6.497e-008, 5.47773072872, 1592.5960136328,
    6.311e-008, 2.34104793674, 3097.88382272579,
];

const MarsTermL3 = [
    1.482423e-005, 0.44434694876, 3340.6124266998,
    6.62095e-006, 0.88469178686, 6681.2248533996,
    1.88268e-006, 1.28799982497, 10021.8372800994,
    4.1474e-007, 1.64850786997, 13362.4497067992,
    2.5994e-007, 0, 0,
    2.2661e-007, 2.05267665262, 155.4203994342,
    1.0454e-007, 1.58006906385, 3.523118349,
    8.024e-008, 1.99858757687, 16703.062133499,
    4.9e-008, 2.82452457966, 242.728603974,
    3.782e-008, 2.01914272515, 3344.1355450488,
    3.176e-008, 4.59144897927, 3185.1920272656,
    3.134e-008, 0.65044714325, 553.5694028424,
];

const MarsTermL4 = [
    1.13969e-006, 3.14159265359, 0,
    2.8725e-007, 5.63662412043, 6681.2248533996,
    2.4447e-007, 5.13868481454, 3340.6124266998,
    1.1187e-007, 6.03161074431, 10021.8372800994,
    3.252e-008, 0.13228350651, 13362.4497067992,
    3.19e-008, 3.56267988299, 155.4203994342,
    7.87e-009, 0.49340783377, 16703.062133499,
    7.76e-009, 1.31734531594, 242.728603974,
];

const MarsTermL5 = [
    8.68e-009, 3.14159265359, 0,
    7.1e-009, 4.04089996521, 6681.2248533996,
];

const MarsTermB0 = [
    0.03197134986, 3.76832042432, 3340.6124266998,
    0.00298033234, 4.10616996243, 6681.2248533996,
    0.00289104742, 0, 0,
    0.00031365538, 4.44651052853, 10021.8372800994,
    3.4841e-005, 4.78812547889, 13362.4497067992,
    4.43401e-006, 5.02642620491, 3344.1355450488,
    4.42999e-006, 5.65233015876, 3337.0893083508,
    3.99109e-006, 5.130568147, 16703.062133499,
    2.92506e-006, 3.79290644595, 2281.2304965106,
    1.81982e-006, 6.13648011704, 6151.533888305,
    1.63159e-006, 4.26399626634, 529.6909650946,
    1.59678e-006, 2.23194610246, 1059.3819301892,
    1.49297e-006, 2.16501209917, 5621.8429232104,
    1.42686e-006, 1.1821501611, 3340.5951730476,
    1.42685e-006, 3.2129218082, 3340.629680352,
    1.39323e-006, 2.41796344238, 8962.4553499102,
];

const MarsTermB1 = [
    0.00350068845, 5.36847836211, 3340.6124266998,
    0.0001411603, 3.14159265359, 0,
    9.670755e-005, 5.47877786506, 6681.2248533996,
    1.471918e-005, 3.20205766795, 10021.8372800994,
    4.25864e-006, 3.40843812875, 13362.4497067992,
    1.02039e-006, 0.77617286189, 3337.0893083508,
    7.8848e-007, 3.71768293865, 16703.062133499,
    3.2708e-007, 3.45803723682, 5621.8429232104,
    2.6171e-007, 2.48293558065, 2281.2304965106,
];

const MarsTermB2 = [
    0.0001672669, 0.60221392419, 3340.6124266998,
    4.986799e-005, 3.14159265359, 0,
    3.02141e-006, 5.55871276021, 6681.2248533996,
    2.5767e-007, 1.89662673499, 13362.4497067992,
    2.1452e-007, 0.91749968618, 10021.8372800994,
    1.182e-007, 2.242407387, 3337.0893083508,
    7.985e-008, 2.24892866611, 16703.062133499,
];

const MarsTermB3 = [
    6.06506e-006, 1.98050633529, 3340.6124266998,
    4.2611e-007, 0, 0,
    1.3652e-007, 1.795882288, 6681.2248533996,
    2.73e-008, 3.45377082121, 10021.8372800994,
];

const MarsTermB4 = [
    1.3369e-007, 0, 0,
    1.1334e-007, 3.45724352586, 3340.6124266998,
    7.44e-009, 0.50445805257, 6681.2248533996,
];

const MarsTermR0 = [
    1.53033488276, 0, 0,
    0.14184953153, 3.47971283519, 3340.6124266998,
    0.00660776357, 3.81783442097, 6681.2248533996,
    0.00046179117, 4.15595316284, 10021.8372800994,
    8.109738e-005, 5.55958460165, 2810.9214616052,
    7.485315e-005, 1.77238998069, 5621.8429232104,
    5.523193e-005, 1.3643631888, 2281.2304965106,
    3.82516e-005, 4.49407182408, 13362.4497067992,
    2.484385e-005, 4.92545577893, 2942.4634232916,
    2.306539e-005, 0.09081742493, 2544.3144198834,
    1.999399e-005, 5.36059605227, 3337.0893083508,
    1.960198e-005, 4.74249386323, 3344.1355450488,
    1.167115e-005, 2.11261501155, 5092.1519581158,
    1.102828e-005, 5.0090826416, 398.1490034082,
    9.92252e-006, 5.83862401067, 6151.533888305,
    8.99077e-006, 4.40790433994, 529.6909650946,
    8.07348e-006, 2.10216647104, 1059.3819301892,
    7.9791e-006, 3.44839026172, 796.2980068164,
    7.4098e-006, 1.49906336892, 2146.1654164752,
    7.25583e-006, 1.24516913473, 8432.7643848156,
    6.9234e-006, 2.13378814785, 8962.4553499102,
    6.33144e-006, 0.89353285018, 3340.5951730476,
    6.3314e-006, 2.92430448169, 3340.629680352,
    6.29976e-006, 1.28738135858, 1751.539531416,
    5.74352e-006, 0.82896196337, 2914.0142358238,
    5.26187e-006, 5.38292276228, 3738.761430108,
    4.72776e-006, 5.19850457873, 3127.3133312618,
    3.48095e-006, 4.83219198908, 16703.062133499,
    2.83702e-006, 2.90692294913, 3532.0606928114,
    2.79552e-006, 5.25749247548, 6283.0758499914,
    2.75501e-006, 1.21767967781, 6254.6266625236,
    2.75224e-006, 2.90818883832, 1748.016413067,
    2.69891e-006, 3.76394728622, 5884.9268465832,
    2.39133e-006, 2.03669896238, 1194.4470102246,
    2.33827e-006, 5.10546492529, 5486.777843175,
    2.28128e-006, 3.2552902062, 6872.6731195112,
    2.2319e-006, 4.19861593779, 3149.1641605882,
    2.19428e-006, 5.58340248784, 191.4482661116,
    2.08336e-006, 4.84626442122, 3340.6797370026,
    2.08333e-006, 5.25476080773, 3340.545116397,
    1.86213e-006, 5.69871555748, 6677.7017350506,
    1.82686e-006, 5.08062683355, 6684.7479717486,
    1.78613e-006, 4.18423025538, 3333.498879699,
    1.75995e-006, 5.95341786369, 3870.3033917944,
    1.63534e-006, 3.79889068111, 4136.9104335162,
];

const MarsTermR1 = [
    0.0110743334, 2.0325052495, 3340.6124266998,
    0.00103175886, 2.37071845682, 6681.2248533996,
    0.000128772, 0, 0,
    0.0001081588, 2.70888093803, 10021.8372800994,
    1.19455e-005, 3.04702182503, 13362.4497067992,
    4.38579e-006, 2.88835072628, 2281.2304965106,
    3.95698e-006, 3.42324611291, 3344.1355450488,
    1.82572e-006, 1.58428644001, 2544.3144198834,
    1.3585e-006, 3.38507017993, 16703.062133499,
    1.28362e-006, 6.04343360441, 3337.0893083508,
    1.28204e-006, 0.6299122057, 1059.3819301892,
    1.27068e-006, 1.9538977574, 796.2980068164,
    1.18443e-006, 2.99761345074, 2146.1654164752,
    8.7537e-007, 3.42052758979, 398.1490034082,
    8.3026e-007, 3.85574986653, 3738.761430108,
    7.5598e-007, 4.45101839349, 6151.533888305,
    7.1999e-007, 2.7644218068, 529.6909650946,
    6.6542e-007, 2.54892602695, 1751.539531416,
    6.643e-007, 4.40597549957, 1748.016413067,
    5.7518e-007, 0.54354327916, 1194.4470102246,
    5.4314e-007, 0.67750943459, 8962.4553499102,
    5.1035e-007, 3.72585409207, 6684.7479717486,
    4.9428e-007, 5.72959428364, 3340.5951730476,
    4.9424e-007, 1.47717922226, 3340.629680352,
    4.8318e-007, 2.58061691301, 3149.1641605882,
    4.7863e-007, 2.28527896843, 2914.0142358238,
    3.8953e-007, 2.31900090554, 4136.9104335162,
];

const MarsTermR2 = [
    0.00044242247, 0.47930603943, 3340.6124266998,
    8.138042e-005, 0.86998398093, 6681.2248533996,
    1.274915e-005, 1.22594050809, 10021.8372800994,
    1.87387e-006, 1.57298991982, 13362.4497067992,
    5.2396e-007, 3.14159265359, 0,
    4.0744e-007, 1.9708017506, 3344.1355450488,
    2.6616e-007, 1.91665615762, 16703.062133499,
    1.7825e-007, 4.43499505333, 2281.2304965106,
    1.1713e-007, 4.5251045373, 3185.1920272656,
    1.0209e-007, 5.39143469548, 1059.3819301892,
    9.95e-008, 0.41870577185, 796.2980068164,
];

const MarsTermR3 = [
    1.113107e-005, 5.14987350142, 3340.6124266998,
    4.24446e-006, 5.61343766478, 6681.2248533996,
    1.00044e-006, 5.99726827028, 10021.8372800994,
    1.9606e-007, 0.07633062094, 13362.4497067992,
    4.693e-008, 3.14159265359, 0,
    3.477e-008, 0.42951907576, 16703.062133499,
];

const MarsTermR4 = [
    1.9552e-007, 3.58211650473, 3340.6124266998,
    1.6323e-007, 4.05116076923, 6681.2248533996,
    5.848e-008, 4.46383962094, 10021.8372800994,
    1.532e-008, 4.84374321619, 13362.4497067992,
];

const MarsTerms: pTerms[] = [
    { termArray: MarsTermL0, termCount: 69 },
    { termArray: MarsTermL1, termCount: 46 },
    { termArray: MarsTermL2, termCount: 33 },
    { termArray: MarsTermL3, termCount: 12 },
    { termArray: MarsTermL4, termCount: 8 },
    { termArray: MarsTermL5, termCount: 2 },
    { termArray: MarsTermB0, termCount: 16 },
    { termArray: MarsTermB1, termCount: 9 },
    { termArray: MarsTermB2, termCount: 7 },
    { termArray: MarsTermB3, termCount: 4 },
    { termArray: MarsTermB4, termCount: 3 },
    { termArray: [], termCount: 0 },
    { termArray: MarsTermR0, termCount: 45 },
    { termArray: MarsTermR1, termCount: 27 },
    { termArray: MarsTermR2, termCount: 11 },
    { termArray: MarsTermR3, termCount: 6 },
    { termArray: MarsTermR4, termCount: 4 },
    { termArray: [], termCount: 0 },
];

const JupiterTermL0 = [
    0.59954691495, 0, 0,
    0.09695898711, 5.06191793105, 529.6909650946,
    0.00573610145, 1.44406205976, 7.1135470008,
    0.0030638918, 5.41734729976, 1059.3819301892,
    0.0009717828, 4.14264708819, 632.7837393132,
    0.00072903096, 3.64042909255, 522.5774180938,
    0.00064263986, 3.41145185203, 103.0927742186,
    0.00039806051, 2.29376744855, 419.4846438752,
    0.0003885778, 1.2723172486, 316.3918696566,
    0.00027964622, 1.78454589485, 536.8045120954,
    0.00013589738, 5.7748103159, 1589.0728952838,
    8.768686e-005, 3.63000324417, 949.1756089698,
    8.246362e-005, 3.58227961655, 206.1855484372,
    7.368057e-005, 5.08101125612, 735.8765135318,
    6.263171e-005, 0.02497643742, 213.299095438,
    6.11405e-005, 4.51319531666, 1162.4747044078,
    5.305457e-005, 4.18625053495, 1052.2683831884,
    5.305283e-005, 1.30671236848, 14.2270940016,
    4.905419e-005, 1.32084631684, 110.2063212194,
    4.647249e-005, 4.69958109497, 3.9321532631,
    3.045009e-005, 4.31675960318, 426.598190876,
    2.610001e-005, 1.5666759485, 846.0828347512,
    2.028191e-005, 1.06376547379, 3.1813937377,
    1.920959e-005, 0.97168928755, 639.897286314,
    1.764768e-005, 2.14148077766, 1066.49547719,
    1.722983e-005, 3.88036008872, 1265.5674786264,
    1.633217e-005, 3.58201089758, 515.463871093,
    1.431997e-005, 4.29683690269, 625.6701923124,
    9.73278e-006, 4.09764957065, 95.9792272178,
    8.84439e-006, 2.43701426123, 412.3710968744,
    7.32875e-006, 6.08534113239, 838.9692877504,
    7.31072e-006, 3.80591233956, 1581.959348283,
    7.0919e-006, 1.29272573658, 742.9900605326,
    6.91928e-006, 6.13368222939, 2118.7638603784,
    6.14464e-006, 4.10853496756, 1478.8665740644,
    5.81902e-006, 4.53967717552, 309.2783226558,
    4.95224e-006, 3.75567461379, 323.5054166574,
    4.40854e-006, 2.95818460943, 454.9093665273,
    4.17266e-006, 1.03554430161, 2.4476805548,
    3.89864e-006, 4.89716105852, 1692.1656695024,
    3.75657e-006, 4.70299124833, 1368.660252845,
    3.41006e-006, 5.71452525783, 533.6231183577,
    3.30458e-006, 4.74049819491, 0.0481841098,
    2.6154e-006, 1.87652461032, 0.9632078465,
    2.61009e-006, 0.82047246448, 380.12776796,
    2.56568e-006, 3.72410724159, 199.0720014364,
    2.4417e-006, 5.220208789, 728.762966531,
    2.35141e-006, 1.22693908124, 909.8187330546,
    2.20382e-006, 1.65115015995, 543.9180590962,
    2.07327e-006, 1.85461666594, 525.7588118315,
    2.01996e-006, 1.80684574186, 1375.7737998458,
    1.97046e-006, 5.29252149016, 1155.361157407,
    1.75191e-006, 3.72966554761, 942.062061969,
    1.75184e-006, 3.22634903433, 1898.3512179396,
    1.74809e-006, 5.90973505276, 956.2891559706,
    1.57909e-006, 4.36483921766, 1795.258443721,
    1.50502e-006, 3.90625022622, 74.7815985673,
    1.49368e-006, 4.37745104275, 1685.0521225016,
    1.41445e-006, 3.13568357861, 491.5579294568,
    1.37871e-006, 1.31797920785, 1169.5882514086,
    1.30531e-006, 4.16867945489, 1045.1548361876,
    1.17495e-006, 2.5002214089, 1596.1864422846,
    1.16757e-006, 3.38920921041, 0.5212648618,
    1.05895e-006, 4.55439798236, 526.5095713569,
];

const JupiterTermL1 = [
    529.93480757497, 0, 0,
    0.00489741194, 4.22066689928, 529.6909650946,
    0.00228918538, 6.02647464016, 7.1135470008,
    0.0002765538, 4.57265956824, 1059.3819301892,
    0.00020720943, 5.45938936295, 522.5774180938,
    0.00012105732, 0.16985765041, 536.8045120954,
    6.068051e-005, 4.42419502005, 103.0927742186,
    5.433924e-005, 3.98478382565, 419.4846438752,
    4.237795e-005, 5.89009351271, 14.2270940016,
    2.211854e-005, 5.26771446618, 206.1855484372,
    1.745919e-005, 4.92669378486, 1589.0728952838,
    1.295769e-005, 5.55132765087, 3.1813937377,
    1.173129e-005, 5.8564730435, 1052.2683831884,
    1.163411e-005, 0.51450895328, 3.9321532631,
    1.098735e-005, 5.30704981594, 515.463871093,
    1.007216e-005, 0.46478398551, 735.8765135318,
    1.003574e-005, 3.15040301822, 426.598190876,
    8.47678e-006, 5.7580585045, 110.2063212194,
    8.27329e-006, 4.80312015734, 213.299095438,
    8.16397e-006, 0.58643054886, 1066.49547719,
    7.25447e-006, 5.51827471473, 639.897286314,
    5.67845e-006, 5.98867049451, 625.6701923124,
    4.74181e-006, 4.13245269168, 412.3710968744,
    4.1293e-006, 5.73652891261, 95.9792272178,
    3.45249e-006, 4.2415956541, 632.7837393132,
    3.35817e-006, 3.73248749046, 1162.4747044078,
    2.3434e-006, 4.03469970332, 949.1756089698,
    2.34066e-006, 6.24302226646, 309.2783226558,
    1.98525e-006, 1.50458442825, 838.9692877504,
    1.94784e-006, 2.21879010911, 323.5054166574,
    1.86899e-006, 6.08620565908, 742.9900605326,
    1.83938e-006, 6.27963588822, 543.9180590962,
    1.7138e-006, 5.41655983845, 199.0720014364,
    1.30771e-006, 0.62643377351, 728.762966531,
    1.15393e-006, 0.68019050174, 846.0828347512,
    1.15047e-006, 5.28641699144, 2118.7638603784,
    1.07575e-006, 4.49282760117, 956.2891559706,
    7.9686e-007, 5.82412400273, 1045.1548361876,
    7.1643e-007, 5.34162650321, 942.062061969,
    6.9618e-007, 5.97263450278, 532.8723588323,
    6.6824e-007, 5.73365126533, 21.3406410024,
    6.5635e-007, 0.1292419143, 526.5095713569,
    6.485e-007, 6.08803490288, 1581.959348283,
    5.8509e-007, 0.58626971028, 1155.361157407,
    5.7939e-007, 0.99453087342, 1596.1864422846,
    5.7368e-007, 5.96851304799, 1169.5882514086,
    5.66e-007, 1.41198438841, 533.6231183577,
    5.4935e-007, 5.42806383723, 10.2949407385,
    5.2309e-007, 5.72661448388, 117.3198682202,
    5.2016e-007, 0.22981299129, 1368.660252845,
    5.0418e-007, 6.08075147811, 525.7588118315,
    4.7418e-007, 3.62611843241, 1478.8665740644,
    4.6678e-007, 0.51144073175, 1265.5674786264,
    3.9888e-007, 4.161580136, 1692.1656695024,
    3.3558e-007, 0.09913904872, 302.164775655,
    3.2827e-007, 5.03596689455, 220.4126424388,
    3.2449e-007, 5.37492530697, 508.3503240922,
    2.9483e-007, 5.42208897099, 1272.6810256272,
    2.9379e-007, 3.35927241533, 4.665866446,
    2.9307e-007, 0.75907909735, 88.865680217,
    2.5195e-007, 1.60723063387, 831.8557407496,
];

const JupiterTermL2 = [
    0.00047233598, 4.32148323554, 7.1135470008,
    0.0003896555, 0, 0,
    0.00030629053, 2.93021440216, 529.6909650946,
    3.189317e-005, 1.05504615595, 522.5774180938,
    2.729292e-005, 4.84545481351, 536.8045120954,
    2.723358e-005, 3.41411526638, 1059.3819301892,
    1.721069e-005, 4.18734385158, 14.2270940016,
    3.83258e-006, 5.76790714387, 419.4846438752,
    3.77524e-006, 0.76048964872, 515.463871093,
    3.67498e-006, 6.05509120409, 103.0927742186,
    3.37386e-006, 3.78644384244, 3.1813937377,
    3.082e-006, 0.69356654052, 206.1855484372,
    2.18408e-006, 3.81389191353, 1589.0728952838,
    1.98883e-006, 5.33996443444, 1066.49547719,
    1.97445e-006, 2.48356402053, 3.9321532631,
    1.55862e-006, 1.40642426467, 1052.2683831884,
    1.4623e-006, 3.81373196838, 639.897286314,
    1.41932e-006, 1.63435169016, 426.598190876,
    1.2957e-006, 5.83738872525, 412.3710968744,
    1.17327e-006, 1.41435462588, 625.6701923124,
    9.6733e-007, 4.03383427887, 110.2063212194,
    9.0823e-007, 1.10630629042, 95.9792272178,
    8.7292e-007, 2.52235174825, 632.7837393132,
    7.8769e-007, 4.63726131329, 543.9180590962,
    7.2392e-007, 2.21716670026, 735.8765135318,
    5.8475e-007, 0.83216317444, 199.0720014364,
    5.691e-007, 3.12292059854, 213.299095438,
    4.8622e-007, 1.67283791618, 309.2783226558,
    4.015e-007, 4.0248544474, 21.3406410024,
    3.9784e-007, 0.62416945827, 323.5054166574,
    3.5718e-007, 2.32581247002, 728.762966531,
    2.9255e-007, 3.60838327799, 10.2949407385,
    2.7814e-007, 3.23992013743, 838.9692877504,
    2.5993e-007, 4.5011829829, 742.9900605326,
    2.562e-007, 2.51240623862, 1162.4747044078,
    2.5194e-007, 1.21868110687, 1045.1548361876,
    2.3591e-007, 3.00532139306, 956.2891559706,
    1.9458e-007, 4.29028644674, 532.8723588323,
    1.766e-007, 0.8095394156, 508.3503240922,
    1.7058e-007, 4.20001977723, 2118.7638603784,
    1.704e-007, 1.8340214664, 526.5095713569,
    1.5355e-007, 5.81037986941, 1596.1864422846,
    1.5292e-007, 0.68174165476, 942.062061969,
    1.4661e-007, 3.99989622586, 117.3198682202,
    1.392e-007, 5.95169568482, 316.3918696566,
    1.3639e-007, 1.80336677963, 302.164775655,
    1.323e-007, 2.51856643603, 88.865680217,
    1.2756e-007, 4.36856232414, 1169.5882514086,
    1.0986e-007, 4.43586634639, 525.7588118315,
    9.681e-008, 1.71563161051, 1581.959348283,
    9.437e-008, 2.17684563456, 1155.361157407,
    8.812e-008, 3.29452783338, 220.4126424388,
    8.69e-008, 3.31924493607, 831.8557407496,
    7.823e-008, 5.75672228354, 846.0828347512,
    7.549e-008, 2.70955516779, 533.6231183577,
    6.685e-008, 2.17560093281, 1265.5674786264,
    6.285e-008, 0.49939863541, 949.1756089698,
];

const JupiterTermL3 = [
    6.501665e-005, 2.59862880482, 7.1135470008,
    1.356524e-005, 1.34635886411, 529.6909650946,
    4.70716e-006, 2.47503977883, 14.2270940016,
    4.1696e-006, 3.24451243214, 536.8045120954,
    3.52851e-006, 2.97360159003, 522.5774180938,
    1.5488e-006, 2.07565585817, 1059.3819301892,
    8.6771e-007, 2.51431584316, 515.463871093,
    4.4378e-007, 0, 0,
    3.3538e-007, 3.82633794497, 1066.49547719,
    2.8457e-007, 2.44754756058, 206.1855484372,
    2.3737e-007, 1.27667172313, 412.3710968744,
    2.2644e-007, 2.98231326774, 543.9180590962,
    1.9798e-007, 2.10099934005, 639.897286314,
    1.974e-007, 1.40255938973, 419.4846438752,
    1.8768e-007, 1.593684035, 103.0927742186,
    1.7033e-007, 2.30214681202, 21.3406410024,
    1.6774e-007, 2.59821460673, 1589.0728952838,
    1.6214e-007, 3.14521117299, 625.6701923124,
    1.6055e-007, 3.36030126297, 1052.2683831884,
    1.3392e-007, 2.75973892202, 95.9792272178,
    1.3234e-007, 2.5386224434, 199.0720014364,
    1.2611e-007, 6.265781104, 426.598190876,
    8.701e-008, 1.76334960737, 10.2949407385,
    8.637e-008, 2.26563256289, 110.2063212194,
    6.725e-008, 3.42566433316, 309.2783226558,
    6.527e-008, 4.03869562907, 728.762966531,
    5.675e-008, 2.52096417685, 508.3503240922,
    5.399e-008, 2.91184687105, 1045.1548361876,
    5.368e-008, 5.25196153539, 323.5054166574,
    3.996e-008, 4.30290261177, 88.865680217,
    3.857e-008, 3.52381361552, 302.164775655,
    3.774e-008, 4.09125315146, 735.8765135318,
    3.269e-008, 1.43175991274, 956.2891559706,
    2.783e-008, 4.3581750767, 1596.1864422846,
    2.661e-008, 1.25276590759, 213.299095438,
    2.656e-008, 5.01505839848, 838.9692877504,
    2.553e-008, 2.23785673285, 117.3198682202,
    2.371e-008, 2.89662409244, 742.9900605326,
    2.279e-008, 2.3558187123, 942.062061969,
];

const JupiterTermL4 = [
    6.69483e-006, 0.8528242109, 7.1135470008,
    1.14019e-006, 3.14159265359, 0,
    9.9961e-007, 0.74258947751, 14.2270940016,
    5.0024e-007, 1.65346208248, 536.8045120954,
    4.3585e-007, 5.82026386621, 529.6909650946,
    3.1813e-007, 4.8582998665, 522.5774180938,
    1.4742e-007, 4.29061635784, 515.463871093,
    8.899e-008, 0.71478520741, 1059.3819301892,
    4.957e-008, 1.29502259434, 543.9180590962,
    4.484e-008, 2.31715516627, 1066.49547719,
    4.251e-008, 0.48326797501, 21.3406410024,
    3.1e-008, 3.00245542678, 412.3710968744,
    2.055e-008, 0.39858940218, 639.897286314,
    1.902e-008, 4.25925620271, 199.0720014364,
    1.762e-008, 4.90536207307, 625.6701923124,
    1.695e-008, 4.26147580803, 206.1855484372,
    1.375e-008, 5.25546955667, 1052.2683831884,
    1.203e-008, 4.71614633845, 95.9792272178,
    1.086e-008, 1.28604571172, 1589.0728952838,
];

const JupiterTermL5 = [
    4.9577e-007, 5.25658966184, 7.1135470008,
    1.5761e-007, 5.25126837478, 14.2270940016,
    4.343e-008, 0.01461869263, 536.8045120954,
    1.526e-008, 1.09739911439, 522.5774180938,
    8.45e-009, 3.14159265359, 0,
];

const JupiterTermB0 = [
    0.02268615703, 3.55852606718, 529.6909650946,
    0.00110090358, 0, 0,
    0.00109971634, 3.90809347389, 1059.3819301892,
    8.101427e-005, 3.60509573368, 522.5774180938,
    6.437782e-005, 0.30627121409, 536.8045120954,
    6.043996e-005, 4.25883108794, 1589.0728952838,
    1.10688e-005, 2.98534421928, 1162.4747044078,
    9.44328e-006, 1.67522288396, 426.598190876,
    9.41651e-006, 2.93619072405, 1052.2683831884,
    8.94088e-006, 1.75447429921, 7.1135470008,
    8.35861e-006, 5.17881973234, 103.0927742186,
    7.6728e-006, 2.1547359406, 632.7837393132,
    6.8422e-006, 3.67808770098, 213.299095438,
    6.29223e-006, 0.64343282328, 1066.49547719,
    5.58524e-006, 0.01354830508, 846.0828347512,
    5.3167e-006, 2.70305954352, 110.2063212194,
    4.64449e-006, 1.17337249185, 949.1756089698,
    4.31072e-006, 2.60825000494, 419.4846438752,
    3.51433e-006, 4.61062990714, 2118.7638603784,
    1.3216e-006, 4.7781699067, 742.9900605326,
    1.23148e-006, 3.34968181384, 1692.1656695024,
    1.16379e-006, 1.38688232033, 323.5054166574,
    1.15038e-006, 5.04892295442, 316.3918696566,
    1.03762e-006, 3.7010383811, 515.463871093,
    1.03402e-006, 2.31878999565, 1478.8665740644,
    1.0242e-006, 3.15293785436, 1581.959348283,
];

const JupiterTermB1 = [
    0.00177351787, 5.70166488486, 529.6909650946,
    3.230171e-005, 5.7794161934, 1059.3819301892,
    3.081364e-005, 5.47464296527, 522.5774180938,
    2.211914e-005, 4.73477480209, 536.8045120954,
    1.694232e-005, 3.14159265359, 0,
    3.46445e-006, 4.74595174109, 1052.2683831884,
    2.34264e-006, 5.18856099929, 1066.49547719,
    1.96154e-006, 6.18554286642, 7.1135470008,
    1.50468e-006, 3.92721226087, 1589.0728952838,
    1.14128e-006, 3.4389727183, 632.7837393132,
    9.6667e-007, 2.9142630409, 949.1756089698,
    8.1671e-007, 5.07666097497, 1162.4747044078,
    7.6599e-007, 2.50522188662, 103.0927742186,
    7.6572e-007, 0.61288981445, 419.4846438752,
    7.3875e-007, 5.49958292155, 515.463871093,
    6.0544e-007, 5.44740084359, 213.299095438,
    4.9915e-007, 3.94799616572, 735.8765135318,
    4.6032e-007, 0.53850360901, 110.2063212194,
    4.5123e-007, 1.89516645239, 846.0828347512,
    3.6561e-007, 4.69828392839, 543.9180590962,
    3.6019e-007, 6.10952578764, 316.3918696566,
    3.1975e-007, 4.92452714629, 1581.959348283,
];

const JupiterTermB2 = [
    8.094051e-005, 1.46322843658, 529.6909650946,
    8.13244e-006, 3.14159265359, 0,
    7.42415e-006, 0.95691639003, 522.5774180938,
    3.98951e-006, 2.89888666447, 536.8045120954,
    3.42226e-006, 1.44683789727, 1059.3819301892,
    7.3948e-007, 0.40724675866, 1052.2683831884,
    4.6151e-007, 3.48036895772, 1066.49547719,
    2.9717e-007, 1.92504171329, 1589.0728952838,
    2.9314e-007, 0.99088831805, 515.463871093,
    2.2753e-007, 4.27124052435, 7.1135470008,
    1.3916e-007, 2.92242387338, 543.9180590962,
    1.2067e-007, 5.22168932482, 632.7837393132,
    1.0703e-007, 4.88024222475, 949.1756089698,
    6.078e-008, 6.21089108431, 1045.1548361876,
];

const JupiterTermB3 = [
    2.51624e-006, 3.38087923084, 529.6909650946,
    1.21738e-006, 2.733118372, 522.5774180938,
    4.8694e-007, 1.03689996685, 536.8045120954,
    1.0988e-007, 2.31463561347, 1052.2683831884,
    8.067e-008, 2.76729757621, 515.463871093,
    7.287e-008, 4.25268318975, 1059.3819301892,
    6.205e-008, 1.7811582737, 1066.49547719,
    3.627e-008, 1.13028917221, 543.9180590962,
    2.798e-008, 3.14159265359, 0,
];

const JupiterTermB4 = [
    1.505e-007, 4.52956999637, 522.5774180938,
    5.37e-008, 4.47427159142, 529.6909650946,
    4.456e-008, 5.43908581047, 536.8045120954,
    3.422e-008, 0, 0,
    1.833e-008, 4.51807036227, 515.463871093,
    1.322e-008, 4.20117611581, 1052.2683831884,
];

const JupiterTermB5 = [
    1.445e-008, 0.09198554072, 522.5774180938,
];

const JupiterTermR0 = [
    5.20887429471, 0, 0,
    0.2520932702, 3.49108640015, 529.6909650946,
    0.00610599902, 3.84115365602, 1059.3819301892,
    0.00282029465, 2.57419879933, 632.7837393132,
    0.00187647391, 2.07590380082, 522.5774180938,
    0.00086792941, 0.71001090609, 419.4846438752,
    0.00072062869, 0.21465694745, 536.8045120954,
    0.00065517227, 5.97995850843, 316.3918696566,
    0.00030135275, 2.16132058449, 949.1756089698,
    0.0002913462, 1.6775924371, 103.0927742186,
    0.0002394734, 0.27457854894, 7.1135470008,
    0.00023453209, 3.54023147303, 735.8765135318,
    0.0002228371, 4.19362773546, 1589.0728952838,
    0.000130326, 2.96043055741, 1162.4747044078,
    0.00012749004, 2.71550102862, 1052.2683831884,
    9.703346e-005, 1.90669572402, 206.1855484372,
    9.161431e-005, 4.41352618935, 213.299095438,
    7.894539e-005, 2.47907551404, 426.598190876,
    7.057978e-005, 2.18184753111, 1265.5674786264,
    6.137755e-005, 6.26417542514, 846.0828347512,
    5.477093e-005, 5.65729325169, 639.897286314,
    4.170012e-005, 2.01605033912, 515.463871093,
    4.13689e-005, 2.72219979684, 625.6701923124,
    3.502519e-005, 0.56531297394, 1066.49547719,
    2.616955e-005, 2.00993967129, 1581.959348283,
    2.499966e-005, 4.55182055941, 838.9692877504,
    2.127644e-005, 6.1275146175, 742.9900605326,
    1.911876e-005, 0.85621927419, 412.3710968744,
    1.610549e-005, 3.08867789275, 1368.660252845,
    1.479484e-005, 2.68026191372, 1478.8665740644,
    1.230708e-005, 1.89042979701, 323.5054166574,
    1.21681e-005, 1.80171561024, 110.2063212194,
    1.014959e-005, 1.38673237666, 454.9093665273,
    9.98579e-006, 2.8720894011, 309.2783226558,
    9.61072e-006, 4.54876989805, 2118.7638603784,
    8.85708e-006, 4.14785948471, 533.6231183577,
    8.21465e-006, 1.59342534396, 1898.3512179396,
    8.12036e-006, 5.94091899141, 909.8187330546,
    7.767e-006, 3.6769695469, 728.762966531,
    7.27162e-006, 3.98824686402, 1155.361157407,
    6.55289e-006, 2.79065604219, 1685.0521225016,
    6.53981e-006, 3.38150775269, 1692.1656695024,
    6.20798e-006, 4.82284338962, 956.2891559706,
    6.14784e-006, 2.27624915604, 942.062061969,
    5.6212e-006, 0.08095987241, 543.9180590962,
    5.42221e-006, 0.28360266386, 525.7588118315,
];

const JupiterTermR1 = [
    0.01271801596, 2.64937511122, 529.6909650946,
    0.00061661771, 3.00076251018, 1059.3819301892,
    0.00053443592, 3.89717644226, 522.5774180938,
    0.00041390257, 0, 0,
    0.00031185167, 4.88276663526, 536.8045120954,
    0.0001184719, 2.41329588176, 419.4846438752,
    9.16636e-005, 4.75979408587, 7.1135470008,
    3.403605e-005, 3.34688537997, 1589.0728952838,
    3.203446e-005, 5.21083285476, 735.8765135318,
    3.175763e-005, 2.79297987071, 103.0927742186,
    2.806064e-005, 3.7422369358, 515.463871093,
    2.676575e-005, 4.33052878699, 1052.2683831884,
    2.600003e-005, 3.63435101622, 206.1855484372,
    2.412207e-005, 1.46947308304, 426.598190876,
    2.100507e-005, 3.92762682306, 639.897286314,
    1.646182e-005, 5.30953510947, 1066.49547719,
    1.641257e-005, 4.41628669824, 625.6701923124,
    1.049866e-005, 3.16113622955, 213.299095438,
    1.024802e-005, 2.55432643018, 412.3710968744,
    8.06404e-006, 2.6775080138, 632.7837393132,
    7.40996e-006, 2.17094630558, 1162.4747044078,
    6.76928e-006, 6.2495347979, 838.9692877504,
    5.67076e-006, 4.57655414712, 742.9900605326,
    4.84689e-006, 2.46882793186, 949.1756089698,
    4.68895e-006, 4.70973463481, 543.9180590962,
    4.44683e-006, 0.40281181402, 323.5054166574,
    4.15894e-006, 5.36836018215, 728.762966531,
    4.01738e-006, 4.60528841541, 309.2783226558,
    3.47378e-006, 4.68148808722, 14.2270940016,
    3.37555e-006, 3.1678195112, 956.2891559706,
    2.60753e-006, 5.34290306101, 846.0828347512,
    2.46603e-006, 3.92313823537, 942.062061969,
    2.20084e-006, 4.84210964963, 1368.660252845,
    2.03217e-006, 5.59995425432, 1155.361157407,
    2.0019e-006, 4.43888814441, 1045.1548361876,
    1.97134e-006, 3.70551461394, 2118.7638603784,
    1.96005e-006, 3.75877587139, 199.0720014364,
    1.83504e-006, 4.26526769703, 95.9792272178,
    1.80134e-006, 4.40165491159, 532.8723588323,
    1.70225e-006, 4.84647488867, 526.5095713569,
    1.46335e-006, 6.12958365535, 533.6231183577,
    1.33483e-006, 1.32245735855, 110.2063212194,
    1.32076e-006, 4.51187950811, 525.7588118315,
];

const JupiterTermR2 = [
    0.00079644833, 1.35865896596, 529.6909650946,
    8.251618e-005, 5.77773935444, 522.5774180938,
    7.029864e-005, 3.27476965833, 536.8045120954,
    5.314006e-005, 1.83835109712, 1059.3819301892,
    1.860833e-005, 2.97682139367, 7.1135470008,
    9.64466e-006, 5.48031822015, 515.463871093,
    8.36267e-006, 4.19889881718, 419.4846438752,
    4.9792e-006, 3.14159265359, 0,
    4.2657e-006, 2.22753101795, 639.897286314,
    4.06453e-006, 3.78250730354, 1066.49547719,
    3.77316e-006, 2.24248352873, 1589.0728952838,
    3.62943e-006, 5.36761847267, 206.1855484372,
    3.42048e-006, 6.09922969324, 1052.2683831884,
    3.39043e-006, 6.12690864038, 625.6701923124,
    3.32578e-006, 0.00328961161, 426.598190876,
    2.7992e-006, 4.26162555827, 412.3710968744,
    2.5729e-006, 0.96295364983, 632.7837393132,
    2.29777e-006, 0.70530766213, 735.8765135318,
    2.00783e-006, 3.06850623368, 543.9180590962,
    1.99807e-006, 4.42884165317, 103.0927742186,
    1.38606e-006, 2.93235671606, 14.2270940016,
    1.13535e-006, 0.78713911289, 728.762966531,
    9.4565e-007, 1.70498041073, 838.9692877504,
    8.6025e-007, 5.14434751994, 323.5054166574,
    8.3469e-007, 0.05834873484, 309.2783226558,
    8.0328e-007, 2.98122361797, 742.9900605326,
    7.5198e-007, 1.60495195911, 956.2891559706,
    7.0451e-007, 1.50988357484, 213.299095438,
    6.6572e-007, 5.47307178077, 199.0720014364,
    6.1649e-007, 6.10137889854, 1045.1548361876,
    5.6203e-007, 0.95534810533, 1162.4747044078,
    5.1904e-007, 5.58435625607, 942.062061969,
    5.0057e-007, 2.72063162317, 532.8723588323,
    4.4548e-007, 5.52445621411, 508.3503240922,
    4.4282e-007, 0.27118152557, 526.5095713569,
    3.9833e-007, 5.94566506227, 95.9792272178,
];

const JupiterTermR3 = [
    3.519257e-005, 6.05800633846, 529.6909650946,
    1.073239e-005, 1.6732134576, 536.8045120954,
    9.15666e-006, 1.41329676116, 522.5774180938,
    3.41593e-006, 0.52296542656, 1059.3819301892,
    2.54893e-006, 1.19625473533, 7.1135470008,
    2.21512e-006, 0.95225226237, 515.463871093,
    8.9729e-007, 3.14159265359, 0,
    6.9078e-007, 2.26885282314, 1066.49547719,
    5.7827e-007, 1.41389745339, 543.9180590962,
    5.7653e-007, 0.52580117593, 639.897286314,
    5.1079e-007, 5.98016364677, 412.3710968744,
    4.6935e-007, 1.57864237959, 625.6701923124,
    4.2824e-007, 6.11689609099, 419.4846438752,
    3.7477e-007, 1.1826276233, 14.2270940016,
    3.3816e-007, 1.66671706951, 1052.2683831884,
    3.3531e-007, 0.84784977903, 206.1855484372,
    3.1195e-007, 1.04290245896, 1589.0728952838,
    3.0023e-007, 4.63236245032, 426.598190876,
    2.0804e-007, 2.50071243814, 728.762966531,
    1.5023e-007, 0.89136998434, 199.0720014364,
    1.4466e-007, 0.96040197071, 508.3503240922,
    1.2969e-007, 1.5023378855, 1045.1548361876,
    1.2319e-007, 2.60952614503, 735.8765135318,
    1.1654e-007, 3.55513510121, 323.5054166574,
    1.116e-007, 1.79041437555, 309.2783226558,
    1.0554e-007, 6.27845112678, 956.2891559706,
    9.812e-008, 6.26016859519, 103.0927742186,
    9.301e-008, 3.45126812476, 838.9692877504,
];

const JupiterTermR4 = [
    1.28628e-006, 0.08419309557, 536.8045120954,
    1.13458e-006, 4.24858855779, 529.6909650946,
    8.265e-007, 3.29754909408, 522.5774180938,
    3.7883e-007, 2.73326611144, 515.463871093,
    2.6694e-007, 5.69142588558, 7.1135470008,
    1.765e-007, 5.40012536918, 1059.3819301892,
    1.2612e-007, 6.01560416057, 543.9180590962,
    9.287e-008, 0.76813946494, 1066.49547719,
    8.107e-008, 5.68228065707, 14.2270940016,
    6.978e-008, 1.42751292055, 412.3710968744,
    6.271e-008, 5.12286932534, 639.897286314,
    5.377e-008, 3.33501947275, 625.6701923124,
    2.911e-008, 3.40334805052, 1052.2683831884,
    2.593e-008, 4.16090412984, 728.762966531,
    2.562e-008, 2.89802035072, 426.598190876,
];

const JupiterTermR5 = [
    1.1188e-007, 4.75249399945, 536.8045120954,
    4.255e-008, 5.9151622917, 522.5774180938,
    2.079e-008, 5.56781555864, 515.463871093,
    1.908e-008, 4.29659647286, 543.9180590962,
    1.875e-008, 3.69357495838, 7.1135470008,
    1.612e-008, 4.13222808529, 1059.3819301892,
    1.59e-008, 5.49312796166, 1066.49547719,
];

const JupiterTerms: pTerms[] = [
    { termArray: JupiterTermL0, termCount: 64 },
    { termArray: JupiterTermL1, termCount: 61 },
    { termArray: JupiterTermL2, termCount: 57 },
    { termArray: JupiterTermL3, termCount: 39 },
    { termArray: JupiterTermL4, termCount: 19 },
    { termArray: JupiterTermL5, termCount: 5 },
    { termArray: JupiterTermB0, termCount: 26 },
    { termArray: JupiterTermB1, termCount: 22 },
    { termArray: JupiterTermB2, termCount: 14 },
    { termArray: JupiterTermB3, termCount: 9 },
    { termArray: JupiterTermB4, termCount: 6 },
    { termArray: JupiterTermB5, termCount: 1 },
    { termArray: JupiterTermR0, termCount: 46 },
    { termArray: JupiterTermR1, termCount: 43 },
    { termArray: JupiterTermR2, termCount: 36 },
    { termArray: JupiterTermR3, termCount: 28 },
    { termArray: JupiterTermR4, termCount: 15 },
    { termArray: JupiterTermR5, termCount: 7 },
];

const SaturnTermL0 = [
    0.87401354029, 0, 0,
    0.1110765978, 3.96205090194, 213.299095438,
    0.01414150958, 4.58581515873, 7.1135470008,
    0.00398379386, 0.52112025957, 206.1855484372,
    0.00350769223, 3.30329903015, 426.598190876,
    0.00206816296, 0.24658366938, 103.0927742186,
    0.00079271288, 3.8400707853, 220.4126424388,
    0.00023990338, 4.6697693486, 110.2063212194,
    0.00016573583, 0.43719123541, 419.4846438752,
    0.000158203, 0.9380895376, 632.7837393132,
    0.00015053509, 2.71670027883, 639.897286314,
    0.00014906995, 5.76903283845, 316.3918696566,
    0.00014609562, 1.56518573691, 3.9321532631,
    0.00013160308, 4.44891180176, 14.2270940016,
    0.00013005305, 5.98119067061, 11.0457002639,
    0.00010725066, 3.12939596466, 202.2533951741,
    6.126308e-005, 1.76328499656, 277.0349937414,
    5.863207e-005, 0.23657028777, 529.6909650946,
    5.227771e-005, 4.2078316238, 3.1813937377,
    5.019658e-005, 3.17787919533, 433.7117378768,
    4.592541e-005, 0.61976424374, 199.0720014364,
    4.005862e-005, 2.24479893937, 63.7358983034,
    3.873696e-005, 3.22282692566, 138.5174968707,
    3.26949e-005, 0.77491895787, 949.1756089698,
    2.953815e-005, 0.98280385206, 95.9792272178,
    2.461172e-005, 2.03163631205, 735.8765135318,
    1.758143e-005, 3.26580514774, 522.5774180938,
    1.640183e-005, 5.50504966218, 846.0828347512,
    1.580641e-005, 4.3726631412, 309.2783226558,
    1.391336e-005, 4.02331978116, 323.5054166574,
    1.123515e-005, 2.83726793572, 415.5524906121,
    1.087237e-005, 4.18343232481, 2.4476805548,
    1.017258e-005, 3.71698151814, 227.5261894396,
    9.56752e-006, 0.50740889886, 1265.5674786264,
    8.52677e-006, 3.42141350697, 175.1660598002,
    8.48643e-006, 3.19149825839, 209.3669421749,
    7.89205e-006, 5.00745123149, 0.9632078465,
    7.48811e-006, 2.14398149298, 853.196381752,
    7.43584e-006, 5.25276954625, 224.3447957019,
    6.86965e-006, 1.74714407827, 1052.2683831884,
    6.5447e-006, 1.59889331515, 0.0481841098,
    6.3398e-006, 2.29889903023, 412.3710968744,
    6.24904e-006, 0.97046831256, 210.1177017003,
    5.79857e-006, 3.09259007048, 74.7815985673,
    5.46358e-006, 2.12678554211, 350.3321196004,
    5.42643e-006, 1.51824320514, 9.5612275556,
    5.29861e-006, 4.44938897119, 117.3198682202,
    4.78054e-006, 2.96488054338, 137.0330241624,
    4.74279e-006, 5.47527185987, 742.9900605326,
    4.51827e-006, 1.04436664241, 490.3340891794,
    4.48542e-006, 1.28990416161, 127.4717966068,
    3.72308e-006, 2.27819108625, 217.2312487011,
    3.54944e-006, 3.0128648303, 838.9692877504,
    3.47413e-006, 1.53928227764, 340.7708920448,
    3.43475e-006, 0.24604039134, 0.5212648618,
    3.30196e-006, 0.24715617844, 1581.959348283,
    3.22185e-006, 0.96137456104, 203.7378678824,
    3.21543e-006, 2.57182354537, 647.0108333148,
    3.09001e-006, 3.49486734909, 216.4804891757,
    2.86688e-006, 2.37043745859, 351.8165923087,
    2.77775e-006, 0.40020408926, 211.8146227297,
    2.49116e-006, 1.47010534421, 1368.660252845,
    2.26609e-006, 4.91003163138, 12.5301729722,
    2.20225e-006, 4.20422424873, 200.7689224658,
    2.08655e-006, 1.34516255304, 625.6701923124,
    2.07663e-006, 0.48349820488, 1162.4747044078,
    2.07659e-006, 1.283022189, 39.3568759152,
    2.045e-006, 6.010822066, 265.9892934775,
    1.8469e-006, 3.50344404958, 149.5631971346,
    1.83511e-006, 0.97254952728, 4.192785694,
    1.82454e-006, 5.49122292426, 2.9207613068,
    1.73914e-006, 1.86305806814, 0.7507595254,
    1.64541e-006, 0.4400551752, 5.4166259714,
    1.49299e-006, 5.73594349789, 52.6901980395,
    1.47526e-006, 1.53529320509, 5.6290742925,
    1.46068e-006, 6.23102544071, 195.1398481733,
    1.39666e-006, 4.29450260069, 21.3406410024,
    1.31283e-006, 4.06828961903, 10.2949407385,
    1.24969e-006, 6.27737805832, 1898.3512179396,
    1.22373e-006, 1.97588777199, 4.665866446,
    1.18156e-006, 5.340729339, 554.0699874828,
    1.17283e-006, 2.67920400584, 1155.361157407,
    1.13747e-006, 5.59427544714, 1059.3819301892,
    1.12437e-006, 1.10502663534, 191.2076949102,
    1.10399e-006, 0.1660402409, 1.4844727083,
    1.09275e-006, 3.43812715686, 536.8045120954,
    1.0657e-006, 4.01156608514, 956.2891559706,
    1.03956e-006, 2.19210363069, 88.865680217,
    1.02702e-006, 1.19748124058, 1685.0521225016,
    1.00631e-006, 4.96513666539, 269.9214467406,
];

const SaturnTermL1 = [
    213.54295595986, 0, 0,
    0.01296855005, 1.82820544701, 213.299095438,
    0.00564347566, 2.88500136429, 7.1135470008,
    0.0010767877, 2.27769911872, 206.1855484372,
    0.0009832303, 1.08070061328, 426.598190876,
    0.00040254586, 2.0412825709, 220.4126424388,
    0.00019941734, 1.27954662736, 103.0927742186,
    0.00010511706, 2.748803928, 14.2270940016,
    6.939233e-005, 0.40493079985, 639.897286314,
    4.803325e-005, 2.44194097666, 419.4846438752,
    4.056325e-005, 2.92166618776, 110.2063212194,
    3.76863e-005, 3.6496563146, 3.9321532631,
    3.384684e-005, 2.41694251653, 3.1813937377,
    3.3022e-005, 1.26256486715, 433.7117378768,
    3.071382e-005, 2.3273931775, 199.0720014364,
    1.953036e-005, 3.563946833, 11.0457002639,
    1.249348e-005, 2.62803737519, 95.9792272178,
    9.21683e-006, 1.9608983425, 227.5261894396,
    7.05587e-006, 4.4168924933, 529.6909650946,
    6.49654e-006, 6.17418093659, 202.2533951741,
    6.27603e-006, 6.11088227167, 309.2783226558,
    4.86843e-006, 6.03998200305, 853.196381752,
    4.78501e-006, 4.98776987984, 522.5774180938,
    4.68377e-006, 4.61707843907, 63.7358983034,
    4.1701e-006, 2.11708169277, 323.5054166574,
    4.0763e-006, 1.29949556676, 209.3669421749,
    3.52489e-006, 2.31707079463, 632.7837393132,
    3.43826e-006, 3.95854178574, 412.3710968744,
    3.39724e-006, 3.63396398752, 316.3918696566,
    3.35936e-006, 3.77173072712, 735.8765135318,
    3.31933e-006, 2.86077699882, 210.1177017003,
    2.89429e-006, 2.73263080235, 117.3198682202,
    2.80911e-006, 5.74398845416, 2.4476805548,
    2.65801e-006, 0.54344631312, 647.0108333148,
    2.30493e-006, 1.64428879621, 216.4804891757,
    1.91667e-006, 2.96512946582, 224.3447957019,
    1.72891e-006, 4.07695221044, 846.0828347512,
    1.67131e-006, 2.59745202658, 21.3406410024,
    1.36328e-006, 2.28580246629, 10.2949407385,
    1.31364e-006, 3.44108355646, 742.9900605326,
    1.27838e-006, 4.09533471247, 217.2312487011,
    1.08862e-006, 6.16141072262, 415.5524906121,
    9.7584e-007, 4.72845436677, 838.9692877504,
    9.3909e-007, 3.48397279899, 1052.2683831884,
    9.2482e-007, 3.94755499926, 88.865680217,
    8.66e-007, 1.21951325061, 440.8252848776,
    8.3463e-007, 3.11269504725, 625.6701923124,
    7.7588e-007, 6.24408938835, 302.164775655,
    6.7106e-007, 0.28961738595, 4.665866446,
    6.5843e-007, 5.64757042732, 9.5612275556,
    6.19e-007, 4.29344363385, 127.4717966068,
    6.1557e-007, 1.82789612597, 195.1398481733,
    5.778e-007, 2.47630552035, 191.9584544356,
    5.6919e-007, 5.01889578112, 137.0330241624,
    5.4585e-007, 0.28356341456, 74.7815985673,
    5.416e-007, 5.12628572382, 490.3340891794,
    5.1425e-007, 1.45766406064, 536.8045120954,
    4.6799e-007, 1.1772121105, 149.5631971346,
    4.6649e-007, 5.14818326902, 515.463871093,
    4.5891e-007, 2.23198878761, 956.2891559706,
    4.4444e-007, 2.70873627665, 5.4166259714,
    4.04e-007, 0.4128152044, 269.9214467406,
    4.038e-007, 3.88870105683, 728.762966531,
    3.7969e-007, 0.6466596718, 422.6660376129,
    3.7768e-007, 2.53379013859, 12.5301729722,
    3.7191e-007, 3.78239026411, 2.9207613068,
    3.5116e-007, 6.08421794089, 5.6290742925,
    3.3778e-007, 3.21070688046, 1368.660252845,
    3.3217e-007, 4.64063092111, 277.0349937414,
    3.305e-007, 5.43038091186, 1066.49547719,
    3.2857e-007, 0.30063884563, 351.8165923087,
    3.1876e-007, 4.3862292377, 1155.361157407,
    3.1329e-007, 2.43455855525, 52.6901980395,
    3.0276e-007, 2.84067004928, 203.0041546995,
    3.0089e-007, 6.18684614308, 284.1485407422,
    2.9667e-007, 3.39052569135, 1059.3819301892,
    2.8913e-007, 2.02614760507, 330.6189636582,
    2.8264e-007, 2.74178953996, 265.9892934775,
    2.6493e-007, 4.51214170121, 340.7708920448,
];

const SaturnTermL2 = [
    0.00116441181, 1.17987850633, 7.1135470008,
    0.00091920844, 0.07425261094, 213.299095438,
    0.00090592251, 0, 0,
    0.00015276909, 4.06492007503, 206.1855484372,
    0.00010631396, 0.25778277414, 220.4126424388,
    0.00010604979, 5.40963595885, 426.598190876,
    4.265368e-005, 1.0459555663, 14.2270940016,
    1.215527e-005, 2.91860042123, 103.0927742186,
    1.164684e-005, 4.60942128971, 639.897286314,
    1.081967e-005, 5.6913035167, 433.7117378768,
    1.044754e-005, 4.04206453611, 199.0720014364,
    1.020079e-005, 0.63369182642, 3.1813937377,
    6.33582e-006, 4.38825410036, 419.4846438752,
    5.49329e-006, 5.57303134242, 3.9321532631,
    4.56914e-006, 1.26840971349, 110.2063212194,
    4.251e-006, 0.20935499279, 227.5261894396,
    2.73739e-006, 4.28841011784, 95.9792272178,
    1.61571e-006, 1.3813914942, 11.0457002639,
    1.29494e-006, 1.5658688417, 309.2783226558,
    1.17008e-006, 3.88120915956, 853.196381752,
    1.05415e-006, 4.90003203599, 647.0108333148,
    1.00967e-006, 0.892704931, 21.3406410024,
    9.5659e-007, 2.91093561539, 316.3918696566,
    9.5227e-007, 5.62561150598, 412.3710968744,
    8.486e-007, 5.73472777961, 209.3669421749,
    8.2727e-007, 6.05030934786, 216.4804891757,
    8.1948e-007, 1.02477558315, 117.3198682202,
    7.4857e-007, 4.76178468163, 210.1177017003,
    6.7184e-007, 0.45648612616, 522.5774180938,
    6.6459e-007, 0.48297940601, 10.2949407385,
    6.3696e-007, 0.35179804917, 323.5054166574,
    6.0647e-007, 4.8751785019, 632.7837393132,
    5.3281e-007, 2.74730541387, 529.6909650946,
    4.5827e-007, 5.69296621745, 440.8252848776,
    4.5293e-007, 1.66856699796, 202.2533951741,
    4.233e-007, 5.70768187703, 88.865680217,
    3.214e-007, 0.07050050346, 63.7358983034,
    3.1573e-007, 1.67190022213, 302.164775655,
    3.115e-007, 4.16379537691, 191.9584544356,
    2.6558e-007, 0.83256214407, 224.3447957019,
    2.4631e-007, 5.6556472857, 735.8765135318,
    2.0108e-007, 5.94364609981, 217.2312487011,
    1.7511e-007, 4.90014736798, 625.6701923124,
    1.713e-007, 1.62593421274, 742.9900605326,
    1.604e-007, 0.57886320845, 515.463871093,
    1.4068e-007, 0.206752937, 838.9692877504,
    1.3744e-007, 3.764971673, 195.1398481733,
    1.2236e-007, 4.71789723976, 203.0041546995,
    1.194e-007, 0.12620714199, 234.6397364404,
    1.1718e-007, 3.12098483554, 846.0828347512,
    1.1154e-007, 5.9221684478, 536.8045120954,
    1.1013e-007, 5.60207982774, 728.762966531,
    1.0601e-007, 3.20327613035, 1066.49547719,
    1.024e-007, 4.9873665607, 422.6660376129,
    1.0072e-007, 0.25709351996, 330.6189636582,
    9.962e-008, 4.15472049127, 860.3099287528,
    9.49e-008, 0.46379969328, 956.2891559706,
    8.287e-008, 2.13990364272, 269.9214467406,
    7.73e-008, 5.24602742309, 429.7795846137,
    7.55e-008, 4.03401153929, 9.5612275556,
    7.238e-008, 5.39724715258, 1052.2683831884,
    6.353e-008, 4.46211130731, 284.1485407422,
    6.082e-008, 5.93416924841, 405.2575498736,
];

const SaturnTermL3 = [
    0.00016038734, 5.73945377424, 7.1135470008,
    4.249793e-005, 4.58539675603, 213.299095438,
    1.906524e-005, 4.76082050205, 220.4126424388,
    1.465687e-005, 5.91326678323, 206.1855484372,
    1.162041e-005, 5.61973132428, 14.2270940016,
    1.066581e-005, 3.60816533142, 426.598190876,
    2.39377e-006, 3.86088273439, 433.7117378768,
    2.36975e-006, 5.76826451465, 199.0720014364,
    1.65641e-006, 5.11641150216, 3.1813937377,
    1.51352e-006, 2.73594641861, 639.897286314,
    1.31409e-006, 4.74327544615, 227.5261894396,
    6.3365e-007, 0.22850089497, 419.4846438752,
    6.163e-007, 4.74287052463, 103.0927742186,
    4.0437e-007, 5.47298059144, 21.3406410024,
    4.0205e-007, 5.9642026672, 95.9792272178,
    3.8746e-007, 5.83386199529, 110.2063212194,
    2.8025e-007, 3.01235311514, 647.0108333148,
    2.5029e-007, 0.9880817074, 3.9321532631,
    1.9014e-007, 1.91614237463, 853.196381752,
    1.8262e-007, 4.96738415934, 10.2949407385,
    1.8101e-007, 1.02506397063, 412.3710968744,
    1.7919e-007, 4.20376505349, 216.4804891757,
    1.7879e-007, 3.31913418974, 309.2783226558,
    1.6208e-007, 3.89825272754, 440.8252848776,
    1.5763e-007, 5.61667809625, 117.3198682202,
    1.2947e-007, 1.18068953942, 88.865680217,
    1.1453e-007, 5.57520615096, 11.0457002639,
    1.0548e-007, 5.92906266269, 191.9584544356,
    1.0389e-007, 3.94838736947, 209.3669421749,
    8.65e-008, 3.39335369698, 302.164775655,
    7.58e-008, 4.87736913157, 323.5054166574,
    6.697e-008, 0.38198725552, 632.7837393132,
    6.327e-008, 2.25492722762, 522.5774180938,
    5.864e-008, 1.05621157685, 210.1177017003,
    5.449e-008, 4.64268475485, 234.6397364404,
    3.701e-008, 3.14159265359, 0,
    3.602e-008, 2.30677010956, 515.463871093,
    3.229e-008, 2.20309400066, 860.3099287528,
    2.85e-008, 0.5860439501, 529.6909650946,
    2.583e-008, 4.93447677059, 224.3447957019,
    2.543e-008, 0.42393884183, 625.6701923124,
    2.421e-008, 4.76621391814, 330.6189636582,
    2.296e-008, 3.34809165905, 429.7795846137,
    2.213e-008, 3.19814958289, 202.2533951741,
    2.194e-008, 1.18918501013, 1066.49547719,
    2.154e-008, 1.35488209144, 405.2575498736,
    2.09e-008, 4.15631351317, 223.5940361765,
    2.018e-008, 3.06693569701, 654.1243803156,
];

const SaturnTermL4 = [
    1.661894e-005, 3.99826248978, 7.1135470008,
    2.57107e-006, 2.98436499013, 220.4126424388,
    2.36344e-006, 3.90241428075, 14.2270940016,
    1.49418e-006, 2.74110824208, 213.299095438,
    1.13953e-006, 3.14159265359, 0,
    1.09598e-006, 1.51515739251, 206.1855484372,
    6.839e-007, 1.72120953337, 426.598190876,
    4.006e-007, 2.04644897412, 433.7117378768,
    3.7699e-007, 1.23795458356, 199.0720014364,
    3.1219e-007, 3.0109418409, 227.5261894396,
    1.5111e-007, 0.82897064529, 639.897286314,
    9.444e-008, 3.71485300868, 21.3406410024,
    5.69e-008, 2.41995290633, 419.4846438752,
    5.608e-008, 1.1560709574, 647.0108333148,
    4.47e-008, 1.45120818748, 95.9792272178,
    4.463e-008, 2.11783225176, 440.8252848776,
    3.229e-008, 4.09278077834, 110.2063212194,
    2.871e-008, 2.77203153866, 412.3710968744,
    2.796e-008, 3.00730249564, 88.865680217,
    2.638e-008, 0.00255721254, 853.196381752,
    2.574e-008, 0.39246854091, 103.0927742186,
    2.225e-008, 3.77689198137, 117.3198682202,
    1.921e-008, 2.82884328662, 234.6397364404,
    1.862e-008, 5.07955457727, 309.2783226558,
    1.805e-008, 2.23816036743, 216.4804891757,
    1.769e-008, 5.19176876406, 302.164775655,
    1.211e-008, 1.54685246534, 191.9584544356,
];

const SaturnTermL5 = [
    1.23615e-006, 2.25923345732, 7.1135470008,
    3.419e-007, 2.16250652689, 14.2270940016,
    2.7546e-007, 1.19868150215, 220.4126424388,
    5.818e-008, 1.21584270184, 227.5261894396,
    5.318e-008, 0.23550400093, 433.7117378768,
    3.677e-008, 6.22669694355, 426.598190876,
    3.057e-008, 2.97372046322, 199.0720014364,
    2.861e-008, 4.28710932685, 206.1855484372,
    1.617e-008, 6.25265362286, 213.299095438,
    1.279e-008, 5.27612561266, 639.897286314,
    1.038e-008, 0.23516951637, 440.8252848776,
    1.007e-008, 3.14159265359, 0,
];

const SaturnTermB0 = [
    0.0433067804, 3.60284428399, 213.299095438,
    0.00240348303, 2.8523848939, 426.598190876,
    0.00084745939, 0, 0,
    0.00034116063, 0.57297307844, 206.1855484372,
    0.00030863357, 3.48441504465, 220.4126424388,
    0.0001473407, 2.1184659787, 639.897286314,
    9.916668e-005, 5.79003189405, 419.4846438752,
    6.993564e-005, 4.73604689179, 7.1135470008,
    4.807587e-005, 5.43305315602, 316.3918696566,
    4.788392e-005, 4.9651292742, 110.2063212194,
    3.432125e-005, 2.73255752123, 433.7117378768,
    1.506129e-005, 6.01304536144, 103.0927742186,
    1.060298e-005, 5.63099292414, 529.6909650946,
    9.69071e-006, 5.20434966103, 632.7837393132,
    9.4205e-006, 1.39646678088, 853.196381752,
    7.07645e-006, 3.80302329547, 323.5054166574,
    5.52313e-006, 5.13149109045, 202.2533951741,
    3.99675e-006, 3.35891413961, 227.5261894396,
    3.1938e-006, 3.6257155098, 209.3669421749,
    3.16063e-006, 1.99716764199, 647.0108333148,
    3.14225e-006, 0.4651027241, 217.2312487011,
    2.84494e-006, 4.88648481625, 224.3447957019,
    2.36442e-006, 2.13887472281, 11.0457002639,
    2.15354e-006, 5.94982610103, 846.0828347512,
    2.08522e-006, 2.12003893769, 415.5524906121,
    2.07213e-006, 0.73021462851, 199.0720014364,
    1.78958e-006, 2.95361514672, 63.7358983034,
    1.40585e-006, 0.64417620299, 490.3340891794,
    1.3924e-006, 4.59535168021, 14.2270940016,
    1.3914e-006, 1.9982199094, 735.8765135318,
    1.34884e-006, 5.24500819605, 742.9900605326,
    1.21669e-006, 3.11537140876, 522.5774180938,
    1.15524e-006, 3.10891547171, 216.4804891757,
    1.14218e-006, 0.96261442133, 210.1177017003,
];

const SaturnTermB1 = [
    0.00397554998, 5.33289992556, 213.299095438,
    0.00049478641, 3.14159265359, 0,
    0.00018571607, 6.09919206378, 426.598190876,
    0.00014800587, 2.3058606052, 206.1855484372,
    9.643981e-005, 1.6967466012, 220.4126424388,
    3.757161e-005, 1.25429514018, 419.4846438752,
    2.716647e-005, 5.91166664787, 639.897286314,
    1.455309e-005, 0.85161616532, 433.7117378768,
    1.290595e-005, 2.9177085709, 7.1135470008,
    8.5263e-006, 0.43572078997, 316.3918696566,
    2.97726e-006, 0.91909206723, 632.7837393132,
    2.92185e-006, 5.3157425127, 853.196381752,
    2.84386e-006, 1.61881754773, 227.5261894396,
    2.7509e-006, 3.88864137336, 103.0927742186,
    1.72359e-006, 0.05215146556, 647.0108333148,
    1.66237e-006, 2.44351613165, 199.0720014364,
    1.5822e-006, 5.20850125766, 110.2063212194,
    1.27731e-006, 1.20711452525, 529.6909650946,
    1.09839e-006, 2.45695551627, 217.2312487011,
    8.1759e-007, 2.75839171353, 210.1177017003,
    8.101e-007, 2.86038377187, 14.2270940016,
    6.8658e-007, 1.65537623146, 202.2533951741,
    6.5161e-007, 1.25527521313, 216.4804891757,
    6.1024e-007, 1.25273412095, 209.3669421749,
    5.9281e-007, 1.82410768234, 323.5054166574,
    4.6386e-007, 0.81534705304, 440.8252848776,
    3.6163e-007, 1.81851057689, 224.3447957019,
    3.4041e-007, 2.83971297997, 117.3198682202,
    3.3114e-007, 1.3055708001, 412.3710968744,
    3.2164e-007, 1.18676132343, 846.0828347512,
    2.7282e-007, 4.64744847591, 1066.49547719,
    2.7128e-007, 4.44228739187, 11.0457002639,
];

const SaturnTermB2 = [
    0.00020629977, 0.50482422817, 213.299095438,
    3.719555e-005, 3.99833475829, 206.1855484372,
    1.627158e-005, 6.181899395, 220.4126424388,
    1.346067e-005, 0, 0,
    7.05842e-006, 3.03914308836, 419.4846438752,
    3.65042e-006, 5.09928680706, 426.598190876,
    3.29632e-006, 5.27899210039, 433.7117378768,
    2.19335e-006, 3.82841533795, 639.897286314,
    1.39393e-006, 1.04272623499, 7.1135470008,
    1.0398e-006, 6.15730992966, 227.5261894396,
    9.2961e-007, 1.97994412845, 316.3918696566,
    7.1242e-007, 4.14754353431, 199.0720014364,
    5.1927e-007, 2.88364833898, 632.7837393132,
    4.8961e-007, 4.43390206741, 647.0108333148,
    4.1373e-007, 3.15927770079, 853.196381752,
    2.8602e-007, 4.52978327558, 210.1177017003,
    2.3969e-007, 1.11595912146, 14.2270940016,
    2.0511e-007, 4.35095844197, 217.2312487011,
    1.9532e-007, 5.30779711223, 440.8252848776,
    1.8263e-007, 0.85391476786, 110.2063212194,
    1.684e-007, 5.68112084135, 216.4804891757,
    1.5742e-007, 4.25767226302, 103.0927742186,
    1.3613e-007, 2.99904334066, 412.3710968744,
    1.1567e-007, 2.5267992841, 529.6909650946,
    7.963e-008, 3.3151242392, 202.2533951741,
    6.648e-008, 5.55714129949, 209.3669421749,
    6.599e-008, 0.28766025146, 323.5054166574,
    6.312e-008, 1.16121321336, 117.3198682202,
    6.192e-008, 3.61231886519, 860.3099287528,
];

const SaturnTermB3 = [
    6.66252e-006, 1.99006340181, 213.299095438,
    6.3235e-006, 5.69778316807, 206.1855484372,
    3.98051e-006, 0, 0,
    1.87838e-006, 4.33779804809, 220.4126424388,
    9.1884e-007, 4.84104208217, 419.4846438752,
    5.1548e-007, 3.42149490328, 433.7117378768,
    4.2369e-007, 2.38073239056, 426.598190876,
    2.5661e-007, 4.40167213109, 227.5261894396,
    2.0551e-007, 5.85313509872, 199.0720014364,
    1.8081e-007, 1.99321433229, 639.897286314,
    1.0874e-007, 5.37344546547, 7.1135470008,
    9.59e-008, 2.54901825866, 647.0108333148,
    7.085e-008, 3.45518372721, 316.3918696566,
    6.002e-008, 4.80055225135, 632.7837393132,
    5.778e-008, 0.01680378777, 210.1177017003,
    5.542e-008, 3.51756747774, 440.8252848776,
    4.881e-008, 5.63719730884, 14.2270940016,
    4.501e-008, 1.2242441901, 853.196381752,
    3.548e-008, 4.7129937089, 412.3710968744,
    2.851e-008, 0.62679207578, 103.0927742186,
    2.173e-008, 3.71982274459, 216.4804891757,
];

const SaturnTermB4 = [
    8.0384e-007, 1.11918414679, 206.1855484372,
    3.166e-007, 3.12218745098, 213.299095438,
    1.7143e-007, 2.48073200414, 220.4126424388,
    1.1844e-007, 3.14159265359, 0,
    9.005e-008, 0.38441424927, 419.4846438752,
    6.164e-008, 1.56186379537, 433.7117378768,
    4.775e-008, 2.63498295487, 227.5261894396,
    4.66e-008, 1.2823563957, 199.0720014364,
    1.487e-008, 1.43096671616, 426.598190876,
    1.424e-008, 0.66988083613, 647.0108333148,
    1.145e-008, 1.72041928134, 440.8252848776,
    1.075e-008, 6.18092274059, 639.897286314,
];

const SaturnTermB5 = [
    7.895e-008, 2.81927558645, 206.1855484372,
    1.014e-008, 0.5118721027, 220.4126424388,
];

const SaturnTermR0 = [
    9.55758135801, 0, 0,
    0.52921382465, 2.39226219733, 213.299095438,
    0.01873679934, 5.23549605091, 206.1855484372,
    0.01464663959, 1.64763045468, 426.598190876,
    0.00821891059, 5.93520025371, 316.3918696566,
    0.00547506899, 5.01532628454, 103.0927742186,
    0.00371684449, 2.27114833428, 220.4126424388,
    0.00361778433, 3.13904303264, 7.1135470008,
    0.00140617548, 5.70406652991, 632.7837393132,
    0.00108974737, 3.29313595577, 110.2063212194,
    0.00069007015, 5.94099622447, 419.4846438752,
    0.0006105335, 0.94037761156, 639.897286314,
    0.00048913044, 1.55733388472, 202.2533951741,
    0.00034143794, 0.19518550682, 277.0349937414,
    0.00032401718, 5.47084606947, 949.1756089698,
    0.00020936573, 0.46349163993, 735.8765135318,
    0.00020839118, 1.5210259064, 433.7117378768,
    0.00020746678, 5.33255667599, 199.0720014364,
    0.00015298457, 3.05943652881, 529.6909650946,
    0.00014296479, 2.60433537909, 323.5054166574,
    0.00012884128, 1.64892310393, 138.5174968707,
    0.00011993314, 5.98051421881, 846.0828347512,
    0.00011380261, 1.73105746566, 522.5774180938,
    9.796061e-005, 5.20475863996, 1265.5674786264,
    7.752769e-005, 5.85191318903, 95.9792272178,
    6.770621e-005, 3.00433479284, 14.2270940016,
    6.465967e-005, 0.17733160145, 1052.2683831884,
    5.850443e-005, 1.45519636076, 415.5524906121,
    5.307481e-005, 0.5973753405, 63.7358983034,
    4.695746e-005, 2.14919036956, 227.5261894396,
    4.043988e-005, 1.64010323863, 209.3669421749,
    3.688132e-005, 0.7801613317, 412.3710968744,
    3.460943e-005, 1.85088802878, 175.1660598002,
    3.419551e-005, 4.94549148887, 1581.959348283,
    3.400616e-005, 0.55386747515, 350.3321196004,
    3.376457e-005, 3.69528478828, 224.3447957019,
    2.976033e-005, 5.68467931117, 210.1177017003,
    2.885348e-005, 1.38764077631, 838.9692877504,
    2.881181e-005, 0.17960757891, 853.196381752,
    2.50763e-005, 3.53851863255, 742.9900605326,
    2.448325e-005, 6.18412386316, 1368.660252845,
    2.406138e-005, 2.96559220267, 117.3198682202,
    2.173959e-005, 0.01508587396, 340.7708920448,
    2.024483e-005, 5.05411271271, 11.0457002639,
];

const SaturnTermR1 = [
    0.06182981282, 0.25843515034, 213.299095438,
    0.00506577574, 0.71114650941, 206.1855484372,
    0.00341394136, 5.7963577396, 426.598190876,
    0.00188491375, 0.47215719444, 220.4126424388,
    0.0018626154, 3.14159265359, 0,
    0.00143891176, 1.40744864239, 7.1135470008,
    0.00049621111, 6.0174446958, 103.0927742186,
    0.00020928189, 5.0924565447, 639.897286314,
    0.00019952612, 1.17560125007, 419.4846438752,
    0.00018839639, 1.60819563173, 110.2063212194,
    0.00013876565, 0.75886204364, 199.0720014364,
    0.00012892827, 5.94330258435, 433.7117378768,
    5.396699e-005, 1.28852405908, 14.2270940016,
    4.869308e-005, 0.86793894213, 323.5054166574,
    4.247455e-005, 0.39299384543, 227.5261894396,
    3.252084e-005, 1.25853470491, 95.9792272178,
    3.081408e-005, 3.43662557418, 522.5774180938,
    2.909411e-005, 4.60679154788, 202.2533951741,
    2.856006e-005, 2.16731405366, 735.8765135318,
    1.987689e-005, 2.45054204795, 412.3710968744,
    1.941309e-005, 6.02393385142, 209.3669421749,
    1.581446e-005, 1.29191789712, 210.1177017003,
    1.339511e-005, 4.30801821806, 853.196381752,
    1.31559e-005, 1.25296446023, 117.3198682202,
    1.203085e-005, 1.86654673794, 316.3918696566,
    1.091088e-005, 0.07527246854, 216.4804891757,
    9.66012e-006, 0.47991379141, 632.7837393132,
    9.54403e-006, 5.15173410519, 647.0108333148,
    8.97512e-006, 0.98343776092, 529.6909650946,
    8.81827e-006, 1.88471724478, 1052.2683831884,
    8.74215e-006, 1.40224683864, 224.3447957019,
    7.84866e-006, 3.06377517461, 838.9692877504,
    7.39892e-006, 1.38225356694, 625.6701923124,
    6.5821e-006, 4.1436293098, 309.2783226558,
    6.496e-006, 1.7248948616, 742.9900605326,
    6.12961e-006, 3.03307306767, 63.7358983034,
    5.99236e-006, 2.54924174765, 217.2312487011,
    5.02886e-006, 2.12958819475, 3.9321532631,
];

const SaturnTermR2 = [
    0.00436902464, 4.78671673044, 213.299095438,
    0.0007192276, 2.50069994874, 206.1855484372,
    0.00049766792, 4.9716815087, 220.4126424388,
    0.00043220894, 3.86940443794, 426.598190876,
    0.00029645554, 5.96310264282, 7.1135470008,
    4.720909e-005, 2.47527992423, 199.0720014364,
    4.14165e-005, 4.10670940823, 433.7117378768,
    3.78937e-005, 3.09771025067, 639.897286314,
    2.96399e-005, 1.37206248846, 103.0927742186,
    2.556363e-005, 2.85065721526, 419.4846438752,
    2.326801e-005, 0, 0,
    2.208457e-005, 6.27588858707, 110.2063212194,
    2.187621e-005, 5.85545832218, 14.2270940016,
    1.956896e-005, 4.92448618045, 227.5261894396,
    9.2384e-006, 5.46392422737, 323.5054166574,
    7.05936e-006, 2.97081280098, 95.9792272178,
    5.46115e-006, 4.12854181522, 412.3710968744,
    4.31485e-006, 5.17825414612, 522.5774180938,
    4.05018e-006, 4.17294157872, 209.3669421749,
    3.90627e-006, 4.48106176893, 216.4804891757,
    3.73838e-006, 5.83435991809, 117.3198682202,
    3.60882e-006, 3.27703082368, 647.0108333148,
    3.5635e-006, 3.19152043942, 210.1177017003,
    3.25598e-006, 2.26867601656, 853.196381752,
    2.06854e-006, 4.02188336738, 735.8765135318,
    2.04494e-006, 0.0877484859, 202.2533951741,
    1.80143e-006, 3.59704903955, 632.7837393132,
    1.78474e-006, 4.09716541453, 440.8252848776,
    1.53656e-006, 3.13470530382, 625.6701923124,
    1.47779e-006, 0.13614300541, 302.164775655,
    1.33076e-006, 2.5935046942, 191.9584544356,
    1.31975e-006, 5.93293968941, 309.2783226558,
];

const SaturnTermR3 = [
    0.00020315005, 3.02186626038, 213.299095438,
    8.923581e-005, 3.19144205755, 220.4126424388,
    6.908677e-005, 4.35174889353, 206.1855484372,
    4.087129e-005, 4.22406927376, 7.1135470008,
    3.879041e-005, 2.01056445995, 426.598190876,
    1.070788e-005, 4.20360341236, 199.0720014364,
    9.07332e-006, 2.28344368029, 433.7117378768,
    6.06121e-006, 3.17458570534, 227.5261894396,
    5.96639e-006, 4.13455753351, 14.2270940016,
    4.83181e-006, 1.17345973258, 639.897286314,
    3.93174e-006, 0, 0,
    2.29472e-006, 4.69838526383, 419.4846438752,
    1.8825e-006, 4.59003889007, 110.2063212194,
    1.49508e-006, 3.201994444, 103.0927742186,
    1.21442e-006, 3.76831374104, 323.5054166574,
    1.02146e-006, 4.70974422803, 95.9792272178,
    1.01215e-006, 5.81884137755, 412.3710968744,
    9.3078e-007, 1.43531270909, 647.0108333148,
    8.4347e-007, 2.63462379693, 216.4804891757,
    7.2601e-007, 4.15395598507, 117.3198682202,
    6.2198e-007, 2.31239345505, 440.8252848776,
    5.4829e-007, 0.30526468471, 853.196381752,
    4.9536e-007, 2.38854232908, 209.3669421749,
    4.5145e-007, 4.37317047297, 191.9584544356,
    4.0671e-007, 0.6884518321, 522.5774180938,
    4.0498e-007, 1.83836569765, 302.164775655,
    3.8089e-007, 5.94455115525, 88.865680217,
    3.2243e-007, 4.01146349387, 21.3406410024,
];

const SaturnTermR4 = [
    1.20205e-005, 1.41499446465, 220.4126424388,
    7.07796e-006, 1.16153570102, 213.299095438,
    5.16121e-006, 6.2397356833, 206.1855484372,
    4.26664e-006, 2.46924890293, 7.1135470008,
    2.67736e-006, 0.18659206741, 426.598190876,
    1.70171e-006, 5.95926972384, 199.0720014364,
    1.50339e-006, 0.4797016714, 433.7117378768,
    1.45113e-006, 1.44211060143, 227.5261894396,
    1.21033e-006, 2.40527320817, 14.2270940016,
    4.7332e-007, 5.56857488676, 639.897286314,
    1.8954e-007, 5.85626429118, 647.0108333148,
    1.6668e-007, 0.52920774279, 440.8252848776,
    1.5745e-007, 2.90112466278, 110.2063212194,
    1.4724e-007, 0.29905316786, 419.4846438752,
    1.4074e-007, 1.30343550656, 412.3710968744,
    1.2708e-007, 2.09349305926, 323.5054166574,
    1.132e-007, 0.21785507019, 95.9792272178,
    1.1133e-007, 2.4630482599, 117.3198682202,
    9.552e-008, 3.14159265359, 0,
    9.246e-008, 1.5649631283, 88.865680217,
    9.233e-008, 2.28127318068, 21.3406410024,
    8.97e-008, 0.68301278041, 216.4804891757,
    8.36e-008, 1.27239488455, 234.6397364404,
];

const SaturnTermR5 = [
    1.28612e-006, 5.91282565136, 220.4126424388,
    3.2273e-007, 0.69256228602, 7.1135470008,
    2.6698e-007, 5.91428528629, 227.5261894396,
    2.0223e-007, 4.95136801768, 433.7117378768,
    1.9923e-007, 0.67370653385, 14.2270940016,
    1.4097e-007, 2.67074280191, 206.1855484372,
    1.3537e-007, 1.45669521408, 199.0720014364,
    1.3364e-007, 4.5882699637, 426.598190876,
    7.257e-008, 4.62966127155, 213.299095438,
    4.876e-008, 3.61448275002, 639.897286314,
    3.759e-008, 4.89624165044, 440.8252848776,
    3.303e-008, 4.07190859545, 647.0108333148,
    3.136e-008, 4.65661021909, 191.9584544356,
    2.917e-008, 0.48665273315, 323.5054166574,
    2.883e-008, 3.18003019204, 419.4846438752,
    2.338e-008, 3.69553554327, 88.865680217,
    2.052e-008, 3.31663577368, 95.9792272178,
    2.028e-008, 0.56025552769, 117.3198682202,
];

const SaturnTerms: pTerms[] = [
    { termArray: SaturnTermL0, termCount: 90 },
    { termArray: SaturnTermL1, termCount: 79 },
    { termArray: SaturnTermL2, termCount: 63 },
    { termArray: SaturnTermL3, termCount: 48 },
    { termArray: SaturnTermL4, termCount: 27 },
    { termArray: SaturnTermL5, termCount: 12 },
    { termArray: SaturnTermB0, termCount: 34 },
    { termArray: SaturnTermB1, termCount: 32 },
    { termArray: SaturnTermB2, termCount: 29 },
    { termArray: SaturnTermB3, termCount: 21 },
    { termArray: SaturnTermB4, termCount: 12 },
    { termArray: SaturnTermB5, termCount: 2 },
    { termArray: SaturnTermR0, termCount: 44 },
    { termArray: SaturnTermR1, termCount: 38 },
    { termArray: SaturnTermR2, termCount: 32 },
    { termArray: SaturnTermR3, termCount: 28 },
    { termArray: SaturnTermR4, termCount: 23 },
    { termArray: SaturnTermR5, termCount: 18 },
];

const UranusTermL0 = [
    5.48129294299, 0, 0,
    0.09260408252, 0.8910642153, 74.7815985673,
    0.01504247826, 3.62719262195, 1.4844727083,
    0.00365981718, 1.89962189068, 73.297125859,
    0.00272328132, 3.35823710524, 149.5631971346,
    0.00070328499, 5.39254431993, 63.7358983034,
    0.00068892609, 6.09292489045, 76.2660712756,
    0.00061998592, 2.26952040469, 2.9689454166,
    0.00061950714, 2.85098907565, 11.0457002639,
    0.00026468869, 3.14152087888, 71.8126531507,
    0.00025710505, 6.11379842935, 454.9093665273,
    0.00021078897, 4.36059465144, 148.0787244263,
    0.00017818665, 1.74436982544, 36.6485629295,
    0.00014613471, 4.73732047977, 3.9321532631,
    0.00011162535, 5.82681993692, 224.3447957019,
    0.00010997934, 0.48865493179, 138.5174968707,
    9.527487e-005, 2.95516893093, 35.1640902212,
    7.545543e-005, 5.23626440666, 109.9456887885,
    4.22017e-005, 3.23328535514, 70.8494453042,
    4.05185e-005, 2.27754158724, 151.0476698429,
    3.490352e-005, 5.48305567292, 146.594251718,
    3.354607e-005, 1.06549008887, 4.4534181249,
    3.144093e-005, 4.75199307603, 77.7505439839,
    2.926671e-005, 4.62903695486, 9.5612275556,
    2.92241e-005, 5.3523674338, 85.8272988312,
    2.27279e-005, 4.36600802756, 70.3281804424,
    2.148599e-005, 0.60745800902, 38.1330356378,
    2.051209e-005, 1.51773563459, 0.1118745846,
    1.991726e-005, 4.92437290826, 277.0349937414,
    1.66691e-005, 3.62744580852, 380.12776796,
    1.533223e-005, 2.58593414266, 52.6901980395,
    1.376208e-005, 2.04281409054, 65.2203710117,
    1.3721e-005, 4.19641615561, 111.4301614968,
    1.284183e-005, 3.11346336879, 202.2533951741,
    1.281641e-005, 0.54269869505, 222.8603229936,
    1.244342e-005, 0.91612680579, 2.4476805548,
    1.220998e-005, 0.19901396193, 108.4612160802,
    1.150993e-005, 4.17898207045, 33.6796175129,
    1.150416e-005, 0.93344454002, 3.1813937377,
    1.090461e-005, 1.77501638912, 12.5301729722,
    1.072008e-005, 0.23564502877, 62.2514255951,
    9.46195e-006, 1.19249463066, 127.4717966068,
    7.07875e-006, 5.18285226584, 213.299095438,
    6.53401e-006, 0.96586909116, 78.7137518304,
    6.27562e-006, 0.18210181975, 984.6003316219,
    6.06827e-006, 5.43209728952, 529.6909650946,
    5.5937e-006, 3.35776737704, 0.5212648618,
    5.24495e-006, 2.01276706996, 299.1263942692,
    4.83219e-006, 2.10553990154, 0.9632078465,
    4.71288e-006, 1.40664336447, 184.7272873558,
    4.67211e-006, 0.41484068933, 145.1097790097,
    4.33532e-006, 5.52142978255, 183.2428146475,
    4.04891e-006, 5.98689011389, 8.0767548473,
    3.98996e-006, 0.33810765436, 415.5524906121,
    3.95614e-006, 5.87039580949, 351.8165923087,
    3.78609e-006, 2.34975805006, 56.6223513026,
    3.09885e-006, 5.83301304674, 145.6310438715,
    3.00379e-006, 5.64353974146, 22.0914005278,
    2.94172e-006, 5.83916826225, 39.6175083461,
    2.51792e-006, 1.63696775578, 221.3758502853,
    2.49229e-006, 4.74617120584, 225.8292684102,
    2.39334e-006, 2.35045874708, 137.0330241624,
    2.24097e-006, 0.51574863468, 84.3428261229,
    2.22588e-006, 2.84309380331, 0.2606324309,
    2.19621e-006, 1.92212987979, 67.6680515665,
    2.16549e-006, 6.14211862702, 5.9378908332,
    2.1648e-006, 4.77847481363, 340.7708920448,
    2.07828e-006, 5.5802057004, 68.8437077341,
    2.01963e-006, 1.29693040865, 0.0481841098,
    1.99146e-006, 0.9563415501, 152.5321425512,
    1.93652e-006, 1.88800122606, 456.3938392356,
    1.92998e-006, 0.91616058506, 453.424893819,
    1.87474e-006, 1.31924326253, 0.1600586944,
    1.81934e-006, 3.53624029238, 79.2350166922,
    1.73145e-006, 1.53860728054, 160.6088973985,
    1.71968e-006, 5.67952685533, 219.891377577,
    1.703e-006, 3.67717520688, 5.4166259714,
    1.68648e-006, 5.87874000882, 18.1592472647,
    1.64588e-006, 1.42379714838, 106.9767433719,
    1.62792e-006, 3.05029377666, 112.9146342051,
    1.58028e-006, 0.73811997211, 54.1746707478,
    1.46653e-006, 1.26300172265, 59.8037450403,
    1.43058e-006, 1.29995487555, 35.4247226521,
    1.39453e-006, 5.385977234, 32.1951448046,
    1.38585e-006, 4.25994786673, 909.8187330546,
    1.2384e-006, 1.37359990336, 7.1135470008,
    1.10163e-006, 2.02685778976, 554.0699874828,
    1.09376e-006, 5.70581833286, 77.962992305,
    1.04414e-006, 5.02820888813, 0.7507595254,
    1.03562e-006, 1.45770270246, 24.3790223882,
    1.03277e-006, 0.68095301267, 14.977853527,
];

const UranusTermL1 = [
    75.02543121646, 0, 0,
    0.00154458244, 5.24201658072, 74.7815985673,
    0.00024456413, 1.71255705309, 1.4844727083,
    9.257828e-005, 0.42844639064, 11.0457002639,
    8.265977e-005, 1.5022003511, 63.7358983034,
    7.841715e-005, 1.31983607251, 149.5631971346,
    3.899105e-005, 0.46483574024, 3.9321532631,
    2.283777e-005, 4.17367533997, 76.2660712756,
    1.9266e-005, 0.53013080152, 2.9689454166,
    1.232727e-005, 1.58634458237, 70.8494453042,
    7.91206e-006, 5.43641224143, 3.1813937377,
    7.66954e-006, 1.99555409575, 73.297125859,
    4.81671e-006, 2.98401996914, 85.8272988312,
    4.49798e-006, 4.13826237508, 138.5174968707,
    4.456e-006, 3.72300400331, 224.3447957019,
    4.26554e-006, 4.73126059388, 71.8126531507,
    3.53752e-006, 2.58324496886, 148.0787244263,
    3.47735e-006, 2.45372261286, 9.5612275556,
    3.17084e-006, 5.57855232072, 52.6901980395,
    2.05585e-006, 2.36263144251, 2.4476805548,
    1.89068e-006, 4.20242881378, 56.6223513026,
    1.83762e-006, 0.28371004654, 151.0476698429,
    1.7992e-006, 5.68367730922, 12.5301729722,
    1.71084e-006, 3.00060075287, 78.7137518304,
    1.58029e-006, 2.90931969498, 0.9632078465,
    1.5467e-006, 5.59083925605, 4.4534181249,
    1.53515e-006, 4.65186885939, 35.1640902212,
    1.51984e-006, 2.9421732689, 77.7505439839,
    1.43464e-006, 2.59049246726, 62.2514255951,
    1.21452e-006, 4.1483920492, 127.4717966068,
    1.15546e-006, 3.73224603791, 65.2203710117,
    1.02022e-006, 4.18754517993, 145.6310438715,
    1.01718e-006, 6.03385875009, 0.1118745846,
    8.8202e-007, 3.99035787994, 18.1592472647,
    8.7549e-007, 6.15520787584, 202.2533951741,
    8.053e-007, 2.64124743934, 22.0914005278,
    7.2047e-007, 6.04545933578, 70.3281804424,
    6.857e-007, 4.05071895264, 77.962992305,
    5.9173e-007, 3.70413919082, 67.6680515665,
    4.7267e-007, 3.54312460519, 351.8165923087,
    4.4339e-007, 5.90865821911, 7.1135470008,
    4.2534e-007, 5.72357370899, 5.4166259714,
    3.8544e-007, 4.91519003848, 222.8603229936,
    3.6116e-007, 5.89964278801, 33.6796175129,
    3.5605e-007, 3.29197259183, 8.0767548473,
    3.5524e-007, 3.32784616138, 71.6002048296,
    3.4996e-007, 5.08034112149, 38.1330356378,
    3.1454e-007, 5.62015632303, 984.6003316219,
    3.0811e-007, 5.49591403863, 59.8037450403,
    3.0608e-007, 5.46414592601, 160.6088973985,
    2.9866e-007, 1.65980844667, 447.7958195265,
    2.9206e-007, 1.14722640419, 462.0229135281,
    2.8947e-007, 4.51867390414, 84.3428261229,
    2.6627e-007, 5.54127301037, 131.4039498699,
    2.6605e-007, 6.14640604128, 299.1263942692,
    2.5753e-007, 4.99362028417, 137.0330241624,
    2.5373e-007, 5.73584678604, 380.12776796,
];

const UranusTermL2 = [
    0.00053033277, 0, 0,
    2.357636e-005, 2.26014661705, 74.7815985673,
    7.69129e-006, 4.52561041823, 11.0457002639,
    5.51533e-006, 3.25814281023, 63.7358983034,
    5.41532e-006, 2.27573907424, 3.9321532631,
    5.29473e-006, 4.92348433826, 1.4844727083,
    2.57521e-006, 3.69059216858, 3.1813937377,
    2.38835e-006, 5.85806638405, 149.5631971346,
    1.81904e-006, 6.21763603405, 70.8494453042,
    5.3504e-007, 1.44225240953, 76.2660712756,
    4.9401e-007, 6.03101301723, 56.6223513026,
    4.4753e-007, 3.90904910523, 2.4476805548,
    4.453e-007, 0.81152639478, 85.8272988312,
    3.8222e-007, 1.78467827781, 52.6901980395,
    3.7403e-007, 4.46228598032, 2.9689454166,
    3.3029e-007, 0.86388149962, 9.5612275556,
    2.9423e-007, 5.09818697708, 73.297125859,
    2.4292e-007, 2.10702559049, 18.1592472647,
    2.2491e-007, 5.99320728691, 138.5174968707,
    2.2135e-007, 4.81730808582, 78.7137518304,
    2.1392e-007, 2.39880709309, 77.962992305,
    2.0578e-007, 2.16918786539, 224.3447957019,
    1.7226e-007, 2.53537183199, 145.6310438715,
    1.6777e-007, 3.46631344086, 12.5301729722,
    1.2012e-007, 0.01941361902, 22.0914005278,
    1.101e-007, 0.0849627437, 127.4717966068,
    1.0476e-007, 5.16453084068, 71.6002048296,
    1.0466e-007, 4.45556032593, 62.2514255951,
    8.668e-008, 4.25550086984, 7.1135470008,
    8.387e-008, 5.50115930045, 67.6680515665,
    7.16e-008, 1.24903906391, 5.4166259714,
    6.109e-008, 3.36320161279, 447.7958195265,
    6.087e-008, 5.44611674384, 65.2203710117,
    6.013e-008, 4.51836836347, 151.0476698429,
    6.003e-008, 5.72500086735, 462.0229135281,
];

const UranusTermL3 = [
    1.20936e-006, 0.02418789918, 74.7815985673,
    6.8064e-007, 4.12084267733, 3.9321532631,
    5.2828e-007, 2.3896406126, 11.0457002639,
    4.5806e-007, 0, 0,
    4.53e-007, 2.0442379841, 3.1813937377,
    4.3754e-007, 2.95965039734, 1.4844727083,
    2.4969e-007, 4.88741307918, 63.7358983034,
    2.1061e-007, 4.54511486862, 70.8494453042,
    1.9897e-007, 2.31320314136, 149.5631971346,
    8.901e-008, 1.57548871761, 56.6223513026,
    4.271e-008, 0.22777319552, 18.1592472647,
    3.613e-008, 5.39244611308, 76.2660712756,
    3.572e-008, 0.95052448578, 77.962992305,
    3.488e-008, 4.97622811775, 85.8272988312,
    3.479e-008, 4.12969359977, 52.6901980395,
    2.696e-008, 0.37287796344, 78.7137518304,
    2.328e-008, 0.85770961794, 145.6310438715,
    2.156e-008, 5.65647821519, 9.5612275556,
];

const UranusTermL4 = [
    1.13855e-006, 3.14159265359, 0,
    5.599e-008, 4.57882424417, 74.7815985673,
    3.203e-008, 0.34623003207, 11.0457002639,
    1.217e-008, 3.42199121826, 56.6223513026,
];

const UranusTermB0 = [
    0.01346277639, 2.61877810545, 74.7815985673,
    0.00062341405, 5.08111175856, 149.5631971346,
    0.00061601203, 3.14159265359, 0,
    9.963744e-005, 1.61603876357, 76.2660712756,
    9.926151e-005, 0.57630387917, 73.297125859,
    3.259455e-005, 1.2611938596, 224.3447957019,
    2.972318e-005, 2.24367035538, 1.4844727083,
    2.010257e-005, 6.05550401088, 148.0787244263,
    1.522172e-005, 0.27960386377, 63.7358983034,
    9.24055e-006, 4.03822927853, 151.0476698429,
    7.60624e-006, 6.14000431923, 71.8126531507,
    5.22309e-006, 3.3208519477, 138.5174968707,
    4.6263e-006, 0.74256727574, 85.8272988312,
    4.36843e-006, 3.38082524317, 529.6909650946,
    4.34625e-006, 0.34065281858, 77.7505439839,
    4.30668e-006, 3.55445034854, 213.299095438,
    4.20265e-006, 5.21279984788, 11.0457002639,
    2.44698e-006, 0.78795150326, 2.9689454166,
    2.32649e-006, 2.25716421383, 222.8603229936,
    2.15838e-006, 1.5912170494, 38.1330356378,
    1.79935e-006, 3.72487952673, 299.1263942692,
    1.74895e-006, 1.23550262213, 146.594251718,
    1.73667e-006, 1.93654269131, 380.12776796,
    1.60368e-006, 5.33635436463, 111.4301614968,
    1.44064e-006, 5.96239326415, 35.1640902212,
    1.16363e-006, 5.73877190007, 70.8494453042,
    1.06441e-006, 0.94103112994, 70.3281804424,
    1.02049e-006, 2.61876256513, 78.7137518304,
];

const UranusTermB1 = [
    0.00206366162, 4.12394311407, 74.7815985673,
    8.56323e-005, 0.33819986165, 149.5631971346,
    1.725703e-005, 2.12193159895, 73.297125859,
    1.374449e-005, 0, 0,
    1.36886e-005, 3.06861722047, 76.2660712756,
    4.50639e-006, 3.77656180977, 1.4844727083,
    3.99847e-006, 2.84767037795, 224.3447957019,
    3.07214e-006, 1.25456766737, 148.0787244263,
    1.54336e-006, 3.78575467747, 63.7358983034,
    1.12432e-006, 5.57299891505, 151.0476698429,
    1.10888e-006, 5.32888676461, 138.5174968707,
    8.3493e-007, 3.59152795558, 71.8126531507,
    5.5573e-007, 3.40135416354, 85.8272988312,
    5.369e-007, 1.70455769943, 77.7505439839,
    4.1912e-007, 1.21476607434, 11.0457002639,
    4.1377e-007, 4.45476669141, 78.7137518304,
    3.1959e-007, 3.77446207748, 222.8603229936,
    3.0297e-007, 2.56371683644, 2.9689454166,
    2.6977e-007, 5.33695500294, 213.299095438,
    2.6222e-007, 0.41620628369, 380.12776796,
];

const UranusTermB2 = [
    9.211656e-005, 5.80044305785, 74.7815985673,
    5.56926e-006, 0, 0,
    2.86265e-006, 2.17729776353, 149.5631971346,
    9.4969e-007, 3.84237569809, 73.297125859,
    4.5419e-007, 4.87822046064, 76.2660712756,
    2.0107e-007, 5.46264485369, 1.4844727083,
    1.4793e-007, 0.87983715652, 138.5174968707,
    1.4261e-007, 2.84517742687, 148.0787244263,
    1.3963e-007, 5.07234043994, 63.7358983034,
    1.0122e-007, 5.00290894862, 224.3447957019,
    8.299e-008, 6.26655615197, 78.7137518304,
];

const UranusTermB3 = [
    2.67832e-006, 1.25097888291, 74.7815985673,
    1.1048e-007, 3.14159265359, 0,
    6.154e-008, 4.00663614486, 149.5631971346,
    3.361e-008, 5.77804694935, 73.297125859,
];

const UranusTermB4 = [
    5.719e-008, 2.85499529315, 74.7815985673,
];

const UranusTermR0 = [
    19.21264847881, 0, 0,
    0.88784984055, 5.60377526994, 74.7815985673,
    0.03440835545, 0.32836098991, 73.297125859,
    0.02055653495, 1.78295170028, 149.5631971346,
    0.00649321851, 4.52247298119, 76.2660712756,
    0.00602248144, 3.86003820462, 63.7358983034,
    0.00496404171, 1.40139934716, 454.9093665273,
    0.00338525522, 1.58002682946, 138.5174968707,
    0.00243508222, 1.57086595074, 71.8126531507,
    0.00190521915, 1.99809364502, 1.4844727083,
    0.00161858251, 2.79137863469, 148.0787244263,
    0.00143705902, 1.38368574483, 11.0457002639,
    0.00093192359, 0.17437193645, 36.6485629295,
    0.00089805842, 3.66105366329, 109.9456887885,
    0.00071424265, 4.24509327405, 224.3447957019,
    0.00046677322, 1.39976563936, 35.1640902212,
    0.00039025681, 3.36234710692, 277.0349937414,
    0.00039009624, 1.66971128869, 70.8494453042,
    0.0003675516, 3.88648934736, 146.594251718,
    0.00030348875, 0.70100446346, 151.0476698429,
    0.00029156264, 3.18056174556, 77.7505439839,
    0.00025785805, 3.78537741503, 85.8272988312,
    0.0002562036, 5.25656292802, 380.12776796,
    0.00022637152, 0.72519137745, 529.6909650946,
    0.00020473163, 2.79639811626, 70.3281804424,
    0.00020471584, 1.555889615, 202.2533951741,
    0.00017900561, 0.55455488605, 2.9689454166,
    0.00015502809, 5.35405037603, 38.1330356378,
    0.00014701566, 4.90434406648, 108.4612160802,
    0.00012896507, 2.62154018241, 111.4301614968,
    0.00012328151, 5.96039150918, 127.4717966068,
    0.00011959355, 1.75044072173, 984.6003316219,
    0.00011852996, 0.99342814582, 52.6901980395,
    0.00011696085, 3.29825599114, 3.9321532631,
    0.00011494701, 0.43774027872, 65.2203710117,
    0.00010792699, 1.42104858472, 213.299095438,
    9.111446e-005, 4.99638600045, 62.2514255951,
    8.42055e-005, 5.25350716616, 222.8603229936,
    8.402147e-005, 5.03877516489, 415.5524906121,
    7.449125e-005, 0.79491905956, 351.8165923087,
    7.329454e-005, 3.9727752784, 183.2428146475,
    6.04637e-005, 5.67960948357, 78.7137518304,
    5.524133e-005, 3.11499484161, 9.5612275556,
    5.444878e-005, 5.10575635361, 145.1097790097,
    5.238103e-005, 2.62960141797, 33.6796175129,
    4.079167e-005, 3.22064788674, 340.7708920448,
    3.919476e-005, 4.25015288873, 39.6175083461,
    3.801606e-005, 6.10985558505, 184.7272873558,
    3.781219e-005, 3.45840272873, 456.3938392356,
    3.686787e-005, 2.48718116535, 453.424893819,
    3.101743e-005, 4.14031063896, 219.891377577,
    2.962641e-005, 0.82977991995, 56.6223513026,
    2.942239e-005, 0.42393808854, 299.1263942692,
    2.940492e-005, 2.14637460319, 137.0330241624,
    2.937799e-005, 3.6765745093, 140.001969579,
    2.865128e-005, 0.30996903761, 12.5301729722,
    2.538032e-005, 4.85457831993, 131.4039498699,
    2.36355e-005, 0.44253328372, 554.0699874828,
    2.182572e-005, 2.94040431638, 305.3461693927,
];

const UranusTermR1 = [
    0.0147989637, 3.67205705317, 74.7815985673,
    0.00071212085, 6.22601006675, 63.7358983034,
    0.00068626972, 6.13411265052, 149.5631971346,
    0.00024059649, 3.14159265359, 0,
    0.00021468152, 2.6017670427, 76.2660712756,
    0.00020857262, 5.24625494219, 11.0457002639,
    0.00011405346, 0.01848461561, 70.8494453042,
    7.496775e-005, 0.42360033283, 73.297125859,
    4.2438e-005, 1.41692350371, 85.8272988312,
    3.926694e-005, 3.15513991323, 71.8126531507,
    3.578446e-005, 2.31160668309, 224.3447957019,
    3.505936e-005, 2.58354048851, 138.5174968707,
    3.228835e-005, 5.25499602896, 3.9321532631,
    3.06001e-005, 0.15321893225, 1.4844727083,
    2.564251e-005, 0.98076846352, 148.0787244263,
    2.429445e-005, 3.99440122468, 52.6901980395,
    1.644719e-005, 2.65349313124, 127.4717966068,
    1.583766e-005, 1.43045619196, 78.7137518304,
    1.508028e-005, 5.05996325425, 151.0476698429,
    1.489525e-005, 2.67559167316, 56.6223513026,
    1.413112e-005, 4.57461892062, 202.2533951741,
    1.403237e-005, 1.36985349744, 77.7505439839,
    1.22822e-005, 1.04703640149, 62.2514255951,
    1.032731e-005, 0.26459059027, 131.4039498699,
    9.92085e-006, 2.17168865909, 65.2203710117,
    8.61867e-006, 5.05530802218, 351.8165923087,
    7.44445e-006, 3.07640148939, 35.1640902212,
    6.8747e-006, 2.49912565674, 77.962992305,
    6.46851e-006, 4.4729042291, 70.3281804424,
    6.23602e-006, 0.8625307382, 9.5612275556,
    6.04362e-006, 0.90717667985, 984.6003316219,
    5.7471e-006, 3.23070708457, 447.7958195265,
    5.61839e-006, 2.7177815898, 462.0229135281,
    5.30364e-006, 5.91655309045, 213.299095438,
    5.27794e-006, 5.15136007084, 2.9689454166,
];

const UranusTermR2 = [
    0.00022439904, 0.6995311876, 74.7815985673,
    4.727037e-005, 1.69901641488, 63.7358983034,
    1.681903e-005, 4.64833551727, 70.8494453042,
    1.649559e-005, 3.0966007898, 11.0457002639,
    1.433755e-005, 3.52119917947, 149.5631971346,
    7.70188e-006, 0, 0,
    5.00429e-006, 6.17229032223, 76.2660712756,
    4.61009e-006, 0.76676632849, 3.9321532631,
    3.90371e-006, 4.49605283502, 56.6223513026,
    3.89945e-006, 5.52673426377, 85.8272988312,
    2.92097e-006, 0.20389012095, 52.6901980395,
    2.86579e-006, 3.5335768327, 73.297125859,
    2.72898e-006, 3.84707823651, 138.5174968707,
    2.19674e-006, 1.96418942891, 131.4039498699,
    2.15788e-006, 0.84812474187, 77.962992305,
    2.05449e-006, 3.24758017121, 78.7137518304,
    1.48554e-006, 4.89840863841, 127.4717966068,
    1.28834e-006, 2.08146849515, 3.1813937377,
];

const UranusTermR3 = [
    1.164382e-005, 4.73453291602, 74.7815985673,
    2.12367e-006, 3.34255734999, 63.7358983034,
    1.96408e-006, 2.98004616318, 70.8494453042,
    1.04527e-006, 0.95807937648, 11.0457002639,
    7.254e-007, 0.99701907912, 149.5631971346,
    7.1681e-007, 0.02528455665, 56.6223513026,
    5.4875e-007, 2.59436811267, 3.9321532631,
    3.6377e-007, 5.65035573017, 77.962992305,
    3.4029e-007, 3.81553325635, 76.2660712756,
    3.2081e-007, 3.5982517784, 131.4039498699,
];

const UranusTermR4 = [
    5.2996e-007, 3.00838033088, 74.7815985673,
    9.887e-008, 1.91399083603, 56.6223513026,
];

const UranusTerms: pTerms[] = [
    { termArray: UranusTermL0, termCount: 91 },
    { termArray: UranusTermL1, termCount: 57 },
    { termArray: UranusTermL2, termCount: 35 },
    { termArray: UranusTermL3, termCount: 18 },
    { termArray: UranusTermL4, termCount: 4 },
    { termArray: [], termCount: 0 },
    { termArray: UranusTermB0, termCount: 28 },
    { termArray: UranusTermB1, termCount: 20 },
    { termArray: UranusTermB2, termCount: 11 },
    { termArray: UranusTermB3, termCount: 4 },
    { termArray: UranusTermB4, termCount: 1 },
    { termArray: [], termCount: 0 },
    { termArray: UranusTermR0, termCount: 59 },
    { termArray: UranusTermR1, termCount: 35 },
    { termArray: UranusTermR2, termCount: 18 },
    { termArray: UranusTermR3, termCount: 10 },
    { termArray: UranusTermR4, termCount: 2 },
    { termArray: [], termCount: 0 },
];

const NeptuneTermL0 = [
    5.31188633047, 0, 0,
    0.01798475509, 2.9010127305, 38.1330356378,
    0.01019727662, 0.4858092366, 1.4844727083,
    0.00124531845, 4.83008090682, 36.6485629295,
    0.0004206445, 5.41054991607, 2.9689454166,
    0.00037714589, 6.09221834946, 35.1640902212,
    0.00033784734, 1.24488865578, 76.2660712756,
    0.00016482741, 7.729261e-005, 491.5579294568,
    9.198582e-005, 4.93747059924, 39.6175083461,
    8.994249e-005, 0.27462142569, 175.1660598002,
    4.216235e-005, 1.98711914364, 73.297125859,
    3.364818e-005, 1.03590121818, 33.6796175129,
    2.2848e-005, 4.20606932559, 4.4534181249,
    1.433512e-005, 2.78340432711, 74.7815985673,
    9.0024e-006, 2.07606702418, 109.9456887885,
    7.44996e-006, 3.19032530145, 71.8126531507,
    5.06206e-006, 5.74785370252, 114.3991069134,
    3.99552e-006, 0.34972342569, 1021.2488945514,
    3.45195e-006, 3.46186210169, 41.1019810544,
    3.40323e-006, 3.30369900416, 77.7505439839,
    3.23004e-006, 2.24815188609, 32.1951448046,
    3.06338e-006, 0.49684039897, 0.5212648618,
    2.87322e-006, 4.50523446022, 0.0481841098,
    2.8217e-006, 2.24565579693, 146.594251718,
    2.66605e-006, 4.88932609483, 0.9632078465,
    2.51941e-006, 5.78166597292, 388.4651552382,
    2.44722e-006, 1.24693337933, 9.5612275556,
    2.32887e-006, 2.50459795017, 137.0330241624,
    2.27079e-006, 1.79713054538, 453.424893819,
    1.70404e-006, 3.3239063065, 108.4612160802,
    1.51401e-006, 2.1915309428, 33.9402499438,
    1.5018e-006, 2.99706110414, 5.9378908332,
    1.48295e-006, 0.85948986145, 111.4301614968,
    1.18672e-006, 3.67706204305, 2.4476805548,
    1.093e-006, 2.41599378049, 183.2428146475,
    1.03305e-006, 0.04078966679, 0.2606324309,
    1.03054e-006, 4.40441222, 70.3281804424,
    1.01821e-006, 5.70539236951, 0.1118745846,
];

const NeptuneTermL1 = [
    38.37687716731, 0, 0,
    0.00016604187, 4.86319129565, 1.4844727083,
    0.00015807148, 2.27923488532, 38.1330356378,
    3.334701e-005, 3.6819967602, 76.2660712756,
    1.30584e-005, 3.67320813491, 2.9689454166,
    6.04832e-006, 1.50477747549, 35.1640902212,
    1.78623e-006, 3.45318524147, 39.6175083461,
    1.06537e-006, 2.45126138334, 4.4534181249,
    1.05747e-006, 2.7547932655, 33.6796175129,
    7.2684e-007, 5.48724732699, 36.6485629295,
    5.7355e-007, 1.85767603384, 114.3991069134,
    5.7069e-007, 5.2164980497, 0.5212648618,
    3.5368e-007, 4.51676827545, 74.7815985673,
    3.2216e-007, 5.9041148968, 77.7505439839,
    2.9871e-007, 3.67043294114, 388.4651552382,
    2.8866e-007, 5.16877529164, 9.5612275556,
    2.8742e-007, 5.16732589024, 2.4476805548,
    2.5507e-007, 5.24526281928, 168.0525127994,
];

const NeptuneTermL2 = [
    0.00053892649, 0, 0,
    2.95693e-006, 1.85520292248, 1.4844727083,
    2.81251e-006, 1.19084538887, 38.1330356378,
    2.7019e-006, 5.72143228148, 76.2660712756,
    2.3023e-007, 1.21035596452, 2.9689454166,
    9.057e-008, 4.42544992035, 35.1640902212,
    7.333e-008, 0.5403330683, 2.4476805548,
];

const NeptuneTermL3 = [
    3.1254e-007, 0, 0,
    1.4541e-007, 1.35337075856, 76.2660712756,
    1.2461e-007, 6.04431418812, 1.4844727083,
    1.1547e-007, 6.11257808366, 38.1330356378,
];

const NeptuneTermL4 = [
    1.13998e-006, 3.14159265359, 0,
];

const NeptuneTermB0 = [
    0.03088622933, 1.44104372626, 38.1330356378,
    0.00027780087, 5.91271882843, 76.2660712756,
    0.00027623609, 0, 0,
    0.00015448133, 3.50877080888, 39.6175083461,
    0.0001535549, 2.52123799481, 36.6485629295,
    1.999919e-005, 1.50998669505, 74.7815985673,
    1.96754e-005, 4.37778195768, 1.4844727083,
    1.015137e-005, 3.21561035875, 35.1640902212,
    6.05767e-006, 2.80246601405, 73.297125859,
    5.94878e-006, 2.12892708114, 41.1019810544,
    5.88805e-006, 3.18655882497, 2.9689454166,
    4.0183e-006, 4.16883287237, 114.3991069134,
    2.79964e-006, 1.68165309699, 77.7505439839,
    2.61647e-006, 3.76722704749, 213.299095438,
    2.54333e-006, 3.27120499438, 453.424893819,
    2.0559e-006, 4.25652348864, 529.6909650946,
    1.40455e-006, 3.52969556376, 137.0330241624,
];

const NeptuneTermB1 = [
    0.00227279214, 3.8079308987, 38.1330356378,
    1.80312e-005, 1.97576485377, 76.2660712756,
    1.4333e-005, 3.14159265359, 0,
    1.385733e-005, 4.82555548018, 36.6485629295,
    1.073298e-005, 6.08054240712, 39.6175083461,
    1.47903e-006, 3.85766231348, 74.7815985673,
    1.36448e-006, 0.47764957338, 1.4844727083,
    7.0285e-007, 6.18782052139, 35.1640902212,
    5.1899e-007, 5.05221791891, 73.297125859,
    4.2568e-007, 0.30721737205, 114.3991069134,
    3.7273e-007, 4.89476629246, 41.1019810544,
    3.7104e-007, 5.75999349109, 2.9689454166,
    2.6399e-007, 5.21566335936, 213.299095438,
];

const NeptuneTermB2 = [
    9.690766e-005, 5.57123750291, 38.1330356378,
    7.8815e-007, 3.62705474219, 76.2660712756,
    7.1523e-007, 0.4547668858, 36.6485629295,
    5.8646e-007, 3.14159265359, 0,
    2.9915e-007, 1.60671721861, 39.6175083461,
    6.472e-008, 5.60736756575, 74.7815985673,
];

const NeptuneTermB3 = [
    2.73423e-006, 1.01688979072, 38.1330356378,
    2.393e-008, 0, 0,
    2.274e-008, 2.36805657126, 36.6485629295,
    2.029e-008, 5.33364321342, 76.2660712756,
];

const NeptuneTermB4 = [
    5.728e-008, 2.66872693322, 38.1330356378,
];

const NeptuneTermR0 = [
    30.07013206102, 0, 0,
    0.2706225949, 1.3299945893, 38.1330356378,
    0.01691764281, 3.25186138896, 36.6485629295,
    0.00807830737, 5.18592836167, 1.4844727083,
    0.00537760613, 4.52113902845, 35.1640902212,
    0.00495725642, 1.57105654815, 491.5579294568,
    0.0027457197, 1.84552256801, 175.1660598002,
    0.00135134095, 3.37220607384, 39.6175083461,
    0.00121801825, 5.79754444303, 76.2660712756,
    0.00100895397, 0.37702748681, 73.297125859,
    0.00069791722, 3.79617226928, 2.9689454166,
    0.00046687838, 5.74937810094, 33.6796175129,
    0.00024593778, 0.50801728204, 109.9456887885,
    0.00016939242, 1.59422166991, 71.8126531507,
    0.00014229686, 1.07786112902, 74.7815985673,
    0.00012011825, 1.92062131635, 1021.2488945514,
    8.394731e-005, 0.67816895547, 146.594251718,
    7.5718e-005, 1.07149263431, 388.4651552382,
    5.720852e-005, 2.59059512267, 4.4534181249,
    4.839672e-005, 1.9068599107, 41.1019810544,
    4.483492e-005, 2.90573457534, 529.6909650946,
    4.420804e-005, 1.74993796503, 108.4612160802,
    4.35379e-005, 0.6798566237, 32.1951448046,
    4.270202e-005, 3.41343865825, 453.424893819,
    3.38093e-005, 0.84810683275, 183.2428146475,
    2.881063e-005, 1.98600105123, 137.0330241624,
    2.878942e-005, 3.67415901855, 350.3321196004,
    2.635535e-005, 3.09755943422, 213.299095438,
    2.530149e-005, 5.79839567009, 490.0734567485,
    2.523132e-005, 0.48630800015, 493.0424021651,
    2.306293e-005, 2.80962935724, 70.3281804424,
    2.087303e-005, 0.61858378281, 33.9402499438,
];

const NeptuneTermR1 = [
    0.00236338502, 0.70498011235, 38.1330356378,
    0.00013220279, 3.32015499895, 1.4844727083,
    8.621863e-005, 6.2162895163, 35.1640902212,
    2.70174e-005, 1.88140666779, 39.6175083461,
    2.154735e-005, 2.09431198086, 2.9689454166,
    2.15315e-005, 5.16873840979, 76.2660712756,
    1.603165e-005, 0, 0,
    1.463924e-005, 1.18417031047, 33.6796175129,
    1.135773e-005, 3.91891199655, 36.6485629295,
    8.9765e-006, 5.24122933533, 388.4651552382,
    7.89908e-006, 0.5331548458, 168.0525127994,
    7.6003e-006, 0.02051033644, 182.279606801,
    6.07183e-006, 1.0770650035, 1021.2488945514,
    5.71622e-006, 3.40060785432, 484.444382456,
    5.6079e-006, 2.88685815667, 498.6714764576,
];

const NeptuneTermR2 = [
    4.247412e-005, 5.89910679117, 38.1330356378,
    2.1757e-006, 0.3458182908, 1.4844727083,
    1.63025e-006, 2.2387294713, 168.0525127994,
    1.56285e-006, 4.59414467342, 182.279606801,
    1.27141e-006, 2.84786298079, 35.1640902212,
];

const NeptuneTermR3 = [
    1.66297e-006, 4.55243893489, 38.1330356378,
];

const NeptuneTerms: pTerms[] = [
    { termArray: NeptuneTermL0, termCount: 38 },
    { termArray: NeptuneTermL1, termCount: 18 },
    { termArray: NeptuneTermL2, termCount: 7 },
    { termArray: NeptuneTermL3, termCount: 4 },
    { termArray: NeptuneTermL4, termCount: 1 },
    { termArray: [], termCount: 0 },
    { termArray: NeptuneTermB0, termCount: 17 },
    { termArray: NeptuneTermB1, termCount: 13 },
    { termArray: NeptuneTermB2, termCount: 6 },
    { termArray: NeptuneTermB3, termCount: 4 },
    { termArray: NeptuneTermB4, termCount: 1 },
    { termArray: [], termCount: 0 },
    { termArray: NeptuneTermR0, termCount: 32 },
    { termArray: NeptuneTermR1, termCount: 15 },
    { termArray: NeptuneTermR2, termCount: 5 },
    { termArray: NeptuneTermR3, termCount: 1 },
    { termArray: [], termCount: 0 },
    { termArray: [], termCount: 0 },
];

const planetTerms: pTerms[][] = [
    SunTerms,
    MercuryTerms,
    VenusTerms,
    EarthTerms,
    MarsTerms,
    JupiterTerms,
    SaturnTerms,
    UranusTerms,
    NeptuneTerms,
];




function astor(x: number) { return (x) * (Math.PI / (180.0 * 3600.0)) }   /* Arc second->Radian */

let quickPlanetCalc: boolean;					// Shortcut planet calculation ?

/*  PLANETPOS  --  Calculate position of a single planet from the
                   terms defining it.  */

// static void planetPos(int planet, double jd,
//     double * l, double * b, double * r, double * ldyn, double * bdyn)
export default function planetPos(planet: number, jd: number) {
    let i, j, k, nterms;
    let x, Tn;
    let y = [0, 0, 0];
    let ld;
    let tau = (jd - 2451545.0) / 365250.0;
    let pt;
    let abc;
    let isSun = false;

    if (planet === 9) {
        // const ny = pluto(jd);
        // y[0] = ny[0]
        // y[1] = ny[1]
        // y[2] = ny[2]
        // y[0] = dtr(y[0]);
        // y[1] = dtr(y[1]);
    } else {
        if (planet === 0) {
            isSun = true;
            planet = 3;

        }
        pt = planetTerms[planet];
        for (i = 0; i < 3; i++) {
            y[i] = 0;
            Tn = 1; /* T^0 = 1 */
            for (j = 0; j < 6; j++) {
                x = 0;
                let ti = 0;
                abc = pt[i * 6 + j].termArray;
                nterms = pt[i * 6 + j].termCount;
                if (quickPlanetCalc) {
                    nterms = Math.min(nterms, 6);
                }
                for (k = 0; k < nterms; ti += 3, k++) {
                    x += abc[ti + 0] * Math.cos(abc[ti + 1] + abc[ti + 2] * tau);
                }
                y[i] += x * Tn;
                Tn *= tau;
            }
        }
    }
    const ldyn = fixangr(y[0]);
    const bdyn = y[1];
    const r = y[2];

    /* Convert from Dynamic to FK5 equator & ecliptic */

    tau *= 10;
    ld = ldyn - dtr(1.397 * tau + 0.00031 * tau * tau);
    const b = bdyn + astor(0.03916 * (Math.cos(ld) - Math.sin(ld)));
    let l = ldyn + astor(-0.09033 + 0.03916 * Math.tan(bdyn) * (Math.cos(ld) + Math.sin(ld)));
    if (isSun) {
        l = fixangr(l + Math.PI);
    }

    return {
        l, b, r, ld: ldyn, bd: bdyn
    }
}

/*	PLANETSPOS	--	Calculate positions for planets selected by
                    a bit vector which. */

// void planets(double jd, int which)
export function planets(jd: number) {
    let i;
    let x, y, z, tau, jc, glon, glat, theta,
        aberrE, aberrPI, aberrDlambda, aberrDbeta, lprime, epsilon, tra;
    let nPsi = 0, nEps = 0, ecos = 0, esin = 0;
    let sunL = 0, sunB = 0, sunR = 0;	   /* Sun position */

    // const Planet = (x: number) => (which && (1 << (x)))

    // const quickPlanetCalc = planet_info[which];

    /* Calculate obliquity of the ecliptic and nutation
       in obliquity and longitude.	These depend solely upon
       the epoch and thus can be used for all the calculations
       below. */

    // if (!quickPlanetCalc) {
    epsilon = dtr(obliqeq(jd));
    ({ nPsi, nEps } = nutation(jd));
    epsilon += nEps;				/* Correct obliquity for nutation */
    esin = Math.sin(epsilon);
    ecos = Math.cos(epsilon);
    // }

    const planet_info: Planet[] = []

    for (i = 0; i < 10; i++) {
        planet_info.push({
            hlong: 0,
            hlat: 0,
            hrv: 0,
            dhlong: 0,
            dhlat: 0,
            ra: 0,
            dec: 0,
            dist: 0,
            mag: 0,
            lha: 0,
            alt: 0,
            az: 0
        });
        // if (Planet(i)) {
        let l, b, r, ld, bd;
        if (i === 3) {				// Plug moon position in slot 3
            ({ l, b, r } = highmoon(jd));
            const { Ra, Dec } = ecliptoeq(jd, l, b);
            planet_info[3].ra = Ra;
            planet_info[3].dec = Dec;
            planet_info[i].dist = r;	// Note that moon distance is from Earth's centre
        } else {
            ({ l, b, r, ld, bd } = planetPos(i, jd));
            if (i === 0) {
                sunL = ld;
                sunB = bd;
                sunR = r;
                x = -r * Math.cos(bd) * Math.cos(ld);
                y = -r * Math.cos(bd) * Math.sin(ld);
                z = -r * Math.sin(bd);
                r = 0;
            } else {

                x = r * Math.cos(bd) * Math.cos(ld) - sunR * Math.cos(sunB) * Math.cos(sunL);
                y = r * Math.cos(bd) * Math.sin(ld) - sunR * Math.cos(sunB) * Math.sin(sunL);
                z = r * Math.sin(bd) - sunR * Math.sin(sunB);
            }

            planet_info[i].hrv = r;			/* Heliocentric radius vector */
            planet_info[i].hlong = rtd(l);	/* Heliocentric FK5 longitude */
            planet_info[i].hlat = rtd(b);	/* Heliocentric FK5 latitude */
            planet_info[i].dhlong = rtd(ld);/* Heliocentric dynamical longitude */
            planet_info[i].dhlat = rtd(bd); /* Heliocentric dynamical latitude */
            planet_info[i].dist = Math.sqrt(x * x + y * y + z * z); /* True distance from Earth */

            if (quickPlanetCalc) {
                continue;
            }

            /* Light travel time over true distance from Earth. */
            tau = 0.0057755183 * planet_info[i].dist;

            /* Recompute apparent position taking into count
               speed of light delay. */

            ({ l, b, r, ld, bd } = planetPos(i, jd - tau));
            if (i === 0) {
                x = -r * Math.cos(bd) * Math.cos(ld);
                y = -r * Math.cos(bd) * Math.sin(ld);
                z = -r * Math.sin(bd);
                r = 0;
            } else {
                x = r * Math.cos(bd) * Math.cos(ld) - sunR * Math.cos(sunB) * Math.cos(sunL);
                y = r * Math.cos(bd) * Math.sin(ld) - sunR * Math.cos(sunB) * Math.sin(sunL);
                z = r * Math.sin(bd) - sunR * Math.sin(sunB);
            }
            tau = 0.0057755183 * Math.sqrt(x * x + y * y + z * z);

            /* Geocentric longitude and latitude, corrected for
               light travel time. */

            glon = Math.atan2(y, x);
            glat = Math.atan2(z, Math.sqrt(x * x + y * y));

            /* Compute aberration. */

            const Kappa = (20.49552 / 3600.0)
            theta = fixangr(sunL + Math.PI);
            jc = (jd - J2000) / JulianCentury;
            aberrE = 0.016708617 - 0.000042037 * jc -
                0.0000001236 * (jc * jc);
            aberrPI = dtr(102.93735 + 0.71953 * jc + 0.00046 * (jc * jc));
            aberrDlambda = ((-Kappa * Math.cos(theta - glon)) +
                (aberrE * Kappa * Math.cos(aberrPI - glon))) /
                Math.cos(glat);
            aberrDbeta = (-Kappa) * Math.sin(glat) * (Math.sin(theta - glon) -
                aberrE * Math.sin(aberrPI - glon));

            /* Correct for aberration. */

            glon += dtr(aberrDlambda);
            glat += dtr(aberrDbeta);

            /* Reduce to the FK5 system. */

            lprime = ld - dtr(1.397 * jc + 0.00031 * jc * jc);
            glat += astor(0.03916 * (Math.cos(ld) - Math.sin(ld)));
            glon += astor((-0.09033 + 0.03916 * Math.tan(bd) * (Math.cos(ld) + Math.sin(ld))));

            /* Correct for nutation. */

            glon += nPsi;

            /* Transform into apparent right ascension and declination. */

            tra = rtd(Math.atan2(Math.sin(glon) * ecos -
                Math.tan(glat) * esin, Math.cos(glon)));
            planet_info[i].dec = rtd(Math.asin(Math.sin(glat) * ecos +
                Math.cos(glat) * esin * Math.sin(glon)));
            planet_info[i].ra = fixangle(tra);
            // }
        }
    }
    return planet_info;
}

