import { gmst } from "./astro";
import { dtr, fixangle, rtd } from "./sunclock";
import { planets } from "./vsop87";

export function calcPlanet(jd: number, siteLat: number, siteLon: number) {
    let i;
    let igmst, latsin, latcos;

    const planet_info = planets(jd);
    igmst = gmst(jd);
    latsin = Math.sin(dtr(siteLat));
    latcos = Math.cos(dtr(siteLat));
    for (i = 0; i < 10; i++) {
        planet_info[i].lha = dtr(fixangle((igmst * 15) - siteLon - planet_info[i].ra));
        planet_info[i].az = rtd(Math.atan2(Math.sin(planet_info[i].lha), Math.cos(planet_info[i].lha) * latsin -
            Math.tan(dtr(planet_info[i].dec)) * latcos));
        planet_info[i].alt = rtd(Math.asin(latsin * Math.sin(dtr(planet_info[i].dec)) +
            latcos * Math.cos(dtr(planet_info[i].dec)) * Math.cos(planet_info[i].lha)));
        }
        
        console.log(latsin, latcos)

    return planet_info;
}