import { Button, Checkbox, Link, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import VerticalPad from "../../components/verticalPad";
import { calcPlanet } from "./planetP";
import { Planet } from "./vsop87";
import axios from "axios";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ArrowLeft, FastRewind, FastForward, ArrowRight } from '@mui/icons-material/';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";
import { jtimeMoment } from "./astro";
import MyBreadcrumbs from "../../components/myBreadcrumbs";

import './planets.css';
import { AstroBaseState, AstroContext, AstroProvider } from "./context";
import React from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { determinePlanetRelations } from "./planetRelations";
import { PlanetaryChart } from "./starchart";

export default function AstroScreen() {
    const [get, set] = useState(AstroBaseState)
    return (
        <>
            <AstroProvider value={{ get, set }}>
                <AstroScreenComp />
            </AstroProvider>
        </>
    )
}
function AstroScreenComp() {
    const context = React.useContext(AstroContext);
    const [astroDate, setAstroDate] = useState<moment.Moment>(moment());
    const [jd, setJd] = useState(jtimeMoment(moment()));
    const [lat, setLat] = useState(47);
    const [lon, setLon] = useState(7);
    const [lonEast, setLonEast] = useState(true);
    const [latNorth, setLatNorth] = useState(true);

    const [value, setValue] = React.useState('date');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const handleAstroDateChange = (newValue: any) => {
        setJd(jtimeMoment(newValue));
        setAstroDate(newValue);
        // console.log(newValue)
    };

    const updateJd = (delta: number) => {
        const newMoment = astroDate.add(delta, 'days');
        handleAstroDateChange(newMoment);
    }

    const getIpLocation = () => {
        axios.get('https://geolocation-db.com/json/').then(res => {
            // console.log(res.data)

            const { latitude, longitude } = res.data;
            setLat(latitude)
            setLon(longitude)
        })
    }

    useEffect(() => {
        const _lon = lonEast ? -lon : lon;
        const _lat = latNorth ? lat : -lat;
        const _p = calcPlanet(jd, _lat, _lon);
        const _r = determinePlanetRelations(_p);
        context.set((val) => ({ ...val, planetInfo: _p, planetRelations: _r }));
    }, [jd, lat, latNorth, lon, lonEast]);

    return <>
        <MyBreadcrumbs current="Astronomy" />
        <Box >
            <Stack
                sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
                direction={{ xs: 'column-reverse', sm: 'column-reverse', md: 'row' }}
                spacing={2}
            >
                {/* <Card style={{ minWidth: "200pxs" }}> */}
                <Box>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Date" value="date" />
                                <Tab label="Location" value="location" />
                            </TabList>
                        </Box>
                        <TabPanel value="date">
                            <Stack spacing={1} direction={"column"} width="100%" style={{ display: "flex", alignItems: "center" }}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        label="Date"
                                        inputFormat="MM/DD/YYYY"
                                        value={astroDate}
                                        onChange={handleAstroDateChange}
                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <TextField variant="standard" disabled ref={inputRef} value={astroDate.format('L')} />
                                                {InputProps?.endAdornment}
                                            </Box>
                                        )}
                                    />
                                </LocalizationProvider>
                                <Stack spacing={.5} direction={"row"} width="100%">
                                    <Button variant="outlined" style={{ flexGrow: "1" }} onClick={() => updateJd(-30)}><FastRewind /></Button>
                                    <Button variant="outlined" style={{ flexGrow: "1" }} onClick={() => updateJd(-1)}><ArrowLeft /></Button>
                                    <Button variant="outlined" style={{ flexGrow: "1" }} onClick={() => updateJd(1)}><ArrowRight /></Button>
                                    <Button variant="outlined" style={{ flexGrow: "1" }} onClick={() => updateJd(30)}><FastForward /></Button>
                                </Stack>
                            </Stack>
                        </TabPanel>
                        <TabPanel value="location">
                            <Stack spacing={1}>
                                <TextField id="outlined-basic" label="Lat" variant="outlined" type="number"
                                    value={lat}
                                    onChange={(e) => setLat(Number(e.target.value))}
                                />
                                <TextField id="outlined-basic" label="Lon" variant="outlined" type="number"
                                    value={lon}
                                    onChange={(e) => setLon(Number(e.target.value))}
                                />
                                <Button onClick={getIpLocation}>Guess IP Location</Button>
                            </Stack>
                        </TabPanel>

                    </TabContext>
                </Box>
                {/* </Card> */}
                <PlanetaryChart />
            </Stack>
            <VerticalPad size={40} />
            <Typography variant="h5" gutterBottom>Ephemeris</Typography>
            <Box>
                {/* <table width={"100%"}>
                    <tbody>

                        <tr>
                            <th></th>
                            <th>Right Ascencion</th>
                            <th>Declination</th>
                            <th>Distance (AU)</th>
                            <th>From {lat}°N Altitude</th>
                            <th>{lon}°E Azimuth </th>

                        </tr>
                        {context.get.planetInfo.map((p, i) => <PlanetRow p={p} i={i} />)}
                    </tbody>
                </table> */}
                <TableContainer style={{ maxHeight: 440, overscrollBehavior: "none" }}>
                    <Table sx={{ minWidth: 800 }} stickyHeader aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Right Ascencion</TableCell>
                                <TableCell align="right">Declination</TableCell>
                                <TableCell align="right">Distance (AU)</TableCell>
                                <TableCell align="right">From {lat}°N Altitude</TableCell>
                                <TableCell align="right">{lon}°E Azimuth </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {context.get.planetInfo.map((p, i) => <PlanetRow p={p} i={i} key={`row-${i}`} />)}
                        </TableBody>
                    </Table>
                </ TableContainer>
            </Box>

        </Box>
        <VerticalPad size={80} />
        <Typography>adapted from John Walker's
            <Link
                href='https://www.fourmilab.ch/homeplanet/'
                style={{ margin: '4px' }}
                underline="hover"
            >
                Home Planet
            </Link>
        </Typography>
    </>
}

function PlanetRow(props: { p: Planet, i: number }) {
    const { p, i } = props;
    const context = React.useContext(AstroContext);

    return (<TableRow>
        <TableCell style={{ textAlign: "left" }}>
            <Checkbox
                checked={context.get.displayPlanet[i]}
                onChange={() => context.set((val) => {
                    val.displayPlanet[i] = !val.displayPlanet[i]
                    return { ...val }
                })}
            />
            {PLANET_NAME[i]}
        </TableCell>
        <TableCell style={{ textAlign: "right", padding: 2 }}>{calcAscencion(p.ra)}</TableCell>
        <TableCell style={{ textAlign: "right", padding: 2 }}>{calcDeclination(p.dec)}</TableCell>
        <TableCell style={{ textAlign: "right", padding: 2 }}>{p.dist.toFixed(3)}</TableCell>
        <TableCell style={{ textAlign: "right", padding: 2 }}>{p.alt.toFixed(3)}</TableCell>
        <TableCell style={{ textAlign: "right", padding: 2 }}>{p.az.toFixed(3)}</TableCell>
    </TableRow>)
}


function calcAscencion(ra: number) {
    const hoursDec = ra / 360 * 24

    const hours = Math.trunc(hoursDec)
    const minutes = Math.trunc((hoursDec - hours) * 60)

    return `${hours}h ${minutes}m`
}

function calcDeclination(dec: number) {
    const deg = Math.trunc(dec);
    const min = (dec - deg) * 60;
    return `${deg}° ${Math.abs(min).toFixed(1)}'`;
    // return dec
}

export const PLANET_NAME = [
    "Sun",
    "Mercury",
    "Venus",
    "Moon",
    "Mars",
    "Jupiter",
    "Saturn",
    "Uranus",
    "Neptune",
    "Pluto"
]

export const PLANET_SYMBOL = [
    "☉",
    "☿",
    "♀︎",
    "☾",
    "♂︎",
    "♃",
    "♄",
    "♅",
    "♆",
    "♇",
]


