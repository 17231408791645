
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import EstimatorScreen from './screens/estimator/estimator';
import ScreenConsulting from './screens/consulting/consulting';

import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { DarkTheme } from './theme';
import ScreenIframe from './screens/iframe';
import ScreenHome from './screens/home/home';
import AstroScreen from './screens/astro/astroScreen';
import ArtScreen from './screens/art/artScreen';
import { Footer } from './components/footer';
import ScreenProtoTyping from './screens/prototyping/prototypingScreen';
import ScreenClaimingXodius from './screens/claimingXodius/cxScreen';

function App() {
  return (
    <>
      <ThemeProvider theme={DarkTheme}>
        <CssBaseline />
        {/* <div className="App"> */}
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <Box sx={{ maxWidth: 960, width: "98%" }} >
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<ScreenHome />} />
                <Route path="/consulting" element={<ScreenConsulting />} />
                <Route path="/consulting/estimator" element={<EstimatorScreen />} />
                <Route path="/iframe" element={<ScreenIframe />} />
                <Route path="/astro" element={<AstroScreen />} />
                <Route path="/gallery" element={<ArtScreen />} />
                <Route path="/claiming-xodius" element={<ScreenClaimingXodius />} />
                <Route path="/prototyping" element={<ScreenProtoTyping />} />
              </Routes>
            </BrowserRouter>
          </Box>
          <Footer />
        </div>
      </ThemeProvider>
    </>
  );
}

export default App;
